@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Oswald:wght@400;500;700&display=swap');

// font
$baseFont: 14;
// font family settings
$font-family-num: 'Oswald', sans-serif !default;


// 色関連指定
// ==========================================================================

// 黒
$color-black: #222222;
// デフォ文字
$color-text: #222222;

// ==========================================================================
// WHITE
// ==========================================================================
$color-white: #fff;
$color-white-smoke: #f2f5fa;

// ==========================================================================
// BLUE
// ==========================================================================
$color-blue: #004098;
$color-aqua: #ccd9ea;
$color-pale-blue: #52a7cc;
$color-light-blue: #ebeef4;
$color-light-sky: #F2F7FA;
// ==========================================================================
// RED
// ==========================================================================
$color-red: #9a0001;
$color-pale-pink: #e16969;

// ==========================================================================
// TOMATO
// ==========================================================================

// ==========================================================================
// ORANGE
// ==========================================================================

// ==========================================================================
// PINK
// ==========================================================================

// ==========================================================================
// GREEN
// ==========================================================================
$color-green: #006a2d;
$color-pale-green: #53a66c;

// ==========================================================================
// YELLOW
// ==========================================================================
$color-yellow: #f3e100;

// ==========================================================================
// PURPLE
// ==========================================================================

// ==========================================================================
// GRAY
// ==========================================================================
$color-light-gray: #f4f4f4;
$color-mid-gray: #666;
$color-demi-gray: #999;
$color-platinum-gray: #e5e5e5;
$color-davy-gray: #555;

// ==========================================================================
// GOLD, BROWN
// ==========================================================================

// ==========================================================================
// COURSE テーマーカラー
// ==========================================================================
$color-course-information: #9FC238;
$color-course-electronics: #2A7FC4;
$color-course-electric: #C83A3F;
