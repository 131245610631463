/* ==================================
HEADER
===================================== */
// .header
.header {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(#666, 0.1);
  position: relative;
  z-index: 10;
  background: $color-white;

  @include pc {
    margin-top: 30px;
    padding: 0 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;

    @include print() {
      min-width: auto !important;
      position: relative !important;
      top: 0 !important;
      left: auto !important;
    }
  }

  @include sp {
    width: 100%;
    padding: 4vw;
    position: fixed;
    top: 0;
    left: 0;

    /*
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: -5px;
      left: 0;
      border: none;
      z-index: 5;
    }
    */
  }

  // .headerLogo
  &Logo {
    @include sp {
      width: 47%;
      max-width: 284px;
    }

    img {
      @include pc {
        width: 235px;
      }
    }
  }
  // end .headerLogo

  // .headerBox
  &Box {
    &-logo {
      @include pc {
        width: 228px;
      }
    }

    &-nav {
      @include pc {
        width: 980px;
        display: block !important;
      }

      @include sp {
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        background: $color-white;
        overflow-y: scroll;
        z-index: 1;
      }
    }
  }
  // end .headerBox

  &.is-fixed {
    @include pc {
      min-width: 1120px;
      position: fixed;
      top: -30px;
      left: 0;
      .gnav_contListItm {
        padding: 10px 20px;
        @include print() {
          padding: 20px !important;
        }
      }
    }
  }
}
// end .header

// .gnav
.gnav {
  // .gnav_cont
  &_cont {
    @include pc {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    // .gnav_contList
    &List {
      @include pc {
        padding: 18px 0;
        &:hover {
          cursor: pointer;
        }

        &:first-child {
          .gnav_contListItm {
            &::before {
              display: none;
            }
          }
        }
      }

      // .gnav_contListItm
      &Itm {
        @include pc {
          padding: 20px 20px;
          display: block;
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 0.8px;
          position: relative;

          &::before {
            content: '';
            display: none;
            width: 1px;
            height: 50px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background: $color-platinum-gray;
          }

          &:hover {
            &::after {
              color: $color-mid-gray;
            }
          }
          a {
            color: $color-black;
          }
        }

        @include sp {
          padding: 6% 4%;
          display: flex;
          font-size: 3.2vw;
          font-weight: bold;
          line-height: 0.9;
          border-bottom: 2px solid $color-platinum-gray;
          position: relative;

          &.is-open {
            border-bottom: 2px solid $color-light-gray;
          }
        }

        &::after {
          content: attr(data-name);
          font-weight: normal;

          @include pc {
            display: block;
            margin: 10px 0 0;
            font-size: 10px;
            text-align: center;
            color: $color-blue;
            letter-spacing: 0.8px;
          }

          @include sp {
            margin-left: 3%;
            display: inline-block;
            vertical-align: middle;
            font-size: 2.4vw;
            color: $color-blue;
            line-height: 1.22;
          }
        }
      }
      // end .gnav_contListItm
    }
    // end .gnav_contList
  }
  // end .gnav_cont
}
// end .gnav

// .otherNav
.otherNav {
  width: 100%;

  @include pc {
    height: 30px;
    background: $color-blue;
    position: absolute;
    top: -30px;
    left: 0;
  }

  // .otherNav_cont
  &_cont {
    width: 100%;
    display: flex;

    @include pc {
      height: 100%;
      align-items: center;
      justify-content: flex-end;
    }

    @include sp {
      flex-wrap: wrap;
    }

    // .otherNav_contList
    &List {
      @include pc {
        display: flex;
        align-items: center;
        height: 100%;
      }

      @include sp {
        width: 50%;

        &:nth-child(2n) {
          .otherNav_contListItm {
            border-left: 2px solid $color-white;
          }
        }
        &#navSearchBtn {
          display: none;
        }
      }

      // .otherNav_contListItm
      &Itm {
        @include pc {
          padding: 0 16px;
          font-size: 12px;
          line-height: 2;
          color: $color-white;

          &::before {
            left: 4px;
          }

          &:hover {
            color: $color-white;
            opacity: 0.7;
          }
          &.searchBtn {
            background-color: $color-yellow;
            height: 100%;
            padding: 0 10px;
            display: flex;
            align-items: center;

            img {
              width: 16px;
              height: 16px;
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        @include sp {
          padding: 8% 0;
          text-align: center;
          background: $color-light-gray;
          border-bottom: 2px solid $color-white;
          font-size: 2.9vw;
          display: block;

          &::before {
            display: none;
          }
        }
      }
      // end .otherNav_contListItm
    }
    // end .otherNav_contList
  }
  // end .otherNav_cont
}
// end .otherNav

// .fixNav
.fixNav {
  @include pc {
    position: fixed;
    top: 22%;
    right: 0;
    z-index: 9999;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }

  // .fixNav_cont
  &_cont {
    // .fixNav_contList
    &List {
      @include pc() {
        width: 76px;
      }
      // .fixNav_contListItm
      &Itm {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        @include pc {
          padding: 18px 30px;
          -webkit-writing-mode: vertical-rl;
          -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
          background: $color-white;
          font-size: 16px;
          box-sizing: border-box;
        }

        @include sp {
          padding: 6% 0;
          text-align: center;
          @include fontsizeVw(32);
          display: block;
        }

        &-doc {
          @extend .fixNav_contListItm;
          color: $color-white;
          background: $color-blue;

          @include pc {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &:hover {
              color: $color-white;
            }
          }
          @include sp {
            border-bottom: 2px solid $color-white;
          }
        }

        &-oc {
          @extend .fixNav_contListItm;
          color: $color-blue;
          background: $color-yellow;

          @include pc {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          @include sp {
            border-bottom: 2px solid $color-white;
          }
        }
        &-ai {
          @extend .fixNav_contListItm;
          color: $color-white;
          background-image: linear-gradient(120deg, #0E719F, #08547E);
          position: relative;
          @include pc() {
            min-height: 118px;
            margin-top: 10px;
            box-sizing: border-box;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:hover {
            color: $color-white;
          }
        }

        .horizontal {
          writing-mode: horizontal-tb;
        }
      }
      // end .fixNav_contListItm
    }
    // end .fixNav_contList
  }
  // end .fixNav_cont
}
// end .fixNav

// .mega
.mega {
  width: 100%;
  display: none;

  @include pc {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: -5px;
      left: 0;
      border: none;
      z-index: 1;
    }
  }

  // .megaBox
  &Box {
    @include pc {
      display: flex;
    }

    // .megaBox-heading
    &-heading {
      @include pc {
        width: 380px;
        padding: 40px 0 40px 160px;
        box-sizing: border-box;
        background: $color-blue;
        color: $color-white;
      }

      @include sp {
        display: none;
      }
    }
    // end .megaBox-heading

    // .megaBox-link
    &-link {
      @include pc {
        width: calc(100% - 380px);
        padding: 28px 20px 22px;
        box-sizing: border-box;
        background: $color-white;
      }
    }
    // end .megaBox-link

    // .megaBox_name
    &_name {
      @include pc {
        font-size: 18px;
      }

      &::after {
        content: attr(data-name);
        display: block;

        @include pc {
          padding: 12px 0 0;
          font-size: 14px;
          color: $color-white;
        }
      }
    }
    // end .megaBox_name

    // .megaBox_cat
    &_cat {
      border-bottom: 1px solid $color-blue;

      @include pc {
        width: 880px;
        margin: 0 0 12px;
        padding: 12px 0;
        font-size: 14px;
        font-weight: bold;
      }

      @include sp {
        display: none;
      }
    }
    // end .megaBox_cat
  }
  // end .megaBox

  // .megaInner
  &Inner {
    @include pc {
      width: 880px;
      display: flex;
      flex-wrap: wrap;
    }

    // .megaInner_list
    &_list {
      @include pc {
        width: 190px;
        margin: 0 40px 0 0;

        &:nth-child(4n) {
          margin: 0;
        }

        &:nth-child(4n + 1) {
          margin-bottom: 18px;
        }
      }

      @include sp {
        &:last-child {
          .megaInner_listItm {
            border-bottom: none;
          }
        }
      }

      &-electronics {
        @extend .megaInner_list;

        @include sp {
          &:first-child {
            border-top: 2px solid $color-white;
          }
        }
      }

      &-electric {
        @extend .megaInner_list;

        @include sp {
          &:first-child {
            border-top: 2px solid $color-white;
          }
        }
      }

      // .megaInner_listItm
      &Itm {
        display: block;

        @include pc {
          padding: 12px 0;
          font-size: 14px;
          border-bottom: 1px solid $color-platinum-gray;
        }

        @include sp {
          padding: 4% 8%;
          font-size: 3.2vw;
          background: $color-light-gray;
          border-bottom: 2px solid $color-white;
        }

        &-engineer {
          @extend .megaInner_listItm;

          @include sp {
            color: $color-red;
          }
        }

        &-electronics {
          @extend .megaInner_listItm;

          @include sp {
            color: $color-blue;
          }
        }

        &-electric {
          @extend .megaInner_listItm;

          @include sp {
            color: $color-green;
          }
        }
      }
      // end .megaInner_listItm
    }
    // end .megaInner_list
  }
  // end .megaInner
}
// end. mega

// .hamburger
.hamburger {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: $color-blue;

  @include pc {
    display: none;
  }

  @include sp {
    width: 18vw;
  }

  &Line {
    width: 48%;
    height: 4%;
    margin: auto;
    right: 0;
    left: 0;
    position: absolute;
    background: $color-white;
    transition: all 0.3s ease-in-out;

    &-top {
      @extend .hamburgerLine;
      top: -26%;
      bottom: 0;
    }

    &-mid {
      @extend .hamburgerLine;
      top: 0;
      bottom: 0;
      opacity: 1;
    }

    &-bot {
      @extend .hamburgerLine;
      top: 26%;
      bottom: 0;
    }
  }

  &.is-open {
    .hamburgerLine {
      &-top {
        transform: rotate(225deg);
        top: 0;
      }

      &-mid {
        opacity: 0;
      }

      &-bot {
        transform: rotate(315deg);
        top: 0;
      }
    }
  }
}
// end .hamburger

.megaBgCover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: none;
}
