#support {
  .mainVisual {
    /*background: url('/asset/img/support/mv.jpg') no-repeat center center/cover;*/
    background-size: cover;
  }

  .featureBlock{
    background: #F2F7FA;
  }

  .supportTitle {
    text-align: center;
    font-size: 20px;
    color: $color-blue;
    margin-bottom: 20px;
    @include pc {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
  //

  .supportTitle_sub {
    text-align: center;
  }

  .supportLicenseGraf {
    margin: 30px 0 0 0;
    display: flex;
    justify-content: center;
    @include pc {
      margin: 60px 0 0 0;
    }

    li {
      img {
        width: 100%;
      }
      margin: 0 3%;
      @include pc {
        width: 24%;
      }
    }
  }

  .features .feature .featureTitle,
  .features .feature .featureSubTitle .subTitleLabel>span{
    color: #003281;
  }

  .curriculumBlock .l-curriculumTable{
    display: block;
  }
  .curriculumBlock .curriculumTable{
    width: 100%;
  }

  .curriculumBlock .curriculumTable{
    text-align: center;
    z-index: 0;
  }

  .curriculumBlock .curriculumTable dt{
    font-size: 1.5rem;
    @include pc {
      font-size: 2.4rem;
    }
  }

  .curriculumBlock .curriculumTable dd{
    position: relative;
    margin-bottom: 80px;
  }

  .curriculumBlock .curriculumTable dd:before{
    content: '';
    position: absolute;
    top: 0%;
    left: 36px;
    transform: translateX(-50%);
    width: 2px;
    height: 88%;
    background-color: $color-blue;
    z-index: -1;
    @include pc {
      height: 93%;
    }
  }

  .curriculumBlock .curriculumTable dd.second:before{
    height: 70%;
    @include pc {
      height: 80%;
    }
  }

  .curriculumBlock .curriculumTable:not(:last-of-type)::after{
    content: none;
  }
  //

  .support_voice {
    /*background: url('/asset/img/common/bg_stripe_yellow.png');*/
    background: #F2F7FA;
    padding: 5% 0;
    @include pc {
      padding: 80px 0;
    }

    &__rap {
      padding: 0 3%;
      @include pc {
        max-width: 1120px;
        margin: 0 auto;
        padding: 0;
      }
    }
    &__inner {
      line-height: 1.6;
      margin-bottom: 30px;
      @include pc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
      }
      &.center{
        justify-content: center;
      }
      &__box {
        background: #fff;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 20px;
        @include pc {
          width: 48%;
          margin-bottom: 0;
        }
      }
    }
    &__photo {
      img {
        width: 100%;
        border-radius: 50%;
      }
      position: absolute;
      width: 23%;
      top: 40px;
      right: 20px;
      @include pc {
        width: 20%;
        top: 15px;
      }
    }
    &__ttl {
      background: $color-blue;
      color: #fff;
      display: inline-block;
      padding: 5px;
      margin-bottom: 15px;
      font-size: 12px;
      @include pc {
        font-size: 14px;
      }
    }
    &__name {
      margin: 5px 0 20px 0;
      .bold {
        font-weight: bold;
      }
      .big {
        font-size: 22px;
      }
    }

    &__box02 {
      padding: 20px;
      box-sizing: border-box;
      line-height: 1.6;
      position: relative;
      @include pc {
        display: flex;
        justify-content: space-between;
      }

      &__r {
        background: #fff;
        padding: 30px;
        border-radius: 10px;
        position:relative;
        @include pc {
          width: 65%;
          padding: 40px 30px;
        }
      }
      &__r:after{
        content: none;
        @include pc {
          border: solid transparent;
          content:'';
          height:0;
          width:0;
          pointer-events:none;
          position:absolute;
          border-color: rgba(255, 255, 255, 0);
          border-top-width:12px;
          border-bottom-width:12px;
          border-left-width:12px;
          border-right-width:18px;
          margin-top: -12px;
          border-right-color:#fff;
          right:100%;
          top:50%;
        }
      }
      &__l {
        position: static;
        width: 80%;
        margin: 0 auto 20px;
        @include pc {
          position: absolute;
          width: 22%;
          position: initial;
          top: 20px;
          right: 10px;
        }
        img {
          width: 100%;
          border-radius: 50%;
        }
      }
      &__tl {
        color: $color-blue;
        font-weight: bold;
        margin-bottom: 10px;
        @include pc {
          font-size: 18px;
        }
      }
      &__name {
        font-weight: bold;
        margin-bottom: 20px;
        border-bottom: 2px solid #F4F4F4;
        padding-bottom: 15px;
        span {
          font-size: 18px;
        }
      }
    }
    //
  }
  //

  .supportList {
    padding: 5% 3%;
    @include pc {
      padding: 80px 0;
      max-width: 1120px;
      margin: 0 auto;
    }

    h3.titleLabel03{
      font-size: 20px;
      @include pc {
        font-size: 32px;
      }
    }

    .course01 {
      &.titleLabel02{
        color: $color-course-information;
      }
      &::before {
        background: $color-course-information;
      }
      .courseTl__inner{
        color: $color-course-information;
      }
    }
    .course02 {
      &.titleLabel02,
      .courseTl__inner{
        color: $color-course-electronics;
      }
      &::before {
        background: $color-course-electronics;
      }
    }
    .course03 {
      &.titleLabel02,
      .courseTl__inner{
        color: $color-course-electric;
      }
      &::before {
        background: $color-course-electric;
      }
    }

    &__box {
      line-height: 1.4;
      @include pc {
        display: flex;
        flex-wrap: wrap;
      }

      li {
        margin-bottom: 20px;
        @include pc {
          width: 22.2%;
          margin: 0 1.3% 30px 1.3%;
        }

        .courseTl {
          background: #f4f4f4;
          margin-bottom: 10px;
          color: $color-course-electronics;
          text-align: center;
          min-height: 80px;
          padding: 35px;
          position: relative;
          &__inner {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);

            span{
              display: inline-block;
              margin-bottom: 5px;
              &.lv{
                font-family: $font-family-num;
                font-size: 13px;
              }
              &.memo{
                color:#000;
                font-size: 1.0em;
                font-weight: 400;
              }
            }

            span{
              font-family: $font-family-num;
              font-weight: bold;
              font-size: 34px;
              &.lv{
                font-size: 13px;
              }
            }
          }
        }
      }
      &.course01 .courseTl {
        color: $color-course-information;
      }
      &.course03 .courseTl {
        color: $color-course-electric;
      }

      &--career {
        li {
          padding-left: 18px;
          text-indent: -18px;
          margin-bottom: 15px;
          @include pc {
            width: 28.5%;
          }
          &::before {
            content: '●';
            color: $color-blue;
            font-size: 10px;
            padding-right: 8px;
          }
        }
      }
    }
    //
  }
  //

  .career_bg {
    background: url('/asset/img/support/bg_transfer.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5% 3%;
    @include pc {
      padding: 80px 0;
    }
    &__inner {
      background: rgba(255, 255, 255, 0.7);
      max-width: 1120px;
      margin: 0 auto;
      padding: 30px;
      box-sizing: border-box;
    }
  }
  //

  .career_bg02 {
    background: #f2f5fa;
    &__inner {
      max-width: 1120px;
      margin: 0 auto;
    }

    .careerFormat dt {
      background: #f3e100;
      color: $color-blue;
    }

    .careerDay {
      display: flex;
      align-items: top;
      margin-bottom: 40px;
      .day img{
        width: 72px;
        height: 72px;
        /*background: $color-blue;
        color: #fff;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        margin-right: 10px;
        font-size: 14px;
        position: relative;*/
        &__inner img{
         /* position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);*/
          width: 72px;
          height: 72px;
        }
        span {
          font-size: 10px;
        }
      }
    }
    //
    .careerDayText {
      margin: 0 0 20px 25px;
      text-align: left;
      @include pc {
        margin: 0 0 20px 45px;
      }
      span{
        color: $color-blue;
        font-size: 24px;
        display: inline-block;
        margin-bottom: 10px;
        @include pc {
          font-size: 26px;
        }
      }
      span.n02{
        margin-top: 40px;
      }
    }
    .careerDayText02 {
      color: $color-blue;
      margin: 20px 0 5px 0;
    }
  }
  //

  .arrowBtn {
    width: 16%;
    margin: 20px auto 0 auto;
    img {
      width: 100%;
    }
  }

  #career01,
  #career02,
  #career03 {
    margin-top: -250px;
    padding-top: 250px;
  }
}
//

.txt-c{
  text-align: center;
}

.rate{
  color: $color-blue;
  line-height: 1.7;
  text-align: center;
  background: #F4F4F4;
  padding: 20px 35px;
  @include pc {
    width: 65%;
    margin: 35px auto;
    padding: 35px;
  }

  p{
    font-size: 14px;
    @include pc {
      font-size: 16px;
    }
  }

  img{
    width: 100%;
    display: inline-block;
    margin: 20px 0;
    @include pc {
      width: 320px;
    }
  }
}

.rate h4{
  font-size: 18px;
  @include pc {
    font-size: 26px;
  }
}

.iconImage--support {
  width: 65px;
  img {
    width: 100%;
  }
}
.featureTitle--support {
  color: $color-blue;
  text-align: center;
  font-weight: 700;
  margin-bottom: 14px;
  @include pc() {
    @include fontsize(18);
  }
  @include sp() {
    @include fontsizeVw(34);
    //@include fontsize(16);
  }
  span {
    border-bottom: solid 2px $color-blue;
    padding-bottom: 5px;
    display: inline-block;
  }
}
//
