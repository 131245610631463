#top {
  .l-mv {
    img {
      width: 100%;
    }
    .mv {
      width: 100%;
      height: 100%;
      min-height: 300px;
      position: relative;
      overflow: hidden;

      .textImg {
        position: absolute;
        left: 0;
        width: 100%;
        &.bg {
          z-index: 1;
        }
        &.title {
          z-index: 2;
        }
        &.titleSub {
          z-index: 3;
        }
        &.aiLogo {
          z-index: 2;
        }
      }

      @include pc() {
        // background: url(/asset/img/top/pc/bg_mv.jpg) no-repeat top center/ cover;
        .textImg {
          top: 0;
        }
      }
      @include sp() {
        // background: url(/asset/img/top/sp/bg_mv.jpg) no-repeat top center/ cover;
        .textImg {
          text-align: center;
          &.title {
            bottom: 14.2%;
            img {
              width: 90%;
            }
          }
          &.titleSub {
            bottom: 6%;
            img {
              width: 40%;
            }
          }
        }
      }
    }
  }
  .l-slider {
    // padding: 50px 0;
    padding-bottom: 50px;
    @include sp() {
      // padding: 5% 0;
      padding-bottom: 5%;
    }
  }

  .l-content {
    overflow-x: hidden;
    @include sp() {
      overflow-x: hidden;
    }
  }

  .l-concept {
    max-width: 1440px;
    min-width: 1300px;
    //width: 100%;
    // height: 612px;
    height: 892px;
    position: relative;
    margin: 0 auto;
    //background-color: #333;
    @include sp() {
      width: auto;
      height: auto;
      max-width: none;
      min-width: auto;
    }
  }
  .l-conceptInner {
    width: 940px;
    height: 100%;
    margin: 0 auto;
    padding-top: 80px;
    @include sp() {
      width: auto;
      padding: 14% 2.67% 0 2.67%;
    }
    .conceptTitleBox {
      text-align: center;
      display: flex;
      justify-content: center;

      img {
        width: auto;
        @include sp() {
          width: 100%;
        }
      }
    }
    .conceptTitleBoxInner {
      @include sp() {
        margin: 0 3%;
      }
    }
    .conceptTitle-image-left {
      align-self: flex-end;
      margin-right: 57px;
      @include sp() {
        margin-right: 0;
        margin-left: 3%;
      }
    }
    .conceptTitle-image-right {
      align-self: flex-end;
      margin-left: 52px;
      @include sp() {
        margin-left: 0;
      }
    }
    .conceptTitleSub {
      display: block;
      margin: 0 auto 30px auto;
      text-align: center;
      @include sp() {
        margin-bottom: 8%;
        width: 64%;
      }
    }
    .conceptTitleMain {
      display: block;
      margin: 0 auto 40px auto;
      text-align: center;
      @include sp() {
        margin-bottom: 10%;
      }
    }
  }
  .l-conceptBg {
    position: absolute;
    pointer-events: none;
    height: 100%;
    top: 0;
    width: 100%;
    //display: none;

    .conceptBg_inner {
      position: relative;
      height: 100%;
      @include sp() {
        overflow: hidden;
      }
    }
    .conceptBg_img1 {
      position: absolute;
      top: 0;
      left: 36px;
      @include sp() {
        width: 12%;
        left: -2%;
        top: -4%;
      }
    }
    .conceptBg_img2 {
      position: absolute;
      right: -105px;
      top: -20px;
      @include sp() {
        width: 20%;
        right: -4.5%;
        top: -6%;
      }
    }
    .conceptBg_img3 {
      position: absolute;
      bottom: -74px;
      left: 25px;
      @include sp() {
        width: 23%;
        bottom: -5%;
        left: -6%;
      }
    }
    .conceptBg_img4 {
      position: absolute;
      bottom: -94px;
      right: -22px;
      @include sp() {
        width: 32%;
        bottom: -10%;
        right: -6%;
      }
    }
  }

  .l-reason {
    padding: 80px 0;
    @include sp() {
      padding: calc(10% + 40px) 0 16% 0;
    }
    .titleLabel01_top span {
      color: $color-blue;
      font-family: $font-family-num;
      @include fontsize(60);
    }
  }

  .l-reasonContent {
    position: relative;
    width: 1200px;
    margin: 0 auto 62px auto;
    overflow: hidden;
    &:last-child() {
      margin-bottom: 0;
    }
    @include sp() {
      display: flex;
      justify-content: space-between;
      width: auto;
      flex-direction: column-reverse;
      padding: 0 3%;
      margin: 0 auto 10% auto;
    }
    .l-reasonText {
      position: relative;
      width: 600px;
      background: $color-white;
      box-sizing: border-box;
      z-index: 1;
      @include pc() {
        position: absolute;
        top: 0;
        left: 0;
        padding: 50px 40px;
      }
      @include sp() {
        width: 94%;
        margin: -8% auto 0;
        padding: 4%;
      }
    }
    .l-reasonImage {
      width: 680px;
      @include pc() {
        float: right;
        margin-top: 60px;
      }
      @include sp() {
        width: auto;
        margin: 0 -3%;
      }
    }
    @include pc() {
      &:nth-of-type(odd) {
        .l-reasonText {
          left: auto;
          right: 0;
        }
        .l-reasonImage {
          float: left;
        }
      }
    }

    .l-reasonTitleSub {
      display: flex;
      align-items: center;
      margin: 0 0 30px 0;
      @include sp() {
        flex-direction: column;
        margin: 4% 0;
      }
      .reasonTitleSub_icon {
        position: relative;
        @include fontsize(80);
        margin-right: 30px;
        font-family: $font-family-num;
        font-weight: 500;
        color: $color-blue;
        @include sp() {
          margin: 0 auto 4%;
          @include fontsizeVw(96);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -0.1em;
          left: 10%;
          display: inline-block;
          width: 80%;
          height: 1px;
          background: $color-blue;
        }
      }
      .reasonTitleSub_text {
        @include fontsize(32);
        font-weight: bold;
        line-height: 1.5;
        @include sp() {
          @include fontsizeVw(48);
        }
      }
    }
    .reasonText {
      @include fontsize(16);
      line-height: 1.57;
      @include sp() {
        @include fontsizeVw(28);
      }
    }
    .l-moreBtn {
      @include pc() {
        justify-content: flex-end;
      }
    }
  }

  .l-op {
    position: relative;
    padding: 80px 0;
    background: $color-light-sky;
    @include sp() {
      padding: calc(10% + 40px) 3% 16%;
    }

    &::after {
      @include pc() {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        display: block;
        width: 25%;
        height: 100%;
        background: $color-white;
        z-index: -1;
      }
    }
  }
  .l-opContent {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1120px;
    margin: 0 auto 62px auto;
    &:last-child() {
      margin-bottom: 0;
    }
    @include sp() {
      flex-direction: column-reverse;
      width: auto;
      margin: 0 auto 10% auto;
    }

    &:nth-of-type(even) {
      @include pc() {
        flex-direction: row-reverse;
      }
    }
  }
  .l-opText {
    @include pc() {
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .l-opImage {
    @include pc() {
      width: 48%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .l-opTitleSub {
    margin: 0 0 20px 0;
    @include sp() {
      margin: 4% 0;
    }
    .opTitleSub_text {
      @include fontsize(26);
      font-weight: bold;
      line-height: 1.5;
      @include sp() {
        @include fontsizeVw(40);
      }
    }
  }
  .opText {
    @include fontsize(16);
    line-height: 1.57;
    @include sp() {
      @include fontsizeVw(28);
    }
  }


  .l-courseWrap {
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
    margin: 0 auto;
    @include sp() {
      flex-direction: column;
    }
    .course {
      width: 33.333333%;
      color: $color-white;
      @include sp() {
        width: 100%;
      }
      .course-image {
        img {
          width: 100%;
        }
      }
      .course-body {
        padding: 40px;
        @include sp() {
          padding: 7% 3%;
        }
      }
      .course-name {
        @include fontsize(26);
        font-weight: bold;
        margin-bottom: 15px;
        @include sp() {
          @include fontsizeVw(32);
        }
      }
      .course-overview {
        @include fontsize(15);
        margin-bottom: 15px;
        line-height: 1.5;
        @include sp() {
          @include fontsizeVw(28);
        }
      }
      .course-tag {
        letter-spacing: -0.2em;
        margin-bottom: 16px;
        &--denki {
          @include pc {
            margin-bottom: 40px;
          }
        }
        .course-tag-item {
          @include fontsize(10);
          display: inline-block;
          border: solid 1px $color-white;
          border-radius: 12px;
          padding: 3px 6px;
          margin: 0 4px 4px 0;
          letter-spacing: normal;
          background: $color-white;
          a {
            font-weight: bold;
            display: block;
          }
          @include sp() {
            @include fontsizeVw(18);
            padding: 1.5%;
          }
        }
      }
      .course-link {
        .course-link-item {
          position: relative;
          display: flex;
          align-items: center;
          background-color: $color-white;
          @include fontsize(15);
          font-weight: bold;
          padding: 15px 20px;
          margin-bottom: 6px;
          @include pc() {
            &:hover {
              opacity: 0.8;
            }
          }
          @include sp() {
            @include fontsizeVw(28);
            padding: 2.5% 2.5%;
          }
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: none;
            background: url(/asset/img/common/ico_arrow_next_white.svg) center center no-repeat;
            background-size: 35%;
            margin-right: 10px;
          }
          &::after {
            content: '';
            position: absolute;
            top: calc(50% - 0.3em);
            right: 20px;
            display: block;
            width: 0.6em;
            height: 0.6em;
            border-top: 1px solid #999;
            border-right: 1px solid #999;
            transform: rotate(45deg);
            display: inline-block;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .course {
      &-informationEngineer {
        background-color: $color-course-information;
        .course-tag {
          .course-tag-item {
            a {
              color: $color-course-information;
            }
          }
        }
        .course-link {
          .course-link-item {
            color: $color-course-information;
            &::after {
              border-color: $color-course-information;
            }
          }
        }
      }
      &-electronics {
        background-color: $color-course-electronics;
        .course-tag {
          .course-tag-item {
            a {
              color: $color-course-electronics;
            }
          }
        }
        .course-link {
          .course-link-item {
            color: $color-course-electronics;
            &::after {
              border-color: $color-course-electronics;
            }
          }
        }
      }
      &-electrical {
        background-color: $color-course-electric;
        .course-tag {
          .course-tag-item {
            a {
              color: $color-course-electric;
            }
          }
        }
        .course-link {
          .course-link-item {
            color: $color-course-electric;
            &::after {
              border-color: $color-course-electric;
            }
          }
        }
      }
    }
  }

  .l-mirai {
    max-width: 1440px;
    margin: 0 auto;
  }
  .mirai {
    background: url(/asset/img/common/img_dot.png) repeat,
      url(/asset/img/top/pc/bg_mirai.png) no-repeat top left;
    background-size: cover;
    height: 400px;
    padding: 10px;
    box-sizing: border-box;
    @include sp() {
      background: url(/asset/img/common/img_dot.png) repeat,
        url(/asset/img/top/sp/bg_mirai.png) no-repeat top left;
      background-size: cover;
      padding: 5px;
    }
    .mirai-inner {
      border: solid 2px $color-white;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
    }
    .mirai-content {
      width: 32%;
      margin-right: 8%;
      @include sp() {
        width: 45%;
        margin-right: 4%;
      }
      .mirai-title {
        margin-bottom: 28px;
        @include sp() {
          width: 75%;
          margin-bottom: 8%;
        }
      }
      .mirai-text {
        color: $color-white;
        @include fontsize(16);
        line-height: 1.5;
        text-shadow: 1px 1px 0 #333;
        @include sp() {
          @include fontsizeVw(22);
        }
      }
    }
  }

  .l-aiContent {
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    @include sp() {
      padding: 4px;
    }
  }
  .l-aiContentInner {
    height: 100%;
    border: solid 2px $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
  }
  .ai {
    //box-sizing: border-box;
    position: relative;
    @include pc() {
      width: 1200px;
      margin: 0 auto 80px;
      height: 400px;
      background: url(/asset/img/top/pc/bg_ai_04.png) center center no-repeat,
        url(/asset/img/top/pc/bg_ai_02@2x.png) no-repeat center center/ 100% 100%,
        url(/asset/img/top/pc/bg_ai_03.png) center center no-repeat,
        url(/asset/img/common/bg_lattice.png) repeat center center,
        url(/asset/img/top/pc/bg_ai_01.png) no-repeat center center/ 100% 100%;
    }
    @include sp() {
      background: url(/asset/img/top/sp/bg_ai_01.png) no-repeat top center/ 100%;
      height: 240px;
    }

    .l-aiTitle {
      text-align: center;
      @include sp() {
        width: 90%;
      }
    }
    .aiTitle {
      display: block;
    }
    .aiTitleSub {
      display: block;
      margin-bottom: 10px;
    }
    .aiText {
      color: $color-white;
      font-weight: bold;
      text-align: center;
      margin-top: 10px;
      @include sp() {
        @include fontsizeVw(18);
      }
    }
    .l-moreBtn {
      margin-top: 30px;
      width: 100%;
    }
  }

  .info {
    background: $color-light-sky;
    padding: 80px 0;
    @include sp() {
      padding: 8% 3%;
    }
    .l-infoContent {
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pc {
        width: 1200px;
      }
    }
    .l-infoInner {
      @include pc {
        width: 48%;
      }
      @include sp {
        width: 100%;

        &#news {
          margin-bottom: 12%;
        }
      }
    }
  }
  .news-items {
    @include sp {
      max-width: 100%;
      width: 100%;
    }
    &::after {
      display: none;
    }
    .news-item {
      @include pc {
        width: 48%;
        height: auto !important;
        margin-top: 0;
      }
      @include sp {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    + .swiper-button-wrap {
      position: relative;
      width: 50%;
      top: auto;
      @include sp {
        width: 100%;
      }

      .swiper-button-prev,
      .swiper-button-next {
        border: 2px solid $color-blue;
        box-shadow: none;
      }
    }
  }

  .pickup {
    background: $color-light-sky;
    .l-pickupInner {
      padding: 80px 0;
      @include sp() {
        padding: 8% 3%;
      }
    }
    .pickup-items {
      display: flex;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pc {
        width: 1200px;
      }
    }
    .pickup-item {
      width: 32%;
      @include pc {
        &:hover {
          opacity: 0.8;
        }
      }
      @include sp {
        width: 100%;
        margin-bottom: 8%;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &-img {
        display: block;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: auto;
        }
      }
      &-text {
        @include fontsize(15);

        @include sp() {
          @include fontsizeVw(30);
        }
      }
    }
  }

  .parents {
    padding: 80px 0 120px;
    @include sp() {
      padding: 8% 0 10%;
    }
  }
  .parents-links {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    @include pc() {
      width: 900px;
    }
    @include sp() {
      flex-wrap: wrap;
      width: 70%;
    }
    .parents-link {
      box-sizing: border-box;
      text-align: center;
      color: $color-blue;
      @include pc() {
        width: 20%;
        &:hover {
          color: $color-text;
        }
      }
      @include sp() {
        width: 45%;
      }
    }
    .parents-link-image {
      padding: 20px 0 20px 0;
      @include sp() {
        padding: 0 20% 8%;
      }
    }
    .parents-link-text {
      @include fontsize(18);
      font-weight: bold;
      padding-bottom: 22px;
      @include sp() {
        @include fontsizeVw(30);
      }
    }
  }

  .l-blogBnr {
    display: flex;
    justify-content: center;
    padding: 80px 0;
    @include sp() {
      padding: 10.7% 10%;
    }
  }
}

#top {
  #mv {
    .bg,
    .title,
    .titleSub,
    .aiLogo {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #concept {
    .conceptTitleSub,
    .conceptTitleMain,
    .conceptTitle-image-left,
    .conceptTitle-image-right,
    .l-stripeBox {
      opacity: 0;
      transform: translateY(10px);
    }

    .conceptBg_img1 {
      opacity: 0;
      transform: translateY(-30px);
    }
    .conceptBg_img2 {
      opacity: 0;
      transform: translateY(-30px) translateX(30px);
    }
    .conceptBg_img3 {
      opacity: 0;
      transform: translateY(30px) translateX(-30px);
    }
    .conceptBg_img4 {
      opacity: 0;
      transform: translateY(30px) translateX(30px);
    }
  }

  #reason {
    .contentTitle,
    .titleLabel01,
    .contentTitle-sub,
    .l-reasonContent {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #ai {
    #aiTitleSub {
      opacity: 0;
      transform: translateX(100px);
    }
    #aiTitle {
      opacity: 0;
      transform: translateX(-100px);
    }
    .aiText,
    .l-moreBtn {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #op {
    .contentTitle,
    .titleLabel01,
    .contentTitle-sub,
    .l-opContent {
      opacity: 0;
      transform: translateY(10px);
    }

    .titleLabel01{
      &_sub{
        display: block;
        font-size: 1.5rem;
        margin-bottom: 1.0rem;

        @include sp() {
          font-size: 1.0rem;
        }
  
        span{
          position: relative;
  
          &:before,
          &:after{
            content: '';
            width: 30px;
            height: 1px;
            background: #000;
          }
  
          &:before{
            position: absolute;
            left: -40px;
            bottom: 15px;
            transform: rotate(45deg);
          }
  
          &:after{
            position: absolute;
            right: -35px;
            bottom: 15px;
            transform: rotate(-45deg);
          }
        }
      }

      &_top{
        @include sp() {
          line-height: 1.3;
        }
      }
    }
  }

  #course {
    padding: 80px 0;
    @include sp {
      padding: 8% 0;
    }

    .contentTitle,
    .titleLabel01,
    .contentTitle-sub,
    .course {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #mirai {
    .mirai,
    .mirai-title,
    .mirai-text {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #pickup {
    .contentTitle,
    .titleLabel01,
    .contentTitle-sub,
    .pickup-item {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #news,
  #op_schedule {
    .titleLabel01,
    .news-items,
    .swiper-button-wrap {
      opacity: 0;
      transform: translateY(10px);
    }
  }

  #vr {
    .contentTitle,
    .contentTitle-sub {
      opacity: 0;
      transform: translateY(10px);
    }
    .l-movie {
      opacity: 0;
      transform: translateY(40px);
    }
  }

  #parents {
    .contentTitle,
    .titleLabel01,
    .contentTitle-sub,
    .parents-link {
      opacity: 0;
      transform: translateY(10px);
    }
  }
}

#top .l-mv_banner {
  max-width: 500px;
  width: 35%;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #top .l-mv_banner {
    width: 70%;
    margin-bottom: 5%;
  }
}

#top .l-mv_banner_link {
  font-size: 1.14286rem;
  position: relative;
}

#top .l-mv_banner_link img {
  vertical-align: middle;
}

$puw: 9.6em;
$puwi: 8.8em;

.pickup-news {
  position: relative;
  padding: 0 20px 48px;
  @include sp() {
    padding: 0 0 5%;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  &_inner {
    width: 100%;
    max-width: 1080px;
    height: 48px;
    margin: 0 auto;
    @include sp() {
      height: auto;
    }
  }
  &_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: $color-blue;
    font-size: 13px;
    padding-left: 25px;
    padding-right: 15px;
    @include sp() {
      padding: 15px;
    }
  }
  &--ttl {
    font-weight: 900;
    color: #fff;
    flex: 0 0 $puw;
    max-width: $puw;
    border-right: 1px solid #fff;
    letter-spacing: 0.1em;
    @include sp() {
      flex: 0 0 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #fff;
      padding-bottom: 8px;
      margin-bottom: 8px;
    }
  }
  &__list {
    flex: 0 0 calc(100% - #{$puw});
    flex-basis: calc(100% - #{$puw});
    max-width: calc(100% - #{$puw});
    height: 100%;
    padding-left: 1.8em;
    margin: 0;
    position: relative;
    @include sp() {
      flex: 0 0 100%;
      flex-basis: 100%;
      max-width: 100%;
      font-size: 12px;
      width: 100%;
      height: 4.3em;
      padding-left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto 0;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background: $color-white url(/asset/img/common/ico_arrow_next.svg) no-repeat 52% center;
      background-size: 30%;
    }

    &--item {
      line-height: 1.35;
    }
  }
  &-link {
    color: #fff;
    width: 100%;
    height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-right: 32px;
    @include sp() {
      height: auto;
    }

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
  &-date {
    font-weight: 900;
    flex: 0 0 $puwi;
    max-width: $puwi;
    letter-spacing: 0.1em;
    @include sp() {
      flex: 0 0 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
      padding-bottom: 0.2em;
    }
  }
  &-ttl {
    flex: 0 0 calc(100% - #{$puwi});
    flex-basis: calc(100% - #{$puwi});
    max-width: calc(100% - #{$puwi});
    @include sp() {
      flex: 0 0 100%;
      flex-basis: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}
