.accordionWrap {
  width: 1120px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .accordionWrap {
    width: 100%;
  }
}

.accordion {
  width: 100%;
}

.accordionTtl {
  background-color: #ebeef4;
  padding: 1.2em 3.5em;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  .accordionTtl {
    font-size: 0.85714rem;
  }
}

.accordionTtl::after,
.accordionTtl::before {
  content: '';
  display: block;
  background-color: #004098;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordionTtl .icon:before {
  content: 'Q';
  width: 2em;
  height: 2em;
  color: #004098;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 1em;
  -webkit-transform: translateY(-50%) scale(1.5);
  -ms-transform: translateY(-50%) scale(1.5);
  transform: translateY(-50%) scale(1.5);
}

.accordionTtl::before {
  width: 20px;
  height: 2px;
  right: 2em;
}

@media screen and (max-width: 768px) {
  .accordionTtl::before {
    width: 14px;
  }
}

.accordionTtl::after {
  width: 2px;
  height: 20px;
  right: 2.55em;
  -webkit-transition: opacity 0.36s;
  -o-transition: opacity 0.36s;
  transition: opacity 0.36s;
}

@media screen and (max-width: 768px) {
  .accordionTtl::after {
    height: 14px;
    right: 2.4em;
  }
}

.accordionTtl.is-active::after {
  opacity: 0;
}

.accordionTxt {
  margin-bottom: 0.4em;
  background-color: #fff;
  padding: 0 3.5em;
  line-height: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: padding 0.3s, opacity 0.25s;
  -o-transition: padding 0.3s, opacity 0.25s;
  transition: padding 0.3s, opacity 0.25s;
}

@media screen and (max-width: 768px) {
  .accordionTxt {
    font-size: 0.85714rem;
  }
}

.accordionTxt.is-open {
  padding: 1.5em 3.5em;
  line-height: normal;
  height: auto;
  opacity: 1;
}
