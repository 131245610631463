/* ==================================
FOOTER
===================================== */
// .footer
.footer {
  width: 100%;
  background: $color-blue;

  // @include pc {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  // }

  // .footer_cont
  &_cont {
    // .footer_cont-page
    &-page {
      @include pc {
        padding: 60px 0;
      }
      @include sp {
        padding: 6% 0;
      }
    }
    // end .footer_cont-page

    // .footer_cont-sns
    &-sns {
      @include pc {
        padding: 0 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    // end .footer_cont-sns
  }
  // end .footer_cont

  // .footerMenu
  &Menu {
    @include pc {
      display: flex;
      justify-content: space-between;
    }

    &_list {
      @include pc {
        width: 192px;
      }
    }
  }
  // end .footerMenu

  // .footerInner
  &Inner {
    //border-top: 1px solid $color-aqua;

    @include pc {
      padding: 20px 0 0;
    }

    @include sp {
      border-top: none;
      //border-bottom: 2px solid $color-aqua;
    }

    // .footerInner_head
    &_head {
      @include pc {
        color: $color-white;
        font-size: 14px;
        font-weight: bold;
      }

      // .footerInner_headItm
      &Itm {
        color: $color-white;
        @include pc {
          pointer-events: none;
        }

        @include sp {
          padding: 3% 5.5%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 3.7vw;
          font-weight: bold;
          color: $color-white;
        }

        &::after {
          content: attr(data-name);
          display: none;
          color: $color-demi-gray;

          @include pc {
            padding: 12px 0 0;
            font-size: 10px;
          }

          @include sp {
            margin: 0 0 0 3%;
            font-size: 2.4vw;
          }
        }
      }
      // end .footerInner_headItm
    }
    // end .footerInner_head

    // .footerInner_body
    &_body {
      @include pc {
        padding: 20px 0 0;
      }

      @include sp {
        display: none;
      }
    }
    // end .footerInner_body

    &-topMargin {
      @extend .footerInner;

      @include pc {
        margin: 40px 0 0;
      }
    }
  }
  // end .footerInner

  // .footerLink
  &Link {
    &_head {
      color: $color-white;
      @include pc() {
        @include fontsize(12);
        font-weight: bold;
        margin-bottom: 12px;
      }
    }
    &_list {
      @include pc {
        margin-bottom: 12px;
      }
      &.indent {
        display: flex;
        align-items: center;
        &:before {
          content: '- ';
          color: $color-white;
        }
      }

      &Itm {
        display: block;

        @include pc {
          font-size: 12px;
          color: $color-white;

          &:hover {
            color: $color-white;
            opacity: 0.7;
          }
        }

        &-it {
          @extend .footerLink_listItm;

          &:hover {
            //color: $color-white;
            //opacity: 0.7;
          }
        }

        &-electric {
          @extend .footerLink_listItm;

          &:hover {
            //color: $color-green;
          }
        }
      }
    }
  }
  // end .footerLink

  // .footerOther
  .footerOther {
    display: flex;
    @include sp {
      flex-wrap: wrap;
    }

    &--en {
      border-top: 2px solid $color-aqua;
      @include pc {
        border: 0;
      }
    }

    // .footerOther_list
    &_list {
      @include pc {
        margin: 0 30px 0 0;
      }

      @include sp {
        width: 100%;
      }

      &:nth-child(2n-1) {
        .footerOther_listItm {
          @include sp {
            //border-right: 2px solid $color-aqua;
          }
        }
      }

      // .footerOther_listItm
      &Itm {
        @include pc {
          font-size: 12px;
          color: $color-white;
        }

        @include sp {
          padding: 2% 8%;
          font-size: 3.2vw;
          color: $color-white;
          text-align: center;
          //background: $color-white;
          //border-bottom: 2px solid $color-aqua;
          display: block;
        }
      }
      // end .footerOther_listItm
    }
    // end .footerOther_list
  }
  // end .footerOther

  // .footerSns
  &Sns {
    @include pc {
      display: flex;
      justify-content: flex-end;
    }

    @include sp {
      width: 42%;
      margin: 0 auto;
      padding: 8% 0;
      display: flex;
      justify-content: space-between;
    }

    &_list {
      @include pc {
        margin: 0 0 0 15px;
      }

      @include sp {
        width: 25%;
      }
    }
  }
  // end .footerSns

  // .footerLogo
  &Logo {
    background: $color-blue;

    @include sp {
      padding: 8% 0 25%;
      border-top: 1px solid $color-white;
    }

    // .footerLogo_inner
    &_inner {
      @include pc {
        width: 1120px;
        margin: 0 auto;
        padding: 30px 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
      }
    }
    // end .footerLogo_inner

    // .footerLogo_box
    &_box {
      @include sp {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    // end .footerLogo_box

    // .footerLogo_logo
    &_logo {
      @include pc {
        margin-right: 30px;
      }
      @include sp {
        max-width: 341px;
        width: 50%;
      }

      img {
        @include pc {
          width: 230px;
        }
      }
    }
    // end .footerLogo_logo

    // .footerLogo_copy
    &_copy {
      color: $color-white;

      @include pc {
        font-size: 10px;
      }

      @include sp {
        padding: 8% 0 0;
        font-size: 2.1vw;
      }
    }
    // end .footerLogo_copy
  }
  // end .footerLogo
}
// end .footer

.footerFixNav {
  @include pc {
    display: none;
  }
  @include sp {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
    .footerFixNav_cont {
      display: flex;
      justify-content: space-between;
      .footerFixNav_contList {
        width: 32%;

        &:last-child {
          width: 68%;
        }

        & a {
          display: block;
          padding: 1.8em 1em;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }
}
.segment {
  img {
    width: auto;
  }
}
