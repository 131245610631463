// pc表示
@mixin pc($width: 769px, $print: true) {
  @media screen and (min-width: $width), print {
    @content;
    @if $print != true {
      display: none;
    }
  }
}
// SP表示
@mixin sp($width: 768px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// 印刷表示
@mixin print() {
  @media print {
    @content;
  }
}

@mixin fontsize($size: 14px) {
  font-size: ($size / $baseFont) + rem;
}

@mixin fontsizeVw($size: 14px, $screenSize: 750) {
  font-size: ($size / $screenSize * 100) + vw;
}

@mixin triangle($size: 40, $color: #f3e300) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: #{$size}px #{$size}px 0 0;
  border-color: $color transparent transparent transparent;
}

@mixin flex {
  display: flex;
  flex-wrap: wrap;
}

@mixin flexAuto($max: 100%) {
  flex: 0 0 auto;
  width: auto;
  max-width: $max;
}

@mixin flexChild($w: 100%) {
  flex: 0 0 $w;
  max-width: $w;
}

@mixin flexCalc($w: 0) {
  flex: 0 0 calc(100% - #{$w});
  flex-basis: calc(100% - #{$w});
  max-width: calc(100% - #{$w});
}
