#online .l-contInner {
  width: 940px;
  margin: 0 auto;
}

#online .mainBnr {
  width: 100%;
  position: relative;
}

#online .mainBnr img {
  width: 100%;
}

#online .mainBnr_txt {
  text-align: center;
  line-height: 1.5;
  position: absolute;
  top: 67%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #online .mainBnr_txt {
    width: 90%;
    top: 70%;
    font-size: 0.57143rem;
  }
}

#online .mainTtl {
  text-align: center;
}

#online .mainTtl img {
  width: auto;
  height: 38px;
  margin: auto;
  margin: 80px auto 45px;
}

@media screen and (max-width: 768px) {
  #online .mainTtl img {
    height: 45px;
    margin: 35px auto 30px;
  }
}

#online .mainTtl02 {
  text-align: center;
}

#online .mainTtl02 img {
  width: auto;
  height: 38px;
  margin: auto;
  margin: 80px auto 45px;
}

@media screen and (max-width: 768px) {
  #online .mainTtl02 img {
    height: 25px;
    margin: 35px auto 30px;
  }
}

#online .container {
  width: 50%;
  padding: 80px 0 55px;
  background: url(/asset/img/online/bg_green.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  #online .container {
    width: 100%;
    padding: 45px 0 35px;
  }
}

#online .container:nth-of-type(2) {
  background: url(/asset/img/online/bg_blue.jpg);
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#online .container .ttl02 {
  width: 100%;
  margin-bottom: 3em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #online .container .ttl02 {
    margin-bottom: 1.5em;
  }
}

#online .container .ttl02 img {
  width: auto;
  height: 35px;
}

@media screen and (max-width: 768px) {
  #online .container .ttl02 img {
    height: 28px;
  }
}

#online .container .item {
  padding: 0 6%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 600px;
}

#online .container .item img {
  width: 170px;
  //margin-top: 30px;
}

@media screen and (max-width: 768px) {
  #online .container .item img {
    width: 30%;
    margin-top: 20px;
  }
}

#online .container .txt {
  margin-bottom: 0.5em;
  font-size: 1.07143rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .container .txt {
    font-size: 0.71429rem;
  }
}

#online .container .note {
  font-size: 1rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .container .note {
    margin-bottom: 1em;
    font-size: 0.71429rem;
  }
}

#online .balloon2-left {
  max-width: 380px;
  position: relative;
  display: inline-block;
  margin-left: 15px;
  padding: 10px 10px 30px;
  min-width: 120px;
  color: #555;
  font-size: 16px;
  background: #fff;
  border: solid 1px #e5e5e5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 3px 5px #e5e5e5;
  box-shadow: 0 3px 5px #e5e5e5;
}

#online .balloon2-left:before {
  content: '';
  position: absolute;
  top: 40px;
  left: -24px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid #fff;
  z-index: 2;
}

#online .balloon2-left:after {
  content: '';
  position: absolute;
  top: 40px;
  left: -30px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #e5e5e5;
  z-index: 1;
}

#online .balloon2-left p {
  margin: 0;
  padding: 0;
}

#online .titleLabel03 {
  padding: 13px 0;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 {
    padding: 0 0 5px;
  }
}

#online .titleLabel03 span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span {
    padding: 5px;
    font-size: 1rem;
  }
}

#online .titleLabel03 span.green {
  border-left: 8px solid #4ecd00;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span.green {
    border-left: 6px solid #4ecd00;
  }
}

#online .titleLabel03 span.blue {
  border-left: 8px solid #509aff;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span.blue {
    border-left: 6px solid #509aff;
  }
}

#online .ttl03 {
  max-width: 300px;
  margin: 0 auto;
  padding: 0.6em 0;
  color: #4ecd00;
  background-color: #fff;
  text-align: center;
  letter-spacing: 2px;
  border: 2px solid #4ecd00;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  position: relative;
  z-index: 2;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .ttl03 {
    width: 50%;
    font-size: 1rem;
  }
}

#online .ttl04 {
  max-width: 300px;
  margin: 0 auto;
  padding: 0.6em 0;
  color: #509aff;
  background-color: #fff;
  text-align: center;
  letter-spacing: 2px;
  border: 2px solid #509aff;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  position: relative;
  z-index: 2;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .ttl04 {
    width: 60%;
    font-size: 1rem;
  }
}

#online .l-col3 {
  width: 1120px;
  margin: 0 auto 35px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: 768px) {
  #online .l-col3 {
    width: 100%;
    margin-bottom: 10px;
  }
}

#online .l-col3Inner {
  width: 860px;
  margin: 0 auto;
  padding: 80px 0 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #4ecd00;
}

@media screen and (max-width: 768px) {
  #online .l-col3Inner {
    width: 70%;
    padding: 25px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

#online .l-col3 .cardThum {
  width: 12%;
  height: auto;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardThum {
    width: 18%;
  }
}

#online .l-col3 .cardThum01 {
  -webkit-transform: translate(20px, 35px);
  -ms-transform: translate(20px, 35px);
  transform: translate(20px, 35px);
}

#online .l-col3 .cardThum02 {
  -webkit-transform: translate(-15px, 50px);
  -ms-transform: translate(-15px, 50px);
  transform: translate(-15px, 50px);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardThum02 {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-transform: translate(-20px, -70px) scale(1.2);
    -ms-transform: translate(-20px, -70px) scale(1.2);
    transform: translate(-20px, -70px) scale(1.2);
  }
}

#online .l-col3Item {
  width: 33.3333%;
  padding: 0 3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col3Item {
    width: 70%;
    margin: 0 15%;
    padding: 0;
  }
}

#online .l-col3Item:not(:last-child) {
  border-right: 2px solid #c4c4c4;
}

@media screen and (max-width: 768px) {
  #online .l-col3Item:not(:last-child) {
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
  }
}

#online .l-col3 .cardTtl01 {
  width: 100%;
  margin: 0 auto 1em;
  padding: 0.8em 0;
  color: #fff;
  background-image: url(/asset/img/online/ttl_online_bg01.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01 {
    width: 100%;
    padding-bottom: 1.3em;
    margin-top: 20px;
    background-image: url(/asset/img/online/ttl_online_bg01-sp.png);
    background-size: contain;
    font-size: 0.85714rem;
  }
}

#online .l-col3 .cardTtl01.bgLight {
  background-image: url(/asset/img/online/ttl_online_bg02.png);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01.bgLight {
    background-image: url(/asset/img/online/ttl_online_bg02-sp.png);
  }
}

#online .l-col3 .cardTtl01.bgDefault {
  background-image: url(/asset/img/online/ttl_online_bg03.png);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01.bgDefault {
    background-image: url(/asset/img/online/ttl_online_bg03-sp.png);
  }
}

#online .l-col4 {
  width: 1120px;
  margin: 0 auto;
  padding: 80px 40px 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #509aff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col4 {
    width: 70%;
    padding: 25px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

#online .l-col4Item {
  width: 25%;
  padding: 0 3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col4Item {
    width: 70%;
    margin: 0 15%;
    padding: 0;
  }
}

#online .l-col4Item:not(:last-child) {
  border-right: 2px solid #c4c4c4;
}

@media screen and (max-width: 768px) {
  #online .l-col4Item:not(:last-child) {
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
  }
}

#online .l-col4 .cardTtl01 {
  width: 100%;
  margin: 0 auto 1em;
  padding: 0.8em 0;
  color: #fff;
  background-image: url(/asset/img/online/ttl_online_bg01.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01 {
    width: 75%;
    margin-top: 20px;
    padding-bottom: 1.3em;
    background-image: url(/asset/img/online/ttl_online_bg01-sp.png);
    font-size: 0.85714rem;
  }
}

#online .l-col4 .cardTtl01.bgLight {
  background-image: url(/asset/img/online/ttl_online_bg04.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgLight {
    background-image: url(/asset/img/online/ttl_online_bg04-sp.png);
  }
}

#online .l-col4 .cardTtl01.bgMid {
  background-image: url(/asset/img/online/ttl_online_bg05.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgMid {
    background-image: url(/asset/img/online/ttl_online_bg05-sp.png);
  }
}

#online .l-col4 .cardTtl01.bgDefault {
  background-image: url(/asset/img/online/ttl_online_bg06.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgDefault {
    background-image: url(/asset/img/online/ttl_online_bg06-sp.png);
  }
}

#online .cardTxt {
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .cardTxt {
    font-size: 0.71429rem;
  }
}

#online .cardTxt:last-of-type {
  margin-bottom: 1.5em;
}

#online .cardNote {
  margin-top: 0.5em;
  line-height: 1.5;
  font-size: 0.85714rem;
}

@media screen and (max-width: 768px) {
  #online .cardNote {
    margin-bottom: 1em;
    font-size: 0.71429rem;
  }
}

#online .cardImg {
  width: 100%;
  margin: auto auto 0;
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 768px) {
  #online .cardImg {
    width: 80%;
    margin-bottom: 20px;
  }
}

#online .l-moreBtn {
  margin-top: 70px;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  #online .l-moreBtn {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

#online .entryBtn {
  width: 400px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #online .entryBtn {
    width: 80%;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.92857rem;
  }
}

#online .faq {
  padding-bottom: 100px;
  background: url(/asset/img/common/bg_stripe_yellow.png) repeat;
}

@media screen and (max-width: 768px) {
  #online .faq {
    padding-bottom: 50px;
  }
}
