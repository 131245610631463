.searchBox {
  background-color: $color-yellow;
  padding: 10px;
  @include pc() {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  @include sp() {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    background-color: #F5AC3C;
  }
  .gsc-control-cse {
    border: none !important;
    background: none !important;
    padding: 0 !important;
  }

  .gsc-search-box-tools .gsc-search-box .gsc-input {
    .gsc-input-box .gsc-input {
      width: 290px !important;
      padding-right: 0 !important;
      padding-left: 6px !important;
      height: 30px !important;
      margin: 0 !important;
      background-position-x: 4px !important;
      @include fontsize(14);
      color: $color-black;
      &::placeholder {
        @include fontsize(14);
      }
    }

    @include sp() {
      width: 84% !important;
      height: auto !important;
      @include fontsize(14);

      .gsc-input-box .gsc-input {
        width: 100% !important;
        padding: 4% !important;
        background-position-x: 4% !important;
        &::placeholder {
          @include fontsizeVw(26);
        }
      }
    }
  }

  /* 検索ボタンの背景をボタンのように見せかける */
  .gsc-search-box {
    margin: 0;
    .gsc-search-button {
      position: relative;
      background-color: $color-blue;
      cursor: pointer;
      width: auto;
      border: none;
      text-align: center;
      @include sp() {
        width: 18%;
      }
    }

    .gsc-search-button-v2 {
      margin: 0;
      padding: 0;
      width: 50px !important;
      height: 32px;

      @include sp() {
        width: 100% !important;
        height: 30px;
      }
      &:before {
        content: '検索' !important;
        color: #fff;
        @include fontsize(10);
        @include sp() {
        }
      }

      > svg {
        display: none;
      }
    }
  }

  table.gsc-search-box {
    td.gsib_a {
      padding: 0 !important;
    }
    td.gsib_b {
      .gsst_b {
        width: 28px;
      }
    }
  }
}
