#campuslife {
  .mainVisual {
    background: url('/asset/img/campuslife/kv.jpg') no-repeat center center/cover;
    background-size: cover;
  }

  img {
    width: 100%;
    vertical-align: middle;
  }
  .m_space {
    margin-bottom: 0;
    padding: 0;
  }

  .innerRap {
    padding: 5% 3%;
    line-height: 1.6;
    @include pc {
      padding: 80px 0;
      max-width: 1120px;
      margin: 0 auto;
    }
  }

  .lifeRap {
    @include pc {
      display: flex;
      justify-content: space-between;
    }

    &__box {
      padding: 20px;
      background: #fae8e9;
      position: relative;
      @include pc {
        width: 43%;
        min-width: 445px;
      }
      &--type02 {
        background: #f9feeb;
      }
    }
    &__pic {
      width: 50%;
      margin: 0 auto;
      position: absolute;
      top: 100px;
      right: 0;
      @include pc {
        margin: 0;
        width: 36%;
        top: 85px;
        right: 5px;
      }
    }

    &__ttl {
      font-size: 19px;
      color: $color-course-electric;
      margin-bottom: 15px;
      &--type02 {
        color: $color-course-information;
      }
    }
    &__category{
      font-size: 14px;
      color: #fff;
      background: $color-course-electric;
      display: inline-block;
      padding: 5px 10px;
      margin-bottom: 5px;
      &--type02 {
        background: $color-course-information;
        font-size: 14px;
        color: #fff;
        display: inline-block;
        padding: 5px 10px;
        margin-bottom: 5px;
      }
    }
    &__name {
      font-size: 12px;
      @include pc {
        font-size: 15px;
      }
      margin-bottom: 15px;
      span {
        font-size: 14px;
        @include pc {
          font-size: 20px;
        }
      }
    }
    &__comment{
      font-size: 14px;
      margin-bottom: 80px;
      width: 50%;
      @include pc {
        width: 60%;
      }
      span{
        font-size: 18px;
        color: $color-course-electric;
        display: inline-block;
        margin-bottom: 5px;
      }

      &--type02 {
        font-size: 14px;
        margin-bottom: 80px;
        width: 50%;
        @include pc {
          width: 60%;
        }
        span{
          font-size: 18px;
          color: $color-course-information;
          display: inline-block;
          margin-bottom: 5px;
        }
      }
    }

    &__schedule {
      padding: 5% 3% 3%;
      background: #fff;
      position: relative;
      margin-top: -20px;
      @include pc {
        margin-top: 0;
      }

      &--type02 {
        margin-top: -300px;
        @include pc {
          margin-top: 0;
        }
      }

      .what_day {
        background: $color-course-electric;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 10px;
        color: #fff;
        span {
          background: url('/asset/img/campuslife/time.png') no-repeat;
          background-size: 18px;
          background-position: left center;
          padding-left: 25px;
        }
        &--type02 {
          background: $color-course-information;
        }
      }

      dl {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        dt {
          color: $color-course-electric;
          width: 20%;
          @include pc {
            width: 12%;
          }
          span {
            border-bottom: 1px solid $color-course-electric;
            display: inline-block;
          }
        }
        dd {
          width: 86%;
          .small {
            font-size: 12px;
          }
          .photo {
            @include pc {
              display: flex;
              justify-content: space-between;
            }
          }
          .photo_l {
            margin-bottom: 10px;
            @include pc {
              width: 55%;
            }
          }
          .photo_r {
            @include pc {
              width: 40%;
            }
          }
        }
      }

      &--type02 {
        border-color: $color-course-information;
        .type02 {
          color: $color-course-information;
          span {
            border-color: $color-course-information;
          }
        }
        .under {
          padding-bottom: 30px;
        }
      }
    }
    //
  }
  //

  .calendarRap {
    background: #F2F7FA;
    &__month {
      @include pc {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
      }

      .monthList {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        @include pc {
          display: block;
          width: 12%;
          letter-spacing: -1px;
          margin-bottom: 0;
        }
        &::before {
          @include pc {
            content: '';
            position: absolute;
            display: block;
            background: #023281;
            height: 4px;
            width: 54%;
            top: 62px;
            right: -54%;
          }
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          background: #023281;
          height: 40px;
          width: 4px;
          bottom: -40px;
          left: 15%;
          @include pc {
            display: none;
          }
        }

        & .monthImg {
          width: 30%;
          @include pc {
            width: auto;
          }
        }

        &.last {
          &::before,
          &::after {
            display: none;
          }
        }
      }

      &__list {
        width: 62%;
        @include pc {
          width: auto;
          margin-top: 10px;
        }

        li {
          margin-bottom: 5px;
          font-size: 13px;
          text-indent: -8px;
          padding-left: 8px;
          &::before {
            content: '●';
            color: $color-blue;
            font-size: 10px;
            display: inline-block;
            margin-right: 3px;
          }
        }
      }
    }
  }
  //

  .internationalBox {
    background: #f2f5fa;
    padding: 0 3%;
    &__pic {
      margin-bottom: 30px;
      @include pc {
        width: 60%;
        max-width: 715px;
        margin: 0 auto 50px auto;
      }
    }
    &__picTxt {
      text-align: center;
      margin-top: 15px;
      span {
        font-size: 18px;
        color: $color-blue;
        display: block;
        margin-bottom: 10px;
        @include pc {
          font-size: 24px;
        }
      }
    }
    .titleLabel02 {
      &::after {
        background: #fff;
      }
    }
    .internationalList {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      & li {
        margin: 0 1% 1% 0;
        width: 48%;
        @include pc {
          width: 24%;
        }
        &::before {
          content: '●';
          color: $color-blue;
          font-size: 10px;
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
  //
}
//
