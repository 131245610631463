.facility {
  max-width: 1020px;
  padding: 3%;
  margin: 30px 0;
  line-height: 1.6;
  @include fontsize(14);
  -webkit-text-size-adjust: 100%;
  @include pc {
    margin: 60px auto;
  }
  @include sp() {
    margin-bottom: 50px;
  }
  img {
    width: 100%;
  }
  .textCenter {
    text-align: center;
    &:not(:first-of-type) {
      margin-bottom: 80px;
    }
  }
  .facility3colRap {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include sp() {
      flex-wrap: wrap;
    }
    &.rowReverse {
      @include pc {
        flex-direction: row-reverse;
      }
    }
    .textRap {
      width: 30%;
      @include sp() {
        width: 100%;
      }
    }

    h4 {
      height: 40px;
      margin-bottom: 30px;
      padding: 0 15px;
      color: $color-blue;
      line-height: 40px;
      letter-spacing: 0.5px;
      border-radius: 2px;
      background-color: $color-yellow;
      box-shadow: 3px 3px 3px $color-platinum-gray;
      @include fontsize(16);
      @include sp() {
        margin-bottom: 20px;
      }
    }
    p {
      @include sp() {
        margin-bottom: 30px;
      }
    }
    picture {
      width: 30%;
      @include sp() {
        width: 48.5%;
        margin-top: 15px;
      }
    }
  }
  .facility2colRap {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include sp() {
      margin-top: 56px;
    }

    &.change202303{
      .textRap {
        margin-bottom: 0 !important;
      }
    }

    .textRap {
      width: 48%;
      margin-bottom: 80px;
      display: flex;
      flex-direction: column;
      @include sp() {
        width: 100%;
        margin-bottom: 56px;
      }

      &.col1{
        width: 100%;
        order: 1;
        @include sp() {
          order: 0;
        }
      }
    }
    h4 {
      height: 40px;
      margin-bottom: 30px;
      padding: 0 15px;
      color: $color-blue;
      line-height: 40px;
      letter-spacing: 0.5px;
      border-radius: 2px;
      background-color: $color-yellow;
      box-shadow: 3px 3px 3px $color-platinum-gray;
      order: 2;
      @include fontsize(16);
      @include sp() {
        margin-bottom: 20px;
        order: 1;
      }
    }
    p {
      order: 3;

      @include sp() {
        margin-bottom: 20px;
        order: 2;
      }
    }
    picture {
      margin-bottom: 30px;
      order: 1;
      @include sp() {
        margin: 0;
        order: 3;
      }
      &.roompickup{
        border: solid 10px #f3e100;
        margin-bottom: 20px;
        @include sp() {
          margin: 0;
        }
      }
    }
  }
  .facility2colAsy {
    display: flex;
    justify-content: space-between;
    @include sp() {
      flex-wrap: wrap;
    }

    .textRap {
      width: 25%;
      @include sp() {
        width: 100%;
      }
    }
    h4 {
      height: 40px;
      margin-bottom: 30px;
      padding: 0 15px;
      color: $color-blue;
      line-height: 40px;
      letter-spacing: 0.5px;
      border-radius: 2px;
      background-color: $color-yellow;
      box-shadow: 3px 3px 3px $color-platinum-gray;
      @include fontsize(16);
      @include sp() {
        margin-bottom: 20px;
      }
    }
    p {
      @include sp() {
        margin-bottom: 30px;
      }
    }
    picture {
      width: 70%;
      @include sp() {
        width: 100%;
        order: 3;
      }
    }
  }
  .facilityRapBgBlue {
    background: $color-light-blue;
  }

  &.onlineClasses {
    @include pc {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .facility2colRap {
      margin-top: 0;
      @include sp {
        flex-direction: column-reverse;
      }
      .textRap {
        @include sp {
          margin-bottom: 0;
        }
      }
    }
  }
}

.facilityBgBlue {
  background: $color-light-blue;

  img {
    width: 100%;
  }
  .facilityInner {
    padding-top: 64px;
    // max-width: 1120px;
    // margin: 0 auto;
    // padding: 100px 3% 120px 3%;
    // line-height: 1.6;
    // @include fontsize(14);
    // -webkit-text-size-adjust: 100%;
    // @include sp {
    //   padding-top: 60px;
    //   padding-bottom: 0;
    // }
  }
  .readText {
    .textCenter {
      text-align: center;
      margin-bottom: 0;
      &:last-of-type {
        margin-bottom: 80px;
        @include sp() {
          margin-bottom: 30px;
        }
      }
    }
  }
  .facility2colImg {
    margin: 30px 0;
    @include pc {
      margin: 60px 0 80px 0;
    }
    ul {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    li {
      margin: 0 3%;
      @include pc {
        width: 24%;
      }
    }
  }
  .facility2colRap {
    // max-width: 1020px;
    // margin: 75px auto 0 auto;
    // display: flex;
    // justify-content: center;
    // flex-wrap: wrap;
    // // flex-direction: column;
    // @include sp() {
    //   margin-top: 56px;
    //   // flex-direction: column-reverse;
    // }

    .textRap {
      // width: 48%;
      // margin-bottom: 70px;
      // display: flex;
      // flex-direction: column;
      // @include sp() {
      //   width: 100%;
      //   margin-bottom: 30px;
      // }
      // &:not(:first-of-type) {
      //   margin-left: 4%;
      //   @include sp() {
      //     margin-left: 0;
      //     margin-bottom: 0;
      //   }
      // }

      h4 {
        height: 40px;
        margin-bottom: 30px;
        /*padding-left: 40px;*/
        color: $color-blue;
        line-height: 40px;
        letter-spacing: 0.5px;
        border-radius: 2px;
        background-color: $color-yellow;
        box-shadow: 3px 3px 3px $color-platinum-gray;
        @include fontsize(16);
        position: relative;
        @include sp() {
          margin-bottom: 20px;
        }

        img {
          width: 24px !important;
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }
  h3 {
    margin-bottom: 30px;
    color: #204f8e;
    text-align: center;
    @include fontsize(30);
    @include sp() {
      @include fontsize(18);
    }
  }

  .facility3colRap {
    // max-width: 1020px;
    // margin: 0 auto;
    // display: flex;
    // justify-content: space-between;
    // @include sp() {
    //   flex-wrap: wrap;
    // }
    // .textRap {
    //   width: 30%;
    //   @include sp() {
    //     width: 100%;
    //     margin-top: 56px;
    //   }
    // }

    h4 {
      height: 40px;
      margin-bottom: 30px;
      padding-left: 40px;
      color: $color-blue;
      line-height: 40px;
      letter-spacing: 0.5px;
      border-radius: 2px;
      background-color: $color-yellow;
      box-shadow: 3px 3px 3px $color-platinum-gray;
      @include fontsize(16);
      position: relative;
      @include sp() {
        margin-bottom: 20px;
      }
    }
    // picture {
    //   width: 30%;
    //   @include sp() {
    //     width: 48.5%;
    //     margin-top: 15px;
    //   }
    // }
  }
}
