#sitemap {
  .sitemap {
    &Box {
      border: solid 1px $color-platinum-gray;
      //padding: 20px;
      margin-bottom: 20px;
      h3 {
        background-color: $color-white-smoke;
        padding: 20px;
        box-sizing: border-box;
        @include sp() {
          width: 100%;
          padding: 4.5%;
        }
      }
      .link {
        &:before {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 0 4px 6.9px;
          border-color: transparent transparent transparent $color-blue;
          margin-right: 6px;
          margin-bottom: 1px;
        }
      }
    }
    &_linkList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &::after {
        content: '';
        display: block;
        width: 33%;
      }
    }
    &_linkItem {
      box-sizing: border-box;
      width: 33%;
      padding: 20px;
      @include sp() {
        width: 100%;
        padding: 4.5%;
      }
      dt {
        font-weight: bold;
      }
      dd {
        padding: 20px;
        @include sp() {
          width: 100%;
          padding: 4.5%;
        }
      }
    }
  }
}
