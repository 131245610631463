/* =================================================
共通
==================================================== */

/* 脱着して使うクラス名________________ */
.df {
  display: flex;
  background-image: linear-gradient(#ddd, #333);
}

@include pc {
  .pc-df {
    display: flex;
  }
}

.fwBold {
  font-weight: bold;
}

.taCenter {
  text-align: center;
}

.tIndent {
  padding-left: 1em;
  text-indent: -1em;
}

.sup {
  vertical-align: super;
  font-size: smaller;
}

.pB0 {
  padding-bottom: 0 !important;
}

/* =================================================
出し分け
==================================================== */
@include sp {
  .pc {
    display: none !important;
  }
}

@include pc {
  .sp {
    display: none !important;
  }
}

/* =================================================
タイトル
==================================================== */
.ttl {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  color: tomato;
}

/* =================================================
背景
==================================================== */
.bg-stripe_yellow {
  background: url(/asset/img/common/bg_stripe_yellow.png) repeat;
}
.bg-stripe_blue {
  background: url(/asset/img/common/bg_stripe_blue.png) repeat;
}
.bg-stripe_light_blue {
  background: url(/asset/img/common/bg_stripe_light_blue.png) repeat;
}
.bg-stripe_pink {
  background: url(/asset/img/common/bg_stripe_pink.png) repeat;
}
.bg-stripe_green {
  background: url(/asset/img/common/bg_stripe_green.png) repeat;
}

/* =================================================
TOP-コンテンツ
==================================================== */
.content {
  text-align: center;
  padding: 50px 0;
  background-color: cornsilk;
}

.anker {
  margin-top: -100px;
  padding-top: 100px;
}

/* =================================================
テキスト
==================================================== */
.planetxt {
  font-size: 4vw;
  line-height: 1.3;

  @include pc {
    font-size: 14px;
    line-height: 1.5;
  }
}

.smallTxt {
  font-size: 12px;
  padding-bottom: 10px;
  @include pc {
    display: none;
  }
}

/* =================================================
アイコン系
==================================================== */
.ico {
  // .ico-tri 左に白色の三角のアイコン
  &-tri {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 7px solid $color-white;
      border-right: 7px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    // end .ico-tri
  }

  // .ico-arrow
  &-arrow {
    position: relative;

    &::after {
      content: '';
      display: block;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

      @include pc {
        width: 5px;
        height: 5px;
        border-right: 2px solid $color-blue;
        border-bottom: 2px solid $color-blue;
        right: 0;
      }

      @include sp {
        width: 1.2vw;
        height: 1.2vw;
        border-right: 0.5vw solid $color-blue;
        border-bottom: 0.5vw solid $color-blue;
        right: 5.3vw;
      }
    }

    @include sp {
      &.megaInner_listItm {
        &-engineer {
          &::after {
            border-color: $color-red;
          }
        }

        &-electronics {
          &::after {
            border-color: $color-blue;
          }
        }

        &-electric {
          &::after {
            border-color: $color-green;
          }
        }
      }
    }
  }
  // end .ico-arrow

  // .ico-plus
  &-plus {
    @include pc {
      display: none;
    }

    @include sp {
      width: 14vw;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      border-left: 2px solid $color-platinum-gray;

      &::before,
      &::after {
        transform-origin: center center;
        content: '';
        display: block;
        width: 6vw;
        height: 4%;
        background: $color-blue;
        transition: all 0.2s ease-in-out;
      }

      &::before {
        transform: rotate(0);
        content: '';
      }

      &::after {
        position: absolute;
        top: 50%;
        left: calc(50% - 3vw);
        transform: rotate(90deg);
        opacity: 1;
      }

      &.is-open {
        &::before {
          transform: rotate(-180deg);
        }

        &::after {
          transform: rotate(180deg);
          opacity: 0;
        }
      }
    }
  }
  // end .ico-plus
}

/* =================================================
下層ページ用
==================================================== */

a.tLink {
  text-decoration: underline;
  color: #00a1e9;
}

// ifremeレスポンシブ対応
//=================================================//
.l-iFrame {
  position: relative;
  width: 100%;
  padding: 60% 0 0 0;
  margin-bottom: 60px;
  @include pc {
    padding: 40% 0 0 0;
    margin-bottom: 60px;
  }

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// パンくず
//=================================================//
.breadCrumb {
  padding: 10px 10px;
  @include fontsize(10);
  @include pc {
    margin-bottom: 40px;
    padding: 10px 40px;
    @include fontsize(12);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  @include sp {
    display: none;
  }

  a {
    color: $color-text;
  }

  &_home {
    width: 16px;
  }

  &_list {
    display: inline-block;
    vertical-align: middle;

    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 5px solid $color-yellow;
      border-right: 5px solid transparent;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      margin: 0 0 3px 8px;
      vertical-align: middle;
    }

    &:last-child::after {
      display: none;
    }
  } // &_list
} //

// MVスタイル
//=================================================//
.mainVisual {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /*
  background: url('/asset/img/common/mv.jpg') no-repeat center center/cover;
  background-size: cover;
  min-height: 150px;
  @include pc {
    min-height: 200px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url('/asset/img/common/bg_stripe_yellow.png');
    opacity: 0.6;
  }

  &.course {
    background: url('/asset/img/common/mv_course.jpg') no-repeat center center/cover;
  }

  &_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    color: $color-blue;
    letter-spacing: 5px;
    @include fontsize(21);
    line-height: 1.4;
    width: 100%;
    @include pc {
      @include fontsize(30);
    }

    span {
      display: block;
      max-width: 40%;
      margin: 0 auto 10px auto;
      color: $color-white;
      background: $color-blue;
      @include fontsize(12);
      border-radius: 30px;
      padding: 5px 20px 7px 20px;
      letter-spacing: 3px;
      @include pc {
        @include fontsize(14);
        max-width: 14%;
      }
    }
  } // &_title
  */

  &_img {
    display: block;

    img {
      width: 100%;
    }
  }

  &_titleBlock {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 30px;
    color: $color-white;
    background: rgba($color-blue, 0.85);
    box-sizing: border-box;
    z-index: 1;
    @include pc {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      min-width: 386px;
    }
    @include sp {
      justify-content: center;
      width: 90%;
      margin-top: -10%;
    }

    .title-top,
    .title-bottom {
      display: block;
      @include sp() {
        text-align: center;
      }
    }
    .title-top {
      position: relative;
      font-weight: bold;
      @include pc() {
        @include fontsize(28);
        margin-bottom: 25px;
        padding-bottom: 30px;
      }
      @include sp() {
        @include fontsizeVw(40);
        margin-bottom: 8%;
        padding-bottom: 10%;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: inline-block;
        width: 1em;
        height: 2px;
        background: $color-white;
        @include sp() {
          left: calc(50% - 0.5em);
        }
      }
    }
    .title-bottom {
      font-weight: bold;
      line-height: 1.3em;
      @include pc() {
        @include fontsize(32);
      }
      @include sp() {
        @include fontsizeVw(52);
      }
    }
    .title-sub {
      color: $color-white;
      @include pc() {
        @include fontsize(14);
        padding-top: 10px;
        margin-top: 10px;
      }
      @include sp() {
        @include fontsizeVw(28);
        margin-top: 4%;
        text-align: center;
      }
    }
  }
} //

// コンテンツ幅
//=================================================//
.l-contentRap {
  max-width: 1020px;
  padding: 3%;
  margin: 30px 0;
  line-height: 1.6;
  @include fontsize(14);
  -webkit-text-size-adjust: 100%;
  @include pc {
    margin: 60px auto;
  }

  &.wide {
    max-width: 1120px;
  }

  &_space {
    margin-bottom: 40px;
    @include pc {
      margin-bottom: 75px;
    }
  }
} //

.l-announceBoxRap {
  margin-top: 30px;
}
.announceBox {
  background-color: $color-light-gray;
  padding: 20px;
  @include sp() {
    padding: 3.5%;
  }
  &.center {
    display: flex;
    justify-content: center;
    @include sp() {
      flex-direction: column;
    }
  }
  .fontBig {
    @include fontsize(26);
    font-weight: bold;
    display: inline-flex;
    align-items: center;
  }
  .announceText {
    padding: 0 30px;
    @include sp() {
      @include fontsizeVw(36);
      padding: 0;
    }
  }
  .tel {
    &:before {
      content: '';
      display: inline-block;
      height: 30px;
      width: 30px;
      background: url(/asset/img/common/ico_tel.png) no-repeat center center;
      background-size: cover;
      margin-right: 6px;
      @include sp() {
        height: 20px;
        width: 20px;
      }
    }
  }
}

.l-imgMiddle {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.l-president {
  &__img {
    width: 25%;
    margin: 0 0 10px 30px;
    float: right;
    img {
      width: 100%;
    }
  }
}

// ブロック
//=================================================//
.l-stripeBox {
  padding: 10px;
  background-color: $color-white;
  .l-stripeBoxInner {
    background-color: $color-white;

    padding: 60px 0;
    @include sp() {
      padding: 8% 5%;
    }
    .stripeBoxInnerText {
      text-align: center;
      @include fontsize(16);
      line-height: 3;
      @include sp() {
        @include fontsizeVw(30);
        line-height: 2.5;
      }
    }
  }
}

// テキスト周り
//=================================================//
.l-textRap {
  margin-top: 15px;
  @include pc {
    margin-top: 30px;
  }
}

.l-textLead {
  margin-bottom: 20px;
}

.textLeadRight {
  text-align: right;
  padding-top: 10px;
}

.l-textCatch {
}
.textCatch {
  text-align: center;
  font-weight: bold;
  @include pc() {
    @include fontsize(30);
    margin-bottom: 40px;
  }
  @include sp() {
    @include fontsizeVw(34);
    margin-bottom: 4%;
  }
  &.courseInformation {
    color: $color-course-information;
  }
  &.courseElectronics {
    color: $color-course-electronics;
  }
  &.courseElectric {
    color: $color-course-electric;
  }
  &_secondary {
    @include pc() {
      @include fontsize(20);
      margin-bottom: 20px;
    }
    @include sp() {
      @include fontsize(18);
      margin-bottom: 10px;
    }
  }
}

.l-textList {
  @include pc() {
    margin-bottom: 50px;
  }
  @include sp() {
    margin-bottom: 14%;
  }
}

.textList {
  &_inner {
    padding: 5px 0 0 20px;
  }

  &_item {
    padding-bottom: 20px;
    li {
      margin-bottom: 10px;
      padding-left: 15px;
      text-indent: -15px;
      &:last-child() {
        margin-bottom: 0;
      }
    }
    &--space0 {
      padding-bottom: 0;
      li {
        margin-bottom: 0;
      }
    }
  }

  &__category {
    padding-left: 15px;
    text-indent: -15px;
  }

  &_number {
    @include pc() {
      margin-bottom: 30px;
    }
    @include sp() {
      margin-bottom: 10%;
    }
    &.bottom_margin {
      margin-bottom: 50px;
    }
    &.border {
      border-bottom: solid 1px $color-platinum-gray;
      @include pc() {
        padding-bottom: 30px;
      }
      @include sp() {
        padding-bottom: 10%;
      }
    }
    dt {
      width: 40px;
      height: 24px;
      position: relative;
      background: $color-blue;
      transition: all 0.2s linear;
      margin: 0 10px 0 0;
      display: inline-block;
      vertical-align: middle;
      span {
        width: 100%;
        height: 25px;
        line-height: 22px;
        font-size: 20px;
        color: $color-white;
        text-align: center;
        display: block;
        position: relative;
        z-index: 1;
      }

      &:before,
      &:after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: $color-blue;
        transition: all 0.2s linear;
        z-index: 0;
      }

      &:before {
        transform: rotate(60deg);
      }
      &:after {
        transform: rotate(-60deg);
      }

      &.yellow {
        background: $color-yellow;
        &:before,
        &:after {
          background: $color-yellow;
        }
        span {
          color: $color-blue;
        }
      }
    }

    dd {
      display: inline-block;
      width: 80%;
      vertical-align: top;
      @include pc {
        width: 94%;
      }

      &.fontBig {
        @include fontsize(16);
        font-weight: bold;
      }
    }
  } //&_number
}

// ボタン
//=================================================//
.l-buttonLayout {
  margin: 40px auto 0 auto;
  @include pc {
    width: 60%;
  }

  &.bottom_margin {
    margin-bottom: 80px;
  }

  &--en {
    @include pc {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    li {
      @include pc {
        width: 31%;
        margin: 5px 10px;
      }
    }
  }

  &--enPdf {
    @include pc {
      width: 80%;
    }
  }

  .buttonLabel {
    line-height: 1.6;
  }
}

button {
  &.buttonLabel {
    width: 100%;
    font-size: 1rem;
  }
}
.buttonLabel {
  background: $color-blue;
  color: $color-white;
  border: 2px solid $color-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  padding: 15px 30px;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 100px;

  &::after {
    content: '';
    position: absolute;
    right: 20px;
    //top: 45%;
    width: 5px;
    height: 5px;
    top: 44%;
    border-top: 1px solid $color-white;
    border-right: 1px solid $color-white;
    transform: rotate(45deg);
    @include pc {
      width: 10px;
      height: 10px;
      top: 27px;
    }
  }

  @include pc() {
    @include fontsize(16);
    padding: 20px 10px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      content: '';
      width: 120%;
      height: 100%;
      background: $color-white;
      transform-origin: right top;
      transform: scale(0, 1);
      transition: transform 0.4s;
    }
    &:hover {
      color: $color-blue;
      &::after {
        border-top: 1px solid $color-blue;
        border-right: 1px solid $color-blue;
      }
      &::before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }
  &.-yellow {
    background: $color-yellow;
    color: $color-blue;
    border: 2px solid $color-yellow;

    &::after {
      border-top: 1px solid $color-blue;
      border-right: 1px solid $color-blue;
    }
  }
}

//見出しタイトル
//=================================================//
.l-titleLabel {
  margin-top: 20px;
}

.titleLabel01 {
  text-align: center;
  padding-bottom: 25px;
  @include pc {
    padding-bottom: 50px;
  }

  &_top {
    @include fontsize(24);
    //color: $color-blue;
    letter-spacing: 2px;
    padding: 0 5px 20px 5px;
    //background: url('/asset/img/common/bg_stripe_yellow.png') repeat-x center 16px;
    //background-size: 5px 18px;
    @include pc {
      @include fontsize(32);
      //background-position: center 28px;
      padding-bottom: 50px;
      letter-spacing: 4px;
    }
  }

  &_bottom {
    display: block;
    @include fontsize(12);
    margin-top: 10px;
    letter-spacing: 3px;
    color: $color-blue;
    font-weight: bold;
    @include pc {
      @include fontsize(14);
    }
  }

  &.courseInformation {
    .titleLabel01_bottom {
      color: $color-course-information;
    }
  }
  &.courseElectronics {
    .titleLabel01_bottom {
      color: $color-course-electronics;
    }
  }
  &.courseElectric {
    .titleLabel01_bottom {
      color: $color-course-electric;
    }
  }
} //.title_label01

.titleLabel02 {
  @include fontsize(20);
  letter-spacing: 2px;
  position: relative;
  font-weight: normal;
  margin-bottom: 30px;
  color: $color-blue;
  @include pc {
    @include fontsize(24);
    margin-bottom: 60px;
  }

  &::before {
    content: '';
    width: 16%;
    height: 3px;
    background: $color-blue;
    position: absolute;
    left: 0;
    bottom: -15px;
    z-index: 1;
  }
  &::after {
    content: '';
    width: 100%;
    height: 3px;
    background: $color-light-gray;
    position: absolute;
    left: 0;
    bottom: -15px;
    z-index: 0;
  }
  &.courseInformation {
    &::before {
      background-color: $color-course-information;
    }
  }
  &.courseElectronics {
    &::before {
      background-color: $color-course-electronics;
    }
  }
  &.courseElectric {
    &::before {
      background-color: $color-course-electric;
    }
  }
} //.title_label02

.titleLabel03 {
  @include fontsize(20);
  font-weight: normal;
  padding-bottom: 20px;
  text-align: center;
  @include pc {
    @include fontsize(26);
    padding-bottom: 40px;
  }

  & span {
    display: block;
    //border-left: 4px solid $color-blue;
    padding: 10px 20px;
    @include pc {
      //border-left: 8px solid $color-blue;
    }
  }

  &.courseInformation {
    span {
      border-color: $color-course-information;
    }
  }
  &.courseElectronics {
    span {
      border-color: $color-course-electronics;
    }
  }
  &.courseElectric {
    span {
      border-color: $color-course-electric;
    }
  }
} //.title_label03

.titleLabel04 {
  @include fontsize(18);
  font-weight: bold;
  margin-bottom: 16px;
}

// テーブル関連
//=================================================//
.l-tableLayout {
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include pc {
    white-space: normal;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #bcbcbc;
  }
}

.tableBox {
  width: 100%;
  border-right: 1px solid $color-platinum-gray;
  border-bottom: 1px solid $color-platinum-gray;
  line-height: 1.5;
  box-sizing: border-box;
  @include pc {
    table-layout: fixed;
  }
  &--auto {
    table-layout: auto;
  }

  & th,
  td {
    border-left: 1px solid $color-platinum-gray;
    border-top: 1px solid $color-platinum-gray;
    padding: 10px;
    box-sizing: border-box;
    text-align: left;
    @include pc {
      padding: 20px;
    }

    &.twoColumn {
      display: block;
      //width: 100%;
      @include pc {
        display: table-cell;
      }
    }
    &.bgGlay {
      color: $color-text;
      background: #eee;
    }
    &.bgNavy {
      color: $color-white;
      background: #002155;
      border-right: 1px solid $color-platinum-gray;
      border-bottom: 1px solid $color-platinum-gray;
    }
    &.centerTxt {
      text-align: center;
      vertical-align: middle;
    }
    &.centerItem {
      vertical-align: middle;
    }
  }

  & th {
    background: $color-blue;
    font-weight: normal;
    color: $color-white;
    width: 100%;
    border-right: 1px solid $color-platinum-gray;
    border-bottom: 1px solid $color-platinum-gray;

    @include pc {
      width: 250px;
    }
    &.wMiddle {
      @include pc {
        width: 25%;
      }
    }

    &.reductionW {
      @include pc {
        width: 450px;
      }
    }
  }

  &_lead {
    color: $color-blue;
  }

  &.t-striped {
    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background: #F1F3F9;
          }
        }
        &:nth-child(even) {
          td {
            background: $color-white;
          }
        }
      }
    }
  }

} // tableBox

// 動画関連
//=================================================//
.l-movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  .movie {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

// カードレイアウト
//=================================================//
.l-cardList {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include pc() {
    width: 1120px;
    &::after {
      content: '';
      display: block;
      width: 352px;
    }
  }
  .card {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    @include pc() {
      width: 352px;
      margin-bottom: 32px;
    }
    @include sp() {
      margin-bottom: 5%;
    }
  }
}

// ボタン
//=================================================//
.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 50px;
  border: solid 1px $color-blue;
  color: $color-blue;
  @include fontsize(14);
  background-color: $color-white;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 0;
  @include sp() {
    @include fontsizeVw(28);
    min-width: auto;
    min-height: auto;
    padding: 5% 15%;
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
    background-size: 35%;
    position: absolute;
    top: calc(50% - 10px);
    right: 4%;
  }

  @include pc() {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      content: '';
      width: 120%;
      height: 100%;
      background: $color-blue;
      transform-origin: right top;
      transform: scale(0, 1);
      transition: transform 0.3s;
    }
    &.selected,
    &:hover {
      color: $color-white;
      &::after {
        background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
        background-size: 35%;
      }
      &::before {
        transform-origin: left top;
        transform: scale(1, 1);
      }
    }
  }

  &.btnRound {
    border-radius: 25px;
  }

  &.-blue {
    border: solid 1px $color-blue;
    color: $color-white;
    background-color: $color-blue;

    &::after {
      background-image: url(/asset/img/common/ico_arrow_next_white.svg);
    }

    @include pc() {
      &::before {
        background: $color-white;
      }
      &.selected,
      &:hover {
        color: $color-blue;
        &::after {
          background-image: url(/asset/img/common/ico_arrow_next.svg);
        }
      }
    }
  }
}

.l-moreBtn {
  display: flex;
  justify-content: center;
  margin-top: 44px;
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  @include sp() {
    margin-top: 6%;
  }
}
