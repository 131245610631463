.ai_mainVisual {
  box-sizing: border-box;
  @include pc() {
    height: 560px;
    padding: 10px;
    box-sizing: border-box;
    background: url(/asset/img/ai_robot/pc/bg_ai_04.png) center center no-repeat,
      url(/asset/img/ai_robot/pc/bg_ai_02.png) no-repeat center center/ 100% 100%,
      url(/asset/img/ai_robot/pc/bg_ai_03.png) center center no-repeat,
      url(/asset/img/ai_robot/pc/bg_ai_01.png) no-repeat center center/ 100% 100%;
  }
  @include sp() {
    background: url(/asset/img/ai_robot/sp/bg_ai_01.png) no-repeat top center/ 100%;
    height: calc(100vw * 0.64);
    padding: 4px;
  }
}
.ai_mainVisual_inner {
  height: 100%;
  box-sizing: border-box;
  border: solid $color-white 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .aiTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include sp() {
      width: 80%;
    }
    .aiTitle_main {
      margin-bottom: 26px;
      @include sp() {
        margin-bottom: 4%;
      }
    }
    .aiTitle_sub {
    }
  }
  .aiText {
    color: $color-white;
    margin-top: 20px;
    @include fontsize(24);
    font-weight: bold;
    @include sp() {
      @include fontsizeVw(30);
      text-align: center;
      line-height: 1.5;
      margin-top: 3%;
    }
  }
}

#ai {
  @media screen and (min-width: 769px), print {
    .gnav_contListItm,
    .footerInner_head,
    .footerInner_headItm::after {
      font-weight: 500;
    }
  }
  @media screen and (max-width: 768px) {
    .gnav_contListItm,
    .footerInner_head,
    .footerInner_headItm::after {
      font-weight: 500;
    }
  }

  .ai_content {
    background: url(/asset/img/ai_robot/bg_content.jpg) -100px 0 repeat;
    @include pc() {
      padding-top: 168px;
    }
    @include sp() {
      padding-top: 15%;
    }
  }

  .ai_content2 {
    background: url(/asset/img/ai_robot/bg_content2.jpg) center center no-repeat;
    background-size: cover;
    @include pc() {
      padding-top: 168px;
    }
    @include sp() {
      padding-top: 15%;
    }
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
  }

  .ai_content3 {
    background: #134861 url(/asset/img/ai_robot/bg_column.jpg) center center no-repeat;
    background-size: cover;
    padding: 134px 0 140px 0;
    @include sp() {
      padding: 10% 0 10% 0;
    }
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
  }

  .ai_section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1150px;
    padding-left: 15px;
    padding-right: 15px;
    &--ttl {
      text-align: center;
      @include fontsize(40);
      color: #fff;
      text-shadow: 0 0 0.17em rgba(#24b3d6, 0.5), 0 0 0.35em rgba(#24b3d6, 0.6);
      margin-bottom: 1.5em;
      font-weight: 500;
      letter-spacing: 0.1em;
      line-height: 1.4;
      @include sp() {
        @include fontsize(24);
      }
      > span {
        display: inline-block;
      }
    }

    &_inner {
      @include flex;
    }
  }

  // message
  .ai_message {
    border: solid 2px #4d7a8f;
    background-color: rgba(0, 21, 51, 0.6);
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    @include pc() {
      max-width: 1120px;
      padding: 0 60px 78px 60px;
    }

    @include sp() {
      width: 92%;
      box-sizing: border-box;
      padding: 0 4% 4% 4%;
    }

    .ai_message_title {
      text-align: center;
      @include pc() {
        margin-top: -42px;
        margin-bottom: 30px;
      }
      @include sp() {
        height: 55px;
        width: auto;
        margin: -20px auto 5% auto;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
    .ai_message_title_sub {
      text-align: center;
      color: $color-white;
      font-weight: bold;
      line-height: 1.615;
      @include fontsize(26);
      @include pc() {
        margin-bottom: 40px;
      }
      @include sp() {
        //@include fontsizeVw(42);
        @include fontsize(14);
        margin-bottom: 7%;
      }
    }
    .ai_message_content {
      display: flex;
      justify-content: space-between;
      @include sp() {
        flex-direction: column;
      }
    }
    .ai_message_text {
      color: $color-white;
      line-height: 1.7;
      @include fontsize(14);

      @include sp() {
        @include fontsize(12);
        margin-bottom: 8%;
      }
    }
    .ai_message_image {
      margin-left: 42px;
      @include sp() {
        margin: auto;
      }
    }

    .corner {
      pointer-events: none;
      user-select: none;
      @include pc() {
        width: 76px;
        height: 75px;
      }
      @include sp() {
        width: 55px;
        height: 55px;
      }
      &TopLeft {
        position: absolute;
        top: -15px;
        left: -15px;
      }
      &TopRight {
        position: absolute;
        top: -15px;
        right: -15px;
        transform: scaleX(-1);
      }
      &BottomLeft {
        position: absolute;
        bottom: -15px;
        left: -15px;
        transform: scaleY(-1);
      }
      &BottomRight {
        position: absolute;
        bottom: -15px;
        right: -15px;
        transform: scale(-1);
      }
    }
  }

  // relation
  .ai_relation {
    margin-top: 140px;
    padding-bottom: 140px;
    @include sp() {
      margin-top: 12%;
      padding-bottom: 8%;
    }
    .ai_relation_title {
      text-align: center;
      @include sp() {
        height: 55px;
        margin: 0 auto 5% auto;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
    .ai_relation_slogan_box {
      background: url(/asset/img/ai_robot/bg_circle.png) center center no-repeat;
      width: 721px;
      height: 706px;
      margin: 140px auto 60px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      @include sp() {
        flex-direction: column;
        width: 92%;
        padding: 4%;
        height: auto;
        margin: 0 auto 26% auto;
        background-size: 100%;
        background: rgba($color-white, 0.5);
        border: solid 2px #007697;
        box-sizing: border-box;
      }
    }
    .ai_relation_slogan_title1 {
      @include sp() {
        width: 70%;
        text-align: center;
        img {
          width: auto;
          max-width: 100%;
        }
      }
    }
    .ai_relation_slogan_title2 {
      @include sp() {
        width: 90%;
        text-align: center;
        img {
          width: auto;
          max-width: 100%;
        }
      }
    }
    .ai_relation_slogan_text {
      text-align: center;
      line-height: 1.75;
      margin-top: 12px;
      @include fontsize(16);
      @include sp() {
        @include fontsize(14);
        text-align: left;
        align-self: flex-start;
      }
    }
    .ai_relation_character {
      position: absolute;
      bottom: 4px;
      left: -56px;
      user-select: none;
      pointer-events: none;
      @include sp() {
        width: 28%;
        max-width: 150px;
        left: auto;
        top: 90%;
        right: -4%;
      }
    }
  }

  .ai_job {
    &_inner {
      margin: 0 -12px;
    }
  }

  .job {
    &_box {
      @include flexChild(50%);
      padding: 0 12px;
      margin-bottom: 24px;
      @include sp() {
        @include flexChild;
      }
    }
    height: 100%;
    border: 2px solid #fff;
    padding: 30px 24px 24px 200px;
    @include sp() {
      padding: 188px 15px 20px;
      background-position: center 24px !important;
    }
    &.job_01 {
      background: rgba(#fff, 0.85) url(/asset/img/ai_robot/job_01.png) 24px center no-repeat;
      background-size: 150px 150px;
    }
    &.job_02 {
      background: rgba(#fff, 0.85) url(/asset/img/ai_robot/job_02.png) 24px center no-repeat;
      background-size: 150px 150px;
    }
    &.job_03 {
      background: rgba(#fff, 0.85) url(/asset/img/ai_robot/job_03.png) 24px center no-repeat;
      background-size: 150px 150px;
    }
    &.job_04 {
      background: rgba(#fff, 0.85) url(/asset/img/ai_robot/job_04.png) 24px center no-repeat;
      background-size: 150px 150px;
      .job--ttl {
        margin-bottom: 0;
      }
    }
    &--ttl {
      color: $color-blue;
      font-weight: 900;
      margin-bottom: 0.5em;
      @include fontsize(26);
      letter-spacing: 0.1em;
      line-height: 1.4;
      @include sp() {
        text-align: center;
        @include fontsize(20);
      }
      > span {
        font-size: 0.65em;
        display: inline-block;
        vertical-align: text-top;
      }
    }
    &--text {
      @include fontsize(16);
      @include sp() {
        @include fontsize(13);
      }
      line-height: 1.75;
    }
  }

  .ai_certificate {
    @include pc() {
      padding-top: 168px;
    }
    @include sp() {
      padding-top: 15%;
    }
    &_inner {
      display: block;
    }
  }

  .certificate {
    &_box {
      // background: url(/asset/img/ai_robot/certificate_bg.png) top center no-repeat;
      padding: 48px 40px;
      background: rgba(#24b3d6, 0.3);
      border: 2px solid rgba(#fff, 0.6);
      margin-bottom: 24px;
      @include sp() {
        padding: 24px 20px;
      }
    }

    &.c {
      &_eng {
        h5::before {
          background: $color-course-information;
        }
      }
      &_elec {
        h5::before {
          background: linear-gradient(
            to right,
            $color-course-electronics 0%,
            $color-course-electronics 50%,
            $color-course-electric 50%,
            $color-course-electric 100%
          );
        }
      }
    }

    &--ttl {
      display: inline-block;
      margin-bottom: 24px;
      color: #fff;
      @include fontsize(26);
      margin-right: 40px;
      font-weight: 500;
      letter-spacing: 0.1em;
      padding-bottom: 4px;
      @include sp() {
        margin-right: 0;
        text-align: center;
        @include fontsize(18);
      }
      > img {
        width: auto;
        height: 40px;
        @include sp() {
          height: 32px;
        }
      }
      > span {
        display: inline-block;
        padding-left: 8px;
        padding-bottom: 4px;
        vertical-align: text-top;
      }
      &.ttl-engineer {
        background: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 70%,
          $color-course-information 70%,
          $color-course-information 100%
        );
      }
      &.ttl-electronics {
        background: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 70%,
          $color-course-electronics 70%,
          $color-course-electronics 100%
        );
      }
      &.ttl-electric {
        background: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 70%,
          $color-course-electric 70%,
          $color-course-electric 100%
        );
      }
    }
    &--text {
      color: #fff;
      li {
        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }

      h5 {
        padding-left: 1.25em;
        @include fontsize(18);
        line-height: 1.4;
        margin-bottom: 0.75em;
        position: relative;
        font-weight: 500;
        @include sp() {
          @include fontsize(15);
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto 0;
          width: 10px;
          height: 4px;
        }

        > span {
          font-size: 0.777777778em;
        }
      }

      p {
        @include fontsize(16);
        @include sp() {
          @include fontsize(13);
        }
        line-height: 1.75;
      }
    }
  }

  .ai_department {
    @include pc() {
      padding-top: 168px;
      padding-bottom: 132px;
    }
    @include sp() {
      padding-top: 15%;
      padding-bottom: 12.5%;
    }
    &_inner {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  .department {
    &_wrap {
      @include flexChild(percentage(1/3));
      padding: 0 10px;
      margin-bottom: 32px;
      @include sp() {
        @include flexChild;
      }
      // margin-left: 20px;
      // &:after {
      //   content: '';
      //   display: block;
      //   background: url(/asset/img/ai_robot/bg_department.png) bottom center no-repeat;
      //   width: 100%;
      //   height: 23px;
      //   background-size: 100%;
      // }
      // &:first-child {
      //   margin-left: 0;
      // }
      // @include sp() {
      //   background-size: 100%;
      //   width: 92%;
      //   margin: 0 auto 5% auto;
      //   //background-position: center calc(100% - 1px);
      //   &:first-child {
      //     margin-left: auto;
      //   }
      //   &:after {
      //     content: '';
      //     display: block;
      //     background: url(/asset/img/ai_robot/bg_department.png) bottom center no-repeat;
      //     width: 92vw;
      //     height: calc(92vw * 0.06666667);
      //     background-size: 100%;
      //   }
      // }
    }

    border: 2px solid rgba(#fff, 0.3);
    width: 100%;
    height: 100%;
    position: relative;
    padding: 63% 20px 24px;
    color: $color-white;
    @include sp() {
      // width: 100%;
      // padding: 14% 4% 0 4%;
    }

    &.it_engineer {
      background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_01.jpg) top center
        no-repeat;
      background-size: contain;
      .department_title {
        background: url(/asset/img/ai_robot/bg_it_engineer.png) top left no-repeat;
        background-size: contain;
      }
      .department_title_sub {
        color: $color-course-information;
      }
    }
    &.electronics {
      background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_02.jpg) top center
        no-repeat;
      background-size: contain;
      .department_title {
        background: url(/asset/img/ai_robot/bg_electronics.png) top left no-repeat;
        background-size: contain;
      }
      .department_title_sub {
        color: $color-course-electronics;
      }
    }
    &.electric {
      background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_03.jpg) top center
        no-repeat;
      background-size: contain;
      .department_title {
        background: url(/asset/img/ai_robot/bg_electric.png) top left no-repeat;
        background-size: contain;
      }
      .department_title_sub {
        color: $color-course-electric;
      }
    }
    &_title {
      @include fontsize(16);
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 30px;
      padding: 0 0.875em 0.15em;
      @include flex;
      align-items: center;
      @include sp() {
        @include fontsize(14);
      }
    }
    &_title_sub {
      text-align: center;
      @include fontsize(16);
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 1.5em;
      > span {
        display: inline-block;
      }
    }
    &_image {
      text-align: center;
      margin-bottom: 16px;
      @include sp() {
        width: 36%;
        margin: 0 auto 6% auto;
      }
    }
    &_text {
      > li {
        @include fontsize(14);
        line-height: 1.714;
        text-indent: -1em;
        padding-left: 1em;
        @include sp() {
          @include fontsize(12);
        }
      }
    }
  }

  .ai_oc {
    &_inner {
      display: block;
      background: rgba(0, 21, 51, 0.6);
      border: 2px solid rgba(#fff, 0.3);
      padding: 40px 40px;
      @include sp() {
        padding: 24px 20px 32px;
      }
    }

    .l-moreBtn {
      margin-top: 16px;

      .btn {
        font-weight: 500;
        @include pc() {
          min-width: 400px;
        }
      }
    }
  }

  .oc {
    &--ttl {
      @include fontsize(18);
      color: #fff;
      margin-bottom: 1em;
      letter-spacing: 0.1em;
      line-height: 1.4;
      @include sp() {
        @include fontsize(16);
      }
    }
    &--text {
      color: #fff;
      @include fontsize(16);
      line-height: 1.75;
      margin-bottom: 1em;
      @include sp() {
        @include fontsize(14);
      }
    }
    &__photo {
      @include flex;
      margin: 0 -8px;

      figure {
        @include flexChild(50%);
        padding: 0 8px 16px;
        @include sp() {
          @include flexChild;
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  // column
  .ai_column {
    background: #134861 url(/asset/img/ai_robot/bg_column.jpg) top center no-repeat;
    padding: 134px 0 140px 0;
    @include sp() {
      padding: 10% 0 10% 0;
    }

    .ai_column_title {
      text-align: center;
      margin-bottom: 50px;
      @include sp() {
        height: 55px;
        width: auto;
        margin-bottom: 5%;
        img {
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
