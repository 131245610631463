/* ==================================
over ride swiper css
===================================== */
.swiper-container-thumbs {
  .swiper-slide {
    // width: 360px;
    // width: 100%;
    width: 20%;
    @include sp() {
      width: 50%;
      // margin-right: 10px !important;
    }
    height: auto;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }
}

.swiper-button-wrap {
  position: absolute;
  // width: 1140px;
  width: 90%;
  margin: 0 auto;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  @include sp() {
    width: 100%;
    top: 54%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  //background-color: $color-white;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  background: $color-white url(/asset/img/common/ico_arrow_next.svg) no-repeat 56% center;
  background-size: 30%;
  @include sp() {
    width: 30px;
    height: 30px;
  }
}

.swiper-button-next {
  right: -20px;
  @include sp() {
    right: 10px;
  }
}
.swiper-button-prev {
  left: -20px;
  transform: rotate(180deg);
  @include sp() {
    left: 10px;
  }
}

.swiper-container-main {
  margin-bottom: 50px;
  // pointer-events: none;
  @include sp() {
    margin-bottom: 5%;
  }
}
