#news {
  .l-tagLinkList {
    margin-bottom: 65px;
    @include sp() {
      margin-bottom: 6%;
    }
  }
  .tagLinkList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.tag-more {
      display: none;
      margin-top: 20px;
      @include sp() {
        margin-top: 3%;
      }
    }
    &.open {
      display: flex;
    }

    > li {
      @include pc() {
        width: 235px;
        margin-top: 20px;
        &:nth-of-type(-n + 4) {
          margin-top: 0;
        }
        &:nth-of-type(4n) {
          margin-right: 0;
        }
      }
      @include sp() {
        width: 48%;
        margin-top: 3%;
        &:nth-of-type(-n + 2) {
          margin-top: 0;
        }
      }
      &.dummyTag {
        @include pc() {
          height: 0;
          margin: 0;
        }
        @include sp() {
          display: none;
        }
      }
    }
    .btn {
      width: 100%;
      height: 100%;
      @include sp() {
        padding: 3% 5%;
        @include fontsizeVw(20);
      }
    }
  }
  .btn-tag-more {
    display: inline-block;
    color: #004098;
    font-size: 1rem;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    border: none;
    transition: all .3s ease;

    &::after {
      content: '+';
      display: inline-block;
    }
    &.open {
      &::after {
        content: '-';
        display: inline-block;
      }
    }

    @include pc() {
      &:hover {
        opacity: .7;
      }
    }
  }

  .l-pagenation {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  .pagenation {
    display: flex;
    justify-content: space-between;
    @include pc() {
      width: 330px;
    }
    @include sp() {
      width: 100%;
    }

    > li {
      min-width: 40px;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-blue;
      @include pc() {
        @include fontsize(14);
      }
      &.prev-button {
        a {
          background: url(/asset/img/common/ico_arrow_prev.svg) no-repeat center center;
          background-size: 30%;
          text-indent: -99999px;
          border: solid $color-white 1px;
          @include pc() {
            &:hover {
              background: url(/asset/img/common/ico_arrow_prev_white.svg) $color-blue no-repeat
                center center;
              background-size: 30%;
              border: solid $color-blue 1px;
            }
          }
        }
      }
      &.next-button {
        a {
          background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
          background-size: 30%;
          text-indent: -99999px;
          border: solid $color-white 1px;
          @include pc() {
            &:hover {
              background: url(/asset/img/common/ico_arrow_next_white.svg) $color-blue no-repeat
                center center;
              background-size: 30%;
              border: solid $color-blue 1px;
            }
          }
        }
      }
      &.current {
        background-color: $color-blue;
        color: $color-white;
      }
      a {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid $color-blue 1px;
        color: $color-blue;
        @include pc() {
          &:hover {
            background-color: $color-blue;
            color: $color-white;
          }
        }
      }
    }
  }
}

#newsDetail {
  .l-newsDetail-title {
    .titleLabel02 {
      margin-bottom: 30px;
    }
  }

  .l-newsDetail-meta {
    display: flex;
    justify-content: space-between;
    @include pc() {
      margin-bottom: 30px;
    }
    @include sp() {
      margin-bottom: 3%;
    }
    .newsDetail-meta-category {
      @include fontsize(10);
      color: $color-blue;
      display: inline-block;
      border: solid 1px $color-blue;
      padding: 7px 11px;
      margin: 0 3px;
      @include sp() {
        @include fontsizeVw(26);
        padding: 1% 2%;
        margin-bottom: 2.5%;
      }
    }
    .newsDetail-meta-date {
      text-align: right;
    }
  }

  .newsDetail-content {
    p {
      @include pc() {
        margin-bottom: 10px;
        line-height: 1.6;
      }
    }
    h1 {
      font-weight: bold;
      font-size: 18px;
      padding: 10px 0;
    }
  }

  .newsDetail-footer {
    border-top: 1px solid $color-platinum-gray;

    @include pc() {
      padding-top: 30px;
      margin-top: 50px;
    }
    @include sp() {
      padding-top: 10%;
      margin-top: 10%;
    }

    .l-buttonLayout {
      @include pc() {
        width: 40%;
        margin: 70px auto 0 auto;
      }
      @include sp() {
        margin: 16% auto 0 auto;
      }
    }

    .pageLink {
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      > li {
        display: flex;
        align-items: center;
        @include pc() {
          @include fontsize(14);
        }
        &.prev-link {
          float: left;
          @include sp() {
            margin-bottom: 5%;
          }
          &:before {
            display: block;
            content: '';
            background: url(/asset/img/common/ico_arrow_prev.svg) no-repeat center center;
            background-size: contain;
            height: 12px;
            width: 12px;
            margin-right: 8px;
          }
        }
        &.next-link {
          float: right;
          //@include sp() {
          //  justify-content: flex-end;
          //}
          &:after {
            display: block;
            content: '';
            background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
            background-size: contain;
            height: 12px;
            width: 12px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
