@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Oswald:wght@400;500;700&display=swap");
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

html {
  overflow-x: hidden;
}

@media screen and (min-width: 769px), print {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 100%;
  }
}

body {
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', '游ゴシック', 'Yu Gothic', YuGothic, 'メイリオ', Meiryo, Verdana, sans-serif;
  position: relative;
}

@media screen and (min-width: 769px), print {
  body {
    font-size: 14px;
  }
}

body.is-fixed {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo, Verdana, sans-serif;
  }
}

@media screen and (max-width: 768px) {
  img {
    width: 100%;
    height: auto;
  }
}

a {
  color: #222222;
  text-decoration: none;
}

@media screen and (min-width: 769px), print {
  a {
    transition: all 0.3s ease;
  }
  a:hover {
    color: #004098;
    opacity: 1;
  }
}

@media screen and (min-width: 769px), print {
  button {
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

input[type='text'],
textarea {
  font-size: 1.14286rem;
  padding: 8px 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: normal;
  width: auto;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  input[type='text'],
  textarea {
    width: 100%;
  }
}

input[type='text'].wide,
textarea.wide {
  width: 600px;
}

@media screen and (max-width: 768px) {
  input[type='text'].wide,
  textarea.wide {
    width: 100%;
  }
}

input[type='text'].narrow,
textarea.narrow {
  width: 3em;
}

select.selectBox {
  font-size: 1.14286rem;
  height: 40px;
  padding: 8px 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-right: 0.5em;
  width: 200px;
  position: relative;
}

@media screen and (max-width: 768px) {
  select.selectBox {
    width: auto;
  }
}

.appeal {
  color: #9a0001;
}

/* =================================================
共通
==================================================== */
/* 脱着して使うクラス名________________ */
.df {
  display: flex;
  background-image: linear-gradient(#ddd, #333);
}

@media screen and (min-width: 769px), print {
  .pc-df {
    display: flex;
  }
}

.fwBold {
  font-weight: bold;
}

.taCenter {
  text-align: center;
}

.tIndent {
  padding-left: 1em;
  text-indent: -1em;
}

.sup {
  vertical-align: super;
  font-size: smaller;
}

.pB0 {
  padding-bottom: 0 !important;
}

/* =================================================
出し分け
==================================================== */
@media screen and (max-width: 768px) {
  .pc {
    display: none !important;
  }
}

@media screen and (min-width: 769px), print {
  .sp {
    display: none !important;
  }
}

/* =================================================
タイトル
==================================================== */
.ttl {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  color: tomato;
}

/* =================================================
背景
==================================================== */
.bg-stripe_yellow {
  background: url(/asset/img/common/bg_stripe_yellow.png) repeat;
}

.bg-stripe_blue {
  background: url(/asset/img/common/bg_stripe_blue.png) repeat;
}

.bg-stripe_light_blue {
  background: url(/asset/img/common/bg_stripe_light_blue.png) repeat;
}

.bg-stripe_pink {
  background: url(/asset/img/common/bg_stripe_pink.png) repeat;
}

.bg-stripe_green {
  background: url(/asset/img/common/bg_stripe_green.png) repeat;
}

/* =================================================
TOP-コンテンツ
==================================================== */
.content {
  text-align: center;
  padding: 50px 0;
  background-color: cornsilk;
}

.anker {
  margin-top: -100px;
  padding-top: 100px;
}

/* =================================================
テキスト
==================================================== */
.planetxt {
  font-size: 4vw;
  line-height: 1.3;
}

@media screen and (min-width: 769px), print {
  .planetxt {
    font-size: 14px;
    line-height: 1.5;
  }
}

.smallTxt {
  font-size: 12px;
  padding-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  .smallTxt {
    display: none;
  }
}

/* =================================================
アイコン系
==================================================== */
.ico-tri {
  position: relative;
}

.ico-tri::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 7px solid #fff;
  border-right: 7px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.ico-arrow {
  position: relative;
}

.ico-arrow::after {
  content: '';
  display: block;
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media screen and (min-width: 769px), print {
  .ico-arrow::after {
    width: 5px;
    height: 5px;
    border-right: 2px solid #004098;
    border-bottom: 2px solid #004098;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .ico-arrow::after {
    width: 1.2vw;
    height: 1.2vw;
    border-right: 0.5vw solid #004098;
    border-bottom: 0.5vw solid #004098;
    right: 5.3vw;
  }
}

@media screen and (max-width: 768px) {
  .ico-arrow.megaInner_listItm-engineer::after {
    border-color: #9a0001;
  }
  .ico-arrow.megaInner_listItm-electronics::after {
    border-color: #004098;
  }
  .ico-arrow.megaInner_listItm-electric::after {
    border-color: #006a2d;
  }
}

@media screen and (min-width: 769px), print {
  .ico-plus {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .ico-plus {
    width: 14vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-left: 2px solid #e5e5e5;
  }
  .ico-plus::before, .ico-plus::after {
    transform-origin: center center;
    content: '';
    display: block;
    width: 6vw;
    height: 4%;
    background: #004098;
    transition: all 0.2s ease-in-out;
  }
  .ico-plus::before {
    transform: rotate(0);
    content: '';
  }
  .ico-plus::after {
    position: absolute;
    top: 50%;
    left: calc(50% - 3vw);
    transform: rotate(90deg);
    opacity: 1;
  }
  .ico-plus.is-open::before {
    transform: rotate(-180deg);
  }
  .ico-plus.is-open::after {
    transform: rotate(180deg);
    opacity: 0;
  }
}

/* =================================================
下層ページ用
==================================================== */
a.tLink {
  text-decoration: underline;
  color: #00a1e9;
}

.l-iFrame {
  position: relative;
  width: 100%;
  padding: 60% 0 0 0;
  margin-bottom: 60px;
}

@media screen and (min-width: 769px), print {
  .l-iFrame {
    padding: 40% 0 0 0;
    margin-bottom: 60px;
  }
}

.l-iFrame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breadCrumb {
  padding: 10px 10px;
  font-size: 0.71429rem;
}

@media screen and (min-width: 769px), print {
  .breadCrumb {
    margin-bottom: 40px;
    padding: 10px 40px;
    font-size: 0.85714rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-width: 768px) {
  .breadCrumb {
    display: none;
  }
}

.breadCrumb a {
  color: #222222;
}

.breadCrumb_home {
  width: 16px;
}

.breadCrumb_list {
  display: inline-block;
  vertical-align: middle;
}

.breadCrumb_list::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid #f3e100;
  border-right: 5px solid transparent;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  margin: 0 0 3px 8px;
  vertical-align: middle;
}

.breadCrumb_list:last-child::after {
  display: none;
}

.mainVisual {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /*
  background: url('/asset/img/common/mv.jpg') no-repeat center center/cover;
  background-size: cover;
  min-height: 150px;
  @include pc {
    min-height: 200px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: url('/asset/img/common/bg_stripe_yellow.png');
    opacity: 0.6;
  }

  &.course {
    background: url('/asset/img/common/mv_course.jpg') no-repeat center center/cover;
  }

  &_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    color: $color-blue;
    letter-spacing: 5px;
    @include fontsize(21);
    line-height: 1.4;
    width: 100%;
    @include pc {
      @include fontsize(30);
    }

    span {
      display: block;
      max-width: 40%;
      margin: 0 auto 10px auto;
      color: $color-white;
      background: $color-blue;
      @include fontsize(12);
      border-radius: 30px;
      padding: 5px 20px 7px 20px;
      letter-spacing: 3px;
      @include pc {
        @include fontsize(14);
        max-width: 14%;
      }
    }
  } // &_title
  */
}

.mainVisual_img {
  display: block;
}

.mainVisual_img img {
  width: 100%;
}

.mainVisual_titleBlock {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 30px;
  color: #fff;
  background: rgba(0, 64, 152, 0.85);
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (min-width: 769px), print {
  .mainVisual_titleBlock {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-width: 386px;
  }
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock {
    justify-content: center;
    width: 90%;
    margin-top: -10%;
  }
}

.mainVisual_titleBlock .title-top,
.mainVisual_titleBlock .title-bottom {
  display: block;
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock .title-top,
  .mainVisual_titleBlock .title-bottom {
    text-align: center;
  }
}

.mainVisual_titleBlock .title-top {
  position: relative;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  .mainVisual_titleBlock .title-top {
    font-size: 2rem;
    margin-bottom: 25px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock .title-top {
    font-size: 5.33333vw;
    margin-bottom: 8%;
    padding-bottom: 10%;
  }
}

.mainVisual_titleBlock .title-top::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: inline-block;
  width: 1em;
  height: 2px;
  background: #fff;
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock .title-top::after {
    left: calc(50% - 0.5em);
  }
}

.mainVisual_titleBlock .title-bottom {
  font-weight: bold;
  line-height: 1.3em;
}

@media screen and (min-width: 769px), print {
  .mainVisual_titleBlock .title-bottom {
    font-size: 2.28571rem;
  }
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock .title-bottom {
    font-size: 6.93333vw;
  }
}

.mainVisual_titleBlock .title-sub {
  color: #fff;
}

@media screen and (min-width: 769px), print {
  .mainVisual_titleBlock .title-sub {
    font-size: 1rem;
    padding-top: 10px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .mainVisual_titleBlock .title-sub {
    font-size: 3.73333vw;
    margin-top: 4%;
    text-align: center;
  }
}

.l-contentRap {
  max-width: 1020px;
  padding: 3%;
  margin: 30px 0;
  line-height: 1.6;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
}

@media screen and (min-width: 769px), print {
  .l-contentRap {
    margin: 60px auto;
  }
}

.l-contentRap.wide {
  max-width: 1120px;
}

.l-contentRap_space {
  margin-bottom: 40px;
}

@media screen and (min-width: 769px), print {
  .l-contentRap_space {
    margin-bottom: 75px;
  }
}

.l-announceBoxRap {
  margin-top: 30px;
}

.announceBox {
  background-color: #f4f4f4;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .announceBox {
    padding: 3.5%;
  }
}

.announceBox.center {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .announceBox.center {
    flex-direction: column;
  }
}

.announceBox .fontBig {
  font-size: 1.85714rem;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.announceBox .announceText {
  padding: 0 30px;
}

@media screen and (max-width: 768px) {
  .announceBox .announceText {
    font-size: 4.8vw;
    padding: 0;
  }
}

.announceBox .tel:before {
  content: '';
  display: inline-block;
  height: 30px;
  width: 30px;
  background: url(/asset/img/common/ico_tel.png) no-repeat center center;
  background-size: cover;
  margin-right: 6px;
}

@media screen and (max-width: 768px) {
  .announceBox .tel:before {
    height: 20px;
    width: 20px;
  }
}

.l-imgMiddle {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.l-president__img {
  width: 25%;
  margin: 0 0 10px 30px;
  float: right;
}

.l-president__img img {
  width: 100%;
}

.l-stripeBox {
  padding: 10px;
  background-color: #fff;
}

.l-stripeBox .l-stripeBoxInner {
  background-color: #fff;
  padding: 60px 0;
}

@media screen and (max-width: 768px) {
  .l-stripeBox .l-stripeBoxInner {
    padding: 8% 5%;
  }
}

.l-stripeBox .l-stripeBoxInner .stripeBoxInnerText {
  text-align: center;
  font-size: 1.14286rem;
  line-height: 3;
}

@media screen and (max-width: 768px) {
  .l-stripeBox .l-stripeBoxInner .stripeBoxInnerText {
    font-size: 4vw;
    line-height: 2.5;
  }
}

.l-textRap {
  margin-top: 15px;
}

@media screen and (min-width: 769px), print {
  .l-textRap {
    margin-top: 30px;
  }
}

.l-textLead {
  margin-bottom: 20px;
}

.textLeadRight {
  text-align: right;
  padding-top: 10px;
}

.textCatch {
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  .textCatch {
    font-size: 2.14286rem;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .textCatch {
    font-size: 4.53333vw;
    margin-bottom: 4%;
  }
}

.textCatch.courseInformation {
  color: #9FC238;
}

.textCatch.courseElectronics {
  color: #2A7FC4;
}

.textCatch.courseElectric {
  color: #C83A3F;
}

@media screen and (min-width: 769px), print {
  .textCatch_secondary {
    font-size: 1.42857rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .textCatch_secondary {
    font-size: 1.28571rem;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 769px), print {
  .l-textList {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .l-textList {
    margin-bottom: 14%;
  }
}

.textList_inner {
  padding: 5px 0 0 20px;
}

.textList_item {
  padding-bottom: 20px;
}

.textList_item li {
  margin-bottom: 10px;
  padding-left: 15px;
  text-indent: -15px;
}

.textList_item li:last-child {
  margin-bottom: 0;
}

.textList_item--space0 {
  padding-bottom: 0;
}

.textList_item--space0 li {
  margin-bottom: 0;
}

.textList__category {
  padding-left: 15px;
  text-indent: -15px;
}

@media screen and (min-width: 769px), print {
  .textList_number {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .textList_number {
    margin-bottom: 10%;
  }
}

.textList_number.bottom_margin {
  margin-bottom: 50px;
}

.textList_number.border {
  border-bottom: solid 1px #e5e5e5;
}

@media screen and (min-width: 769px), print {
  .textList_number.border {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  .textList_number.border {
    padding-bottom: 10%;
  }
}

.textList_number dt {
  width: 40px;
  height: 24px;
  position: relative;
  background: #004098;
  transition: all 0.2s linear;
  margin: 0 10px 0 0;
  display: inline-block;
  vertical-align: middle;
}

.textList_number dt span {
  width: 100%;
  height: 25px;
  line-height: 22px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  display: block;
  position: relative;
  z-index: 1;
}

.textList_number dt:before, .textList_number dt:after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #004098;
  transition: all 0.2s linear;
  z-index: 0;
}

.textList_number dt:before {
  transform: rotate(60deg);
}

.textList_number dt:after {
  transform: rotate(-60deg);
}

.textList_number dt.yellow {
  background: #f3e100;
}

.textList_number dt.yellow:before, .textList_number dt.yellow:after {
  background: #f3e100;
}

.textList_number dt.yellow span {
  color: #004098;
}

.textList_number dd {
  display: inline-block;
  width: 80%;
  vertical-align: top;
}

@media screen and (min-width: 769px), print {
  .textList_number dd {
    width: 94%;
  }
}

.textList_number dd.fontBig {
  font-size: 1.14286rem;
  font-weight: bold;
}

.l-buttonLayout {
  margin: 40px auto 0 auto;
}

@media screen and (min-width: 769px), print {
  .l-buttonLayout {
    width: 60%;
  }
}

.l-buttonLayout.bottom_margin {
  margin-bottom: 80px;
}

@media screen and (min-width: 769px), print {
  .l-buttonLayout--en {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  .l-buttonLayout--en li {
    width: 31%;
    margin: 5px 10px;
  }
}

@media screen and (min-width: 769px), print {
  .l-buttonLayout--enPdf {
    width: 80%;
  }
}

.l-buttonLayout .buttonLabel {
  line-height: 1.6;
}

button.buttonLabel {
  width: 100%;
  font-size: 1rem;
}

.buttonLabel {
  background: #004098;
  color: #fff;
  border: 2px solid #004098;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  padding: 15px 30px;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 15px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 100px;
}

.buttonLabel::after {
  content: '';
  position: absolute;
  right: 20px;
  width: 5px;
  height: 5px;
  top: 44%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
}

@media screen and (min-width: 769px), print {
  .buttonLabel::after {
    width: 10px;
    height: 10px;
    top: 27px;
  }
}

@media screen and (min-width: 769px), print {
  .buttonLabel {
    font-size: 1.14286rem;
    padding: 20px 10px;
  }
  .buttonLabel::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 120%;
    height: 100%;
    background: #fff;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform 0.4s;
  }
  .buttonLabel:hover {
    color: #004098;
  }
  .buttonLabel:hover::after {
    border-top: 1px solid #004098;
    border-right: 1px solid #004098;
  }
  .buttonLabel:hover::before {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

.buttonLabel.-yellow {
  background: #f3e100;
  color: #004098;
  border: 2px solid #f3e100;
}

.buttonLabel.-yellow::after {
  border-top: 1px solid #004098;
  border-right: 1px solid #004098;
}

.l-titleLabel {
  margin-top: 20px;
}

.titleLabel01 {
  text-align: center;
  padding-bottom: 25px;
}

@media screen and (min-width: 769px), print {
  .titleLabel01 {
    padding-bottom: 50px;
  }
}

.titleLabel01_top {
  font-size: 1.71429rem;
  letter-spacing: 2px;
  padding: 0 5px 20px 5px;
}

@media screen and (min-width: 769px), print {
  .titleLabel01_top {
    font-size: 2.28571rem;
    padding-bottom: 50px;
    letter-spacing: 4px;
  }
}

.titleLabel01_bottom {
  display: block;
  font-size: 0.85714rem;
  margin-top: 10px;
  letter-spacing: 3px;
  color: #004098;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  .titleLabel01_bottom {
    font-size: 1rem;
  }
}

.titleLabel01.courseInformation .titleLabel01_bottom {
  color: #9FC238;
}

.titleLabel01.courseElectronics .titleLabel01_bottom {
  color: #2A7FC4;
}

.titleLabel01.courseElectric .titleLabel01_bottom {
  color: #C83A3F;
}

.titleLabel02 {
  font-size: 1.42857rem;
  letter-spacing: 2px;
  position: relative;
  font-weight: normal;
  margin-bottom: 30px;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  .titleLabel02 {
    font-size: 1.71429rem;
    margin-bottom: 60px;
  }
}

.titleLabel02::before {
  content: '';
  width: 16%;
  height: 3px;
  background: #004098;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
}

.titleLabel02::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #f4f4f4;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 0;
}

.titleLabel02.courseInformation::before {
  background-color: #9FC238;
}

.titleLabel02.courseElectronics::before {
  background-color: #2A7FC4;
}

.titleLabel02.courseElectric::before {
  background-color: #C83A3F;
}

.titleLabel03 {
  font-size: 1.42857rem;
  font-weight: normal;
  padding-bottom: 20px;
  text-align: center;
}

@media screen and (min-width: 769px), print {
  .titleLabel03 {
    font-size: 1.85714rem;
    padding-bottom: 40px;
  }
}

.titleLabel03 span {
  display: block;
  padding: 10px 20px;
}

.titleLabel03.courseInformation span {
  border-color: #9FC238;
}

.titleLabel03.courseElectronics span {
  border-color: #2A7FC4;
}

.titleLabel03.courseElectric span {
  border-color: #C83A3F;
}

.titleLabel04 {
  font-size: 1.28571rem;
  font-weight: bold;
  margin-bottom: 16px;
}

.l-tableLayout {
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

@media screen and (min-width: 769px), print {
  .l-tableLayout {
    white-space: normal;
  }
}

.l-tableLayout::-webkit-scrollbar {
  height: 5px;
}

.l-tableLayout::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.l-tableLayout::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.tableBox {
  width: 100%;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.5;
  box-sizing: border-box;
}

@media screen and (min-width: 769px), print {
  .tableBox {
    table-layout: fixed;
  }
}

.tableBox--auto {
  table-layout: auto;
}

.tableBox th,
.tableBox td {
  border-left: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
}

@media screen and (min-width: 769px), print {
  .tableBox th,
  .tableBox td {
    padding: 20px;
  }
}

.tableBox th.twoColumn,
.tableBox td.twoColumn {
  display: block;
}

@media screen and (min-width: 769px), print {
  .tableBox th.twoColumn,
  .tableBox td.twoColumn {
    display: table-cell;
  }
}

.tableBox th.bgGlay,
.tableBox td.bgGlay {
  color: #222222;
  background: #eee;
}

.tableBox th.bgNavy,
.tableBox td.bgNavy {
  color: #fff;
  background: #002155;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.tableBox th.centerTxt,
.tableBox td.centerTxt {
  text-align: center;
  vertical-align: middle;
}

.tableBox th.centerItem,
.tableBox td.centerItem {
  vertical-align: middle;
}

.tableBox th {
  background: #004098;
  font-weight: normal;
  color: #fff;
  width: 100%;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

@media screen and (min-width: 769px), print {
  .tableBox th {
    width: 250px;
  }
}

@media screen and (min-width: 769px), print {
  .tableBox th.wMiddle {
    width: 25%;
  }
}

@media screen and (min-width: 769px), print {
  .tableBox th.reductionW {
    width: 450px;
  }
}

.tableBox_lead {
  color: #004098;
}

.tableBox.t-striped tbody tr:nth-child(odd) td {
  background: #F1F3F9;
}

.tableBox.t-striped tbody tr:nth-child(even) td {
  background: #fff;
}

.l-movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.l-movie .movie {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.l-cardList {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (min-width: 769px), print {
  .l-cardList {
    width: 1120px;
  }
  .l-cardList::after {
    content: '';
    display: block;
    width: 352px;
  }
}

.l-cardList .card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 769px), print {
  .l-cardList .card {
    width: 352px;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 768px) {
  .l-cardList .card {
    margin-bottom: 5%;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 50px;
  border: solid 1px #004098;
  color: #004098;
  font-size: 1rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  .btn {
    font-size: 3.73333vw;
    min-width: auto;
    min-height: auto;
    padding: 5% 15%;
  }
}

.btn::after {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
  background-size: 35%;
  position: absolute;
  top: calc(50% - 10px);
  right: 4%;
}

@media screen and (min-width: 769px), print {
  .btn::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 120%;
    height: 100%;
    background: #004098;
    transform-origin: right top;
    transform: scale(0, 1);
    transition: transform 0.3s;
  }
  .btn.selected, .btn:hover {
    color: #fff;
  }
  .btn.selected::after, .btn:hover::after {
    background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
    background-size: 35%;
  }
  .btn.selected::before, .btn:hover::before {
    transform-origin: left top;
    transform: scale(1, 1);
  }
}

.btn.btnRound {
  border-radius: 25px;
}

.btn.-blue {
  border: solid 1px #004098;
  color: #fff;
  background-color: #004098;
}

.btn.-blue::after {
  background-image: url(/asset/img/common/ico_arrow_next_white.svg);
}

@media screen and (min-width: 769px), print {
  .btn.-blue::before {
    background: #fff;
  }
  .btn.-blue.selected, .btn.-blue:hover {
    color: #004098;
  }
  .btn.-blue.selected::after, .btn.-blue:hover::after {
    background-image: url(/asset/img/common/ico_arrow_next.svg);
  }
}

.l-moreBtn {
  display: flex;
  justify-content: center;
  margin-top: 44px;
}

.l-moreBtn.left {
  justify-content: flex-start;
}

.l-moreBtn.right {
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .l-moreBtn {
    margin-top: 6%;
  }
}

/* ==================================
レイアウト
===================================== */
.l-full {
  width: 100%;
  margin: 0 auto;
}

.l-spinner {
  width: 95%;
  margin: 0 auto 10vw;
}

@media screen and (min-width: 769px), print {
  .l-spinner {
    width: 100%;
    margin: 0;
  }
}

.l-wrap, .l-wrapInner {
  max-width: 100%;
  margin: 0 auto 50px;
}

@media screen and (min-width: 769px), print {
  .l-wrap, .l-wrapInner {
    max-width: 1080px;
  }
}

.l-wrapInner {
  margin: 0 auto;
}

.l-wrapColor {
  padding: 50px 0 50px;
}

@media screen and (min-width: 769px), print {
  .l-wrapColor {
    max-width: 1080px;
  }
}

.contentbox {
  border: 1px solid;
  margin-top: 40px;
}

.news-items {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
}

@media screen and (min-width: 769px), print {
  .news-items {
    justify-content: space-between;
  }
  .news-items::after {
    content: '';
    display: block;
    width: 31.4%;
  }
}

@media screen and (max-width: 768px) {
  .news-items {
    flex-direction: column;
  }
}

.news-items .news-item {
  width: 31.4%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 769px), print {
  .news-items .news-item {
    margin-top: 35px;
  }
  .news-items .news-item:nth-of-type(-n + 3) {
    margin-top: 0;
  }
  .news-items .news-item:hover .news-item-thum img {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 768px) {
  .news-items .news-item {
    width: 100%;
    margin-bottom: 3%;
  }
}

.news-items .news-item a {
  display: block;
  height: 100%;
}

.news-items .news-item .news-item-thum {
  overflow: hidden;
  height: 164px;
}

.news-items .news-item .news-item-thum img {
  transition: transform 0.3s;
  width: 100%;
}

.news-items .news-item .news-item-body {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .news-items .news-item .news-item-body {
    padding: 4% 3%;
  }
}

.news-items .news-item .news-item-category {
  font-size: 0.71429rem;
  color: #004098;
  display: inline-block;
  border: solid 1px #004098;
  padding: 3px 11px;
  margin-bottom: 5px;
  margin-right: 3px;
  border-radius: 50px;
}

.news-items .news-item .news-item-category:last-child {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .news-items .news-item .news-item-category {
    font-size: 3.46667vw;
    padding: 0.5% 2%;
  }
}

.news-items .news-item .news-item-category::before {
  content: '#';
}

.news-items .news-item .news-item-date {
  font-size: 0.85714rem;
  color: #999;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  .news-items .news-item .news-item-date {
    font-size: 3.46667vw;
    margin-bottom: 2.5%;
  }
}

.news-items .news-item .news-item-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 16px;
  line-height: 1.5714;
}

@media screen and (max-width: 768px) {
  .news-items .news-item .news-item-title {
    font-size: 3.73333vw;
    margin-bottom: 2.5%;
  }
}

.news-items .news-item .news-item-text {
  line-height: 1.5714;
  position: relative;
  height: calc(2em * 1.5714);
  margin-bottom: 16px;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .news-items .news-item .news-item-text {
    font-size: 3.73333vw;
    margin-bottom: 2.5%;
  }
}

.l-wrapError {
  padding: 50px 20px;
  text-align: center;
  line-height: 1.5;
}

@media screen and (min-width: 769px), print {
  .l-wrapError {
    max-width: 1080px;
    margin: 0 auto;
    font-size: 16px;
    padding: 100px 20px;
  }
}

.l-wrapError_title {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  .l-wrapError_title {
    font-size: 28px;
  }
}

/* PCのみに効かせるやつ___________________________ */
/* ____________________________________________ */
/* SPのみに効かせるやつ___________________________ */
/* ____________________________________________ */
/* ==================================
HEADER
===================================== */
.header {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(102, 102, 102, 0.1);
  position: relative;
  z-index: 10;
  background: #fff;
}

@media screen and (min-width: 769px), print {
  .header {
    margin-top: 30px;
    padding: 0 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
  }
}

@media print {
  .header {
    min-width: auto !important;
    position: relative !important;
    top: 0 !important;
    left: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    padding: 4vw;
    position: fixed;
    top: 0;
    left: 0;
    /*
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 5px;
      box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: -5px;
      left: 0;
      border: none;
      z-index: 5;
    }
    */
  }
}

@media screen and (max-width: 768px) {
  .headerLogo {
    width: 47%;
    max-width: 284px;
  }
}

@media screen and (min-width: 769px), print {
  .headerLogo img {
    width: 235px;
  }
}

@media screen and (min-width: 769px), print {
  .headerBox-logo {
    width: 228px;
  }
}

@media screen and (min-width: 769px), print {
  .headerBox-nav {
    width: 980px;
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .headerBox-nav {
    display: none;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    overflow-y: scroll;
    z-index: 1;
  }
}

@media screen and (min-width: 769px), print {
  .header.is-fixed {
    min-width: 1120px;
    position: fixed;
    top: -30px;
    left: 0;
  }
  .header.is-fixed .gnav_contListItm {
    padding: 10px 20px;
  }
}

@media print {
  .header.is-fixed .gnav_contListItm {
    padding: 20px !important;
  }
}

@media screen and (min-width: 769px), print {
  .gnav_cont {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 769px), print {
  .gnav_contList {
    padding: 18px 0;
  }
  .gnav_contList:hover {
    cursor: pointer;
  }
  .gnav_contList:first-child .gnav_contListItm::before {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .gnav_contListItm {
    padding: 20px 20px;
    display: block;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.8px;
    position: relative;
  }
  .gnav_contListItm::before {
    content: '';
    display: none;
    width: 1px;
    height: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background: #e5e5e5;
  }
  .gnav_contListItm:hover::after {
    color: #666;
  }
  .gnav_contListItm a {
    color: #222222;
  }
}

@media screen and (max-width: 768px) {
  .gnav_contListItm {
    padding: 6% 4%;
    display: flex;
    font-size: 3.2vw;
    font-weight: bold;
    line-height: 0.9;
    border-bottom: 2px solid #e5e5e5;
    position: relative;
  }
  .gnav_contListItm.is-open {
    border-bottom: 2px solid #f4f4f4;
  }
}

.gnav_contListItm::after {
  content: attr(data-name);
  font-weight: normal;
}

@media screen and (min-width: 769px), print {
  .gnav_contListItm::after {
    display: block;
    margin: 10px 0 0;
    font-size: 10px;
    text-align: center;
    color: #004098;
    letter-spacing: 0.8px;
  }
}

@media screen and (max-width: 768px) {
  .gnav_contListItm::after {
    margin-left: 3%;
    display: inline-block;
    vertical-align: middle;
    font-size: 2.4vw;
    color: #004098;
    line-height: 1.22;
  }
}

.otherNav {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  .otherNav {
    height: 30px;
    background: #004098;
    position: absolute;
    top: -30px;
    left: 0;
  }
}

.otherNav_cont {
  width: 100%;
  display: flex;
}

@media screen and (min-width: 769px), print {
  .otherNav_cont {
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .otherNav_cont {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  .otherNav_contList {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .otherNav_contList {
    width: 50%;
  }
  .otherNav_contList:nth-child(2n) .otherNav_contListItm {
    border-left: 2px solid #fff;
  }
  .otherNav_contList#navSearchBtn {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .otherNav_contListItm {
    padding: 0 16px;
    font-size: 12px;
    line-height: 2;
    color: #fff;
  }
  .otherNav_contListItm::before {
    left: 4px;
  }
  .otherNav_contListItm:hover {
    color: #fff;
    opacity: 0.7;
  }
  .otherNav_contListItm.searchBtn {
    background-color: #f3e100;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  .otherNav_contListItm.searchBtn img {
    width: 16px;
    height: 16px;
  }
  .otherNav_contListItm.searchBtn:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .otherNav_contListItm {
    padding: 8% 0;
    text-align: center;
    background: #f4f4f4;
    border-bottom: 2px solid #fff;
    font-size: 2.9vw;
    display: block;
  }
  .otherNav_contListItm::before {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .fixNav {
    position: fixed;
    top: 22%;
    right: 0;
    z-index: 9999;
  }
}

@media screen and (min-width: 769px), print {
  .fixNav_contList {
    width: 76px;
  }
}

.fixNav_contListItm, .fixNav_contListItm-doc, .fixNav_contListItm-oc, .fixNav_contListItm-ai {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 769px), print {
  .fixNav_contListItm, .fixNav_contListItm-doc, .fixNav_contListItm-oc, .fixNav_contListItm-ai {
    padding: 18px 30px;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    background: #fff;
    font-size: 16px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .fixNav_contListItm, .fixNav_contListItm-doc, .fixNav_contListItm-oc, .fixNav_contListItm-ai {
    padding: 6% 0;
    text-align: center;
    font-size: 4.26667vw;
    display: block;
  }
}

.fixNav_contListItm-doc {
  color: #fff;
  background: #004098;
}

@media screen and (min-width: 769px), print {
  .fixNav_contListItm-doc {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .fixNav_contListItm-doc:hover {
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .fixNav_contListItm-doc {
    border-bottom: 2px solid #fff;
  }
}

.fixNav_contListItm-oc {
  color: #004098;
  background: #f3e100;
}

@media screen and (min-width: 769px), print {
  .fixNav_contListItm-oc {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .fixNav_contListItm-oc {
    border-bottom: 2px solid #fff;
  }
}

.fixNav_contListItm-ai {
  color: #fff;
  background-image: linear-gradient(120deg, #0E719F, #08547E);
  position: relative;
}

@media screen and (min-width: 769px), print {
  .fixNav_contListItm-ai {
    min-height: 118px;
    margin-top: 10px;
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.fixNav_contListItm-ai:hover {
  color: #fff;
}

.fixNav_contListItm .horizontal, .fixNav_contListItm-doc .horizontal, .fixNav_contListItm-oc .horizontal, .fixNav_contListItm-ai .horizontal {
  writing-mode: horizontal-tb;
}

.mega {
  width: 100%;
  display: none;
}

@media screen and (min-width: 769px), print {
  .mega {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
  }
  .mega::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -5px;
    left: 0;
    border: none;
    z-index: 1;
  }
}

@media screen and (min-width: 769px), print {
  .megaBox {
    display: flex;
  }
}

@media screen and (min-width: 769px), print {
  .megaBox-heading {
    width: 380px;
    padding: 40px 0 40px 160px;
    box-sizing: border-box;
    background: #004098;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .megaBox-heading {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .megaBox-link {
    width: calc(100% - 380px);
    padding: 28px 20px 22px;
    box-sizing: border-box;
    background: #fff;
  }
}

@media screen and (min-width: 769px), print {
  .megaBox_name {
    font-size: 18px;
  }
}

.megaBox_name::after {
  content: attr(data-name);
  display: block;
}

@media screen and (min-width: 769px), print {
  .megaBox_name::after {
    padding: 12px 0 0;
    font-size: 14px;
    color: #fff;
  }
}

.megaBox_cat {
  border-bottom: 1px solid #004098;
}

@media screen and (min-width: 769px), print {
  .megaBox_cat {
    width: 880px;
    margin: 0 0 12px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .megaBox_cat {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .megaInner {
    width: 880px;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  .megaInner_list, .megaInner_list-electronics, .megaInner_list-electric {
    width: 190px;
    margin: 0 40px 0 0;
  }
  .megaInner_list:nth-child(4n), .megaInner_list-electronics:nth-child(4n), .megaInner_list-electric:nth-child(4n) {
    margin: 0;
  }
  .megaInner_list:nth-child(4n + 1), .megaInner_list-electronics:nth-child(4n + 1), .megaInner_list-electric:nth-child(4n + 1) {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_list:last-child .megaInner_listItm, .megaInner_list-electronics:last-child .megaInner_listItm, .megaInner_list-electric:last-child .megaInner_listItm, .megaInner_list:last-child .megaInner_listItm-engineer, .megaInner_list-electronics:last-child .megaInner_listItm-engineer, .megaInner_list-electric:last-child .megaInner_listItm-engineer, .megaInner_list:last-child .megaInner_listItm-electronics, .megaInner_list-electronics:last-child .megaInner_listItm-electronics, .megaInner_list-electric:last-child .megaInner_listItm-electronics, .megaInner_list:last-child .megaInner_listItm-electric, .megaInner_list-electronics:last-child .megaInner_listItm-electric, .megaInner_list-electric:last-child .megaInner_listItm-electric {
    border-bottom: none;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_list-electronics:first-child {
    border-top: 2px solid #fff;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_list-electric:first-child {
    border-top: 2px solid #fff;
  }
}

.megaInner_listItm, .megaInner_listItm-engineer, .megaInner_listItm-electronics, .megaInner_listItm-electric {
  display: block;
}

@media screen and (min-width: 769px), print {
  .megaInner_listItm, .megaInner_listItm-engineer, .megaInner_listItm-electronics, .megaInner_listItm-electric {
    padding: 12px 0;
    font-size: 14px;
    border-bottom: 1px solid #e5e5e5;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_listItm, .megaInner_listItm-engineer, .megaInner_listItm-electronics, .megaInner_listItm-electric {
    padding: 4% 8%;
    font-size: 3.2vw;
    background: #f4f4f4;
    border-bottom: 2px solid #fff;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_listItm-engineer {
    color: #9a0001;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_listItm-electronics {
    color: #004098;
  }
}

@media screen and (max-width: 768px) {
  .megaInner_listItm-electric {
    color: #006a2d;
  }
}

.hamburger {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #004098;
}

@media screen and (min-width: 769px), print {
  .hamburger {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hamburger {
    width: 18vw;
  }
}

.hamburgerLine, .hamburgerLine-top, .hamburgerLine-mid, .hamburgerLine-bot {
  width: 48%;
  height: 4%;
  margin: auto;
  right: 0;
  left: 0;
  position: absolute;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.hamburgerLine-top {
  top: -26%;
  bottom: 0;
}

.hamburgerLine-mid {
  top: 0;
  bottom: 0;
  opacity: 1;
}

.hamburgerLine-bot {
  top: 26%;
  bottom: 0;
}

.hamburger.is-open .hamburgerLine-top {
  transform: rotate(225deg);
  top: 0;
}

.hamburger.is-open .hamburgerLine-mid {
  opacity: 0;
}

.hamburger.is-open .hamburgerLine-bot {
  transform: rotate(315deg);
  top: 0;
}

.megaBgCover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 4;
  display: none;
}

/* ==================================
FOOTER
===================================== */
.footer {
  width: 100%;
  background: #004098;
}

@media screen and (min-width: 769px), print {
  .footer_cont-page {
    padding: 60px 0;
  }
}

@media screen and (max-width: 768px) {
  .footer_cont-page {
    padding: 6% 0;
  }
}

@media screen and (min-width: 769px), print {
  .footer_cont-sns {
    padding: 0 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 769px), print {
  .footerMenu {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 769px), print {
  .footerMenu_list {
    width: 192px;
  }
}

@media screen and (min-width: 769px), print {
  .footerInner, .footerInner-topMargin {
    padding: 20px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .footerInner, .footerInner-topMargin {
    border-top: none;
  }
}

@media screen and (min-width: 769px), print {
  .footerInner_head {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
  }
}

.footerInner_headItm {
  color: #fff;
}

@media screen and (min-width: 769px), print {
  .footerInner_headItm {
    pointer-events: none;
  }
}

@media screen and (max-width: 768px) {
  .footerInner_headItm {
    padding: 3% 5.5%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 3.7vw;
    font-weight: bold;
    color: #fff;
  }
}

.footerInner_headItm::after {
  content: attr(data-name);
  display: none;
  color: #999;
}

@media screen and (min-width: 769px), print {
  .footerInner_headItm::after {
    padding: 12px 0 0;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .footerInner_headItm::after {
    margin: 0 0 0 3%;
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 769px), print {
  .footerInner_body {
    padding: 20px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .footerInner_body {
    display: none;
  }
}

@media screen and (min-width: 769px), print {
  .footerInner-topMargin {
    margin: 40px 0 0;
  }
}

.footerLink_head {
  color: #fff;
}

@media screen and (min-width: 769px), print {
  .footerLink_head {
    font-size: 0.85714rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 769px), print {
  .footerLink_list {
    margin-bottom: 12px;
  }
}

.footerLink_list.indent {
  display: flex;
  align-items: center;
}

.footerLink_list.indent:before {
  content: '- ';
  color: #fff;
}

.footerLink_listItm, .footerLink_listItm-it, .footerLink_listItm-electric {
  display: block;
}

@media screen and (min-width: 769px), print {
  .footerLink_listItm, .footerLink_listItm-it, .footerLink_listItm-electric {
    font-size: 12px;
    color: #fff;
  }
  .footerLink_listItm:hover, .footerLink_listItm-it:hover, .footerLink_listItm-electric:hover {
    color: #fff;
    opacity: 0.7;
  }
}

.footer .footerOther {
  display: flex;
}

@media screen and (max-width: 768px) {
  .footer .footerOther {
    flex-wrap: wrap;
  }
}

.footer .footerOther--en {
  border-top: 2px solid #ccd9ea;
}

@media screen and (min-width: 769px), print {
  .footer .footerOther--en {
    border: 0;
  }
}

@media screen and (min-width: 769px), print {
  .footer .footerOther_list {
    margin: 0 30px 0 0;
  }
}

@media screen and (max-width: 768px) {
  .footer .footerOther_list {
    width: 100%;
  }
}

@media screen and (min-width: 769px), print {
  .footer .footerOther_listItm {
    font-size: 12px;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .footer .footerOther_listItm {
    padding: 2% 8%;
    font-size: 3.2vw;
    color: #fff;
    text-align: center;
    display: block;
  }
}

@media screen and (min-width: 769px), print {
  .footerSns {
    display: flex;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .footerSns {
    width: 42%;
    margin: 0 auto;
    padding: 8% 0;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (min-width: 769px), print {
  .footerSns_list {
    margin: 0 0 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .footerSns_list {
    width: 25%;
  }
}

.footerLogo {
  background: #004098;
}

@media screen and (max-width: 768px) {
  .footerLogo {
    padding: 8% 0 25%;
    border-top: 1px solid #fff;
  }
}

@media screen and (min-width: 769px), print {
  .footerLogo_inner {
    width: 1120px;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

@media screen and (max-width: 768px) {
  .footerLogo_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 769px), print {
  .footerLogo_logo {
    margin-right: 30px;
  }
}

@media screen and (max-width: 768px) {
  .footerLogo_logo {
    max-width: 341px;
    width: 50%;
  }
}

@media screen and (min-width: 769px), print {
  .footerLogo_logo img {
    width: 230px;
  }
}

.footerLogo_copy {
  color: #fff;
}

@media screen and (min-width: 769px), print {
  .footerLogo_copy {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .footerLogo_copy {
    padding: 8% 0 0;
    font-size: 2.1vw;
  }
}

@media screen and (min-width: 769px), print {
  .footerFixNav {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .footerFixNav {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
  .footerFixNav .footerFixNav_cont {
    display: flex;
    justify-content: space-between;
  }
  .footerFixNav .footerFixNav_cont .footerFixNav_contList {
    width: 32%;
  }
  .footerFixNav .footerFixNav_cont .footerFixNav_contList:last-child {
    width: 68%;
  }
  .footerFixNav .footerFixNav_cont .footerFixNav_contList a {
    display: block;
    padding: 1.8em 1em;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.segment img {
  width: auto;
}

/* ==================================
over ride swiper css
===================================== */
.swiper-container-thumbs .swiper-slide {
  width: 20%;
  height: auto;
  transition: opacity 0.3s;
}

@media screen and (max-width: 768px) {
  .swiper-container-thumbs .swiper-slide {
    width: 50%;
  }
}

.swiper-container-thumbs .swiper-slide:hover {
  opacity: 0.6;
}

.swiper-button-wrap {
  position: absolute;
  width: 90%;
  margin: 0 auto;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .swiper-button-wrap {
    width: 100%;
    top: 54%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  border-radius: 50%;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  background: #fff url(/asset/img/common/ico_arrow_next.svg) no-repeat 56% center;
  background-size: 30%;
}

@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
  }
}

.swiper-button-next {
  right: -20px;
}

@media screen and (max-width: 768px) {
  .swiper-button-next {
    right: 10px;
  }
}

.swiper-button-prev {
  left: -20px;
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .swiper-button-prev {
    left: 10px;
  }
}

.swiper-container-main {
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .swiper-container-main {
    margin-bottom: 5%;
  }
}

.searchBox {
  background-color: #f3e100;
  padding: 10px;
  /* 検索ボタンの背景をボタンのように見せかける */
}

@media screen and (min-width: 769px), print {
  .searchBox {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .searchBox {
    display: block;
    position: relative;
    top: auto;
    right: auto;
    background-color: #F5AC3C;
  }
}

.searchBox .gsc-control-cse {
  border: none !important;
  background: none !important;
  padding: 0 !important;
}

.searchBox .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsc-input {
  width: 290px !important;
  padding-right: 0 !important;
  padding-left: 6px !important;
  height: 30px !important;
  margin: 0 !important;
  background-position-x: 4px !important;
  font-size: 1rem;
  color: #222222;
}

.searchBox .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsc-input::placeholder {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .searchBox .gsc-search-box-tools .gsc-search-box .gsc-input {
    width: 84% !important;
    height: auto !important;
    font-size: 1rem;
  }
  .searchBox .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsc-input {
    width: 100% !important;
    padding: 4% !important;
    background-position-x: 4% !important;
  }
  .searchBox .gsc-search-box-tools .gsc-search-box .gsc-input .gsc-input-box .gsc-input::placeholder {
    font-size: 3.46667vw;
  }
}

.searchBox .gsc-search-box {
  margin: 0;
}

.searchBox .gsc-search-box .gsc-search-button {
  position: relative;
  background-color: #004098;
  cursor: pointer;
  width: auto;
  border: none;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .searchBox .gsc-search-box .gsc-search-button {
    width: 18%;
  }
}

.searchBox .gsc-search-box .gsc-search-button-v2 {
  margin: 0;
  padding: 0;
  width: 50px !important;
  height: 32px;
}

@media screen and (max-width: 768px) {
  .searchBox .gsc-search-box .gsc-search-button-v2 {
    width: 100% !important;
    height: 30px;
  }
}

.searchBox .gsc-search-box .gsc-search-button-v2:before {
  content: '検索' !important;
  color: #fff;
  font-size: 0.71429rem;
}

.searchBox .gsc-search-box .gsc-search-button-v2 > svg {
  display: none;
}

.searchBox table.gsc-search-box td.gsib_a {
  padding: 0 !important;
}

.searchBox table.gsc-search-box td.gsib_b .gsst_b {
  width: 28px;
}

#top .l-mv img {
  width: 100%;
}

#top .l-mv .mv {
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

#top .l-mv .mv .textImg {
  position: absolute;
  left: 0;
  width: 100%;
}

#top .l-mv .mv .textImg.bg {
  z-index: 1;
}

#top .l-mv .mv .textImg.title {
  z-index: 2;
}

#top .l-mv .mv .textImg.titleSub {
  z-index: 3;
}

#top .l-mv .mv .textImg.aiLogo {
  z-index: 2;
}

@media screen and (min-width: 769px), print {
  #top .l-mv .mv .textImg {
    top: 0;
  }
}

@media screen and (max-width: 768px) {
  #top .l-mv .mv .textImg {
    text-align: center;
  }
  #top .l-mv .mv .textImg.title {
    bottom: 14.2%;
  }
  #top .l-mv .mv .textImg.title img {
    width: 90%;
  }
  #top .l-mv .mv .textImg.titleSub {
    bottom: 6%;
  }
  #top .l-mv .mv .textImg.titleSub img {
    width: 40%;
  }
}

#top .l-slider {
  padding-bottom: 50px;
}

@media screen and (max-width: 768px) {
  #top .l-slider {
    padding-bottom: 5%;
  }
}

#top .l-content {
  overflow-x: hidden;
}

@media screen and (max-width: 768px) {
  #top .l-content {
    overflow-x: hidden;
  }
}

#top .l-concept {
  max-width: 1440px;
  min-width: 1300px;
  height: 892px;
  position: relative;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  #top .l-concept {
    width: auto;
    height: auto;
    max-width: none;
    min-width: auto;
  }
}

#top .l-conceptInner {
  width: 940px;
  height: 100%;
  margin: 0 auto;
  padding-top: 80px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner {
    width: auto;
    padding: 14% 2.67% 0 2.67%;
  }
}

#top .l-conceptInner .conceptTitleBox {
  text-align: center;
  display: flex;
  justify-content: center;
}

#top .l-conceptInner .conceptTitleBox img {
  width: auto;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitleBox img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitleBoxInner {
    margin: 0 3%;
  }
}

#top .l-conceptInner .conceptTitle-image-left {
  align-self: flex-end;
  margin-right: 57px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitle-image-left {
    margin-right: 0;
    margin-left: 3%;
  }
}

#top .l-conceptInner .conceptTitle-image-right {
  align-self: flex-end;
  margin-left: 52px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitle-image-right {
    margin-left: 0;
  }
}

#top .l-conceptInner .conceptTitleSub {
  display: block;
  margin: 0 auto 30px auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitleSub {
    margin-bottom: 8%;
    width: 64%;
  }
}

#top .l-conceptInner .conceptTitleMain {
  display: block;
  margin: 0 auto 40px auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #top .l-conceptInner .conceptTitleMain {
    margin-bottom: 10%;
  }
}

#top .l-conceptBg {
  position: absolute;
  pointer-events: none;
  height: 100%;
  top: 0;
  width: 100%;
}

#top .l-conceptBg .conceptBg_inner {
  position: relative;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #top .l-conceptBg .conceptBg_inner {
    overflow: hidden;
  }
}

#top .l-conceptBg .conceptBg_img1 {
  position: absolute;
  top: 0;
  left: 36px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptBg .conceptBg_img1 {
    width: 12%;
    left: -2%;
    top: -4%;
  }
}

#top .l-conceptBg .conceptBg_img2 {
  position: absolute;
  right: -105px;
  top: -20px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptBg .conceptBg_img2 {
    width: 20%;
    right: -4.5%;
    top: -6%;
  }
}

#top .l-conceptBg .conceptBg_img3 {
  position: absolute;
  bottom: -74px;
  left: 25px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptBg .conceptBg_img3 {
    width: 23%;
    bottom: -5%;
    left: -6%;
  }
}

#top .l-conceptBg .conceptBg_img4 {
  position: absolute;
  bottom: -94px;
  right: -22px;
}

@media screen and (max-width: 768px) {
  #top .l-conceptBg .conceptBg_img4 {
    width: 32%;
    bottom: -10%;
    right: -6%;
  }
}

#top .l-reason {
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  #top .l-reason {
    padding: calc(10% + 40px) 0 16% 0;
  }
}

#top .l-reason .titleLabel01_top span {
  color: #004098;
  font-family: "Oswald", sans-serif;
  font-size: 4.28571rem;
}

#top .l-reasonContent {
  position: relative;
  width: 1200px;
  margin: 0 auto 62px auto;
  overflow: hidden;
}

#top .l-reasonContent:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent {
    display: flex;
    justify-content: space-between;
    width: auto;
    flex-direction: column-reverse;
    padding: 0 3%;
    margin: 0 auto 10% auto;
  }
}

#top .l-reasonContent .l-reasonText {
  position: relative;
  width: 600px;
  background: #fff;
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (min-width: 769px), print {
  #top .l-reasonContent .l-reasonText {
    position: absolute;
    top: 0;
    left: 0;
    padding: 50px 40px;
  }
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .l-reasonText {
    width: 94%;
    margin: -8% auto 0;
    padding: 4%;
  }
}

#top .l-reasonContent .l-reasonImage {
  width: 680px;
}

@media screen and (min-width: 769px), print {
  #top .l-reasonContent .l-reasonImage {
    float: right;
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .l-reasonImage {
    width: auto;
    margin: 0 -3%;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-reasonContent:nth-of-type(odd) .l-reasonText {
    left: auto;
    right: 0;
  }
  #top .l-reasonContent:nth-of-type(odd) .l-reasonImage {
    float: left;
  }
}

#top .l-reasonContent .l-reasonTitleSub {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .l-reasonTitleSub {
    flex-direction: column;
    margin: 4% 0;
  }
}

#top .l-reasonContent .l-reasonTitleSub .reasonTitleSub_icon {
  position: relative;
  font-size: 5.71429rem;
  margin-right: 30px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: #004098;
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .l-reasonTitleSub .reasonTitleSub_icon {
    margin: 0 auto 4%;
    font-size: 12.8vw;
  }
}

#top .l-reasonContent .l-reasonTitleSub .reasonTitleSub_icon::after {
  content: '';
  position: absolute;
  bottom: -0.1em;
  left: 10%;
  display: inline-block;
  width: 80%;
  height: 1px;
  background: #004098;
}

#top .l-reasonContent .l-reasonTitleSub .reasonTitleSub_text {
  font-size: 2.28571rem;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .l-reasonTitleSub .reasonTitleSub_text {
    font-size: 6.4vw;
  }
}

#top .l-reasonContent .reasonText {
  font-size: 1.14286rem;
  line-height: 1.57;
}

@media screen and (max-width: 768px) {
  #top .l-reasonContent .reasonText {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-reasonContent .l-moreBtn {
    justify-content: flex-end;
  }
}

#top .l-op {
  position: relative;
  padding: 80px 0;
  background: #F2F7FA;
}

@media screen and (max-width: 768px) {
  #top .l-op {
    padding: calc(10% + 40px) 3% 16%;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-op::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: block;
    width: 25%;
    height: 100%;
    background: #fff;
    z-index: -1;
  }
}

#top .l-opContent {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1120px;
  margin: 0 auto 62px auto;
}

#top .l-opContent:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  #top .l-opContent {
    flex-direction: column-reverse;
    width: auto;
    margin: 0 auto 10% auto;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-opContent:nth-of-type(even) {
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-opText {
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

@media screen and (min-width: 769px), print {
  #top .l-opImage {
    width: 48%;
  }
}

#top .l-opImage img {
  width: 100%;
  height: auto;
}

#top .l-opTitleSub {
  margin: 0 0 20px 0;
}

@media screen and (max-width: 768px) {
  #top .l-opTitleSub {
    margin: 4% 0;
  }
}

#top .l-opTitleSub .opTitleSub_text {
  font-size: 1.85714rem;
  font-weight: bold;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #top .l-opTitleSub .opTitleSub_text {
    font-size: 5.33333vw;
  }
}

#top .opText {
  font-size: 1.14286rem;
  line-height: 1.57;
}

@media screen and (max-width: 768px) {
  #top .opText {
    font-size: 3.73333vw;
  }
}

#top .l-courseWrap {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap {
    flex-direction: column;
  }
}

#top .l-courseWrap .course {
  width: 33.333333%;
  color: #fff;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course {
    width: 100%;
  }
}

#top .l-courseWrap .course .course-image img {
  width: 100%;
}

#top .l-courseWrap .course .course-body {
  padding: 40px;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course .course-body {
    padding: 7% 3%;
  }
}

#top .l-courseWrap .course .course-name {
  font-size: 1.85714rem;
  font-weight: bold;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course .course-name {
    font-size: 4.26667vw;
  }
}

#top .l-courseWrap .course .course-overview {
  font-size: 1.07143rem;
  margin-bottom: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course .course-overview {
    font-size: 3.73333vw;
  }
}

#top .l-courseWrap .course .course-tag {
  letter-spacing: -0.2em;
  margin-bottom: 16px;
}

@media screen and (min-width: 769px), print {
  #top .l-courseWrap .course .course-tag--denki {
    margin-bottom: 40px;
  }
}

#top .l-courseWrap .course .course-tag .course-tag-item {
  font-size: 0.71429rem;
  display: inline-block;
  border: solid 1px #fff;
  border-radius: 12px;
  padding: 3px 6px;
  margin: 0 4px 4px 0;
  letter-spacing: normal;
  background: #fff;
}

#top .l-courseWrap .course .course-tag .course-tag-item a {
  font-weight: bold;
  display: block;
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course .course-tag .course-tag-item {
    font-size: 2.4vw;
    padding: 1.5%;
  }
}

#top .l-courseWrap .course .course-link .course-link-item {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  font-size: 1.07143rem;
  font-weight: bold;
  padding: 15px 20px;
  margin-bottom: 6px;
}

@media screen and (min-width: 769px), print {
  #top .l-courseWrap .course .course-link .course-link-item:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 768px) {
  #top .l-courseWrap .course .course-link .course-link-item {
    font-size: 3.73333vw;
    padding: 2.5% 2.5%;
  }
}

#top .l-courseWrap .course .course-link .course-link-item:before {
  content: '';
  width: 20px;
  height: 20px;
  display: none;
  background: url(/asset/img/common/ico_arrow_next_white.svg) center center no-repeat;
  background-size: 35%;
  margin-right: 10px;
}

#top .l-courseWrap .course .course-link .course-link-item::after {
  content: '';
  position: absolute;
  top: calc(50% - 0.3em);
  right: 20px;
  display: block;
  width: 0.6em;
  height: 0.6em;
  border-top: 1px solid #999;
  border-right: 1px solid #999;
  transform: rotate(45deg);
  display: inline-block;
}

#top .l-courseWrap .course .course-link .course-link-item:last-child {
  margin-bottom: 0;
}

#top .l-courseWrap .course-informationEngineer {
  background-color: #9FC238;
}

#top .l-courseWrap .course-informationEngineer .course-tag .course-tag-item a {
  color: #9FC238;
}

#top .l-courseWrap .course-informationEngineer .course-link .course-link-item {
  color: #9FC238;
}

#top .l-courseWrap .course-informationEngineer .course-link .course-link-item::after {
  border-color: #9FC238;
}

#top .l-courseWrap .course-electronics {
  background-color: #2A7FC4;
}

#top .l-courseWrap .course-electronics .course-tag .course-tag-item a {
  color: #2A7FC4;
}

#top .l-courseWrap .course-electronics .course-link .course-link-item {
  color: #2A7FC4;
}

#top .l-courseWrap .course-electronics .course-link .course-link-item::after {
  border-color: #2A7FC4;
}

#top .l-courseWrap .course-electrical {
  background-color: #C83A3F;
}

#top .l-courseWrap .course-electrical .course-tag .course-tag-item a {
  color: #C83A3F;
}

#top .l-courseWrap .course-electrical .course-link .course-link-item {
  color: #C83A3F;
}

#top .l-courseWrap .course-electrical .course-link .course-link-item::after {
  border-color: #C83A3F;
}

#top .l-mirai {
  max-width: 1440px;
  margin: 0 auto;
}

#top .mirai {
  background: url(/asset/img/common/img_dot.png) repeat, url(/asset/img/top/pc/bg_mirai.png) no-repeat top left;
  background-size: cover;
  height: 400px;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #top .mirai {
    background: url(/asset/img/common/img_dot.png) repeat, url(/asset/img/top/sp/bg_mirai.png) no-repeat top left;
    background-size: cover;
    padding: 5px;
  }
}

#top .mirai .mirai-inner {
  border: solid 2px #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}

#top .mirai .mirai-content {
  width: 32%;
  margin-right: 8%;
}

@media screen and (max-width: 768px) {
  #top .mirai .mirai-content {
    width: 45%;
    margin-right: 4%;
  }
}

#top .mirai .mirai-content .mirai-title {
  margin-bottom: 28px;
}

@media screen and (max-width: 768px) {
  #top .mirai .mirai-content .mirai-title {
    width: 75%;
    margin-bottom: 8%;
  }
}

#top .mirai .mirai-content .mirai-text {
  color: #fff;
  font-size: 1.14286rem;
  line-height: 1.5;
  text-shadow: 1px 1px 0 #333;
}

@media screen and (max-width: 768px) {
  #top .mirai .mirai-content .mirai-text {
    font-size: 2.93333vw;
  }
}

#top .l-aiContent {
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #top .l-aiContent {
    padding: 4px;
  }
}

#top .l-aiContentInner {
  height: 100%;
  border: solid 2px #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
}

#top .ai {
  position: relative;
}

@media screen and (min-width: 769px), print {
  #top .ai {
    width: 1200px;
    margin: 0 auto 80px;
    height: 400px;
    background: url(/asset/img/top/pc/bg_ai_04.png) center center no-repeat, url(/asset/img/top/pc/bg_ai_02@2x.png) no-repeat center center/100% 100%, url(/asset/img/top/pc/bg_ai_03.png) center center no-repeat, url(/asset/img/common/bg_lattice.png) repeat center center, url(/asset/img/top/pc/bg_ai_01.png) no-repeat center center/100% 100%;
  }
}

@media screen and (max-width: 768px) {
  #top .ai {
    background: url(/asset/img/top/sp/bg_ai_01.png) no-repeat top center/100%;
    height: 240px;
  }
}

#top .ai .l-aiTitle {
  text-align: center;
}

@media screen and (max-width: 768px) {
  #top .ai .l-aiTitle {
    width: 90%;
  }
}

#top .ai .aiTitle {
  display: block;
}

#top .ai .aiTitleSub {
  display: block;
  margin-bottom: 10px;
}

#top .ai .aiText {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  #top .ai .aiText {
    font-size: 2.4vw;
  }
}

#top .ai .l-moreBtn {
  margin-top: 30px;
  width: 100%;
}

#top .info {
  background: #F2F7FA;
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  #top .info {
    padding: 8% 3%;
  }
}

#top .info .l-infoContent {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #top .info .l-infoContent {
    width: 1200px;
  }
}

@media screen and (min-width: 769px), print {
  #top .info .l-infoInner {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  #top .info .l-infoInner {
    width: 100%;
  }
  #top .info .l-infoInner#news {
    margin-bottom: 12%;
  }
}

@media screen and (max-width: 768px) {
  #top .news-items {
    max-width: 100%;
    width: 100%;
  }
}

#top .news-items::after {
  display: none;
}

@media screen and (min-width: 769px), print {
  #top .news-items .news-item {
    width: 48%;
    height: auto !important;
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  #top .news-items .news-item {
    width: 100%;
  }
  #top .news-items .news-item img {
    width: 100%;
    height: auto;
  }
}

#top .news-items + .swiper-button-wrap {
  position: relative;
  width: 50%;
  top: auto;
}

@media screen and (max-width: 768px) {
  #top .news-items + .swiper-button-wrap {
    width: 100%;
  }
}

#top .news-items + .swiper-button-wrap .swiper-button-prev,
#top .news-items + .swiper-button-wrap .swiper-button-next {
  border: 2px solid #004098;
  box-shadow: none;
}

#top .pickup {
  background: #F2F7FA;
}

#top .pickup .l-pickupInner {
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  #top .pickup .l-pickupInner {
    padding: 8% 3%;
  }
}

#top .pickup .pickup-items {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #top .pickup .pickup-items {
    width: 1200px;
  }
}

#top .pickup .pickup-item {
  width: 32%;
}

@media screen and (min-width: 769px), print {
  #top .pickup .pickup-item:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width: 768px) {
  #top .pickup .pickup-item {
    width: 100%;
    margin-bottom: 8%;
  }
  #top .pickup .pickup-item:last-child {
    margin-bottom: 0;
  }
}

#top .pickup .pickup-item-img {
  display: block;
  margin-bottom: 10px;
}

#top .pickup .pickup-item-img img {
  width: 100%;
  height: auto;
}

#top .pickup .pickup-item-text {
  font-size: 1.07143rem;
}

@media screen and (max-width: 768px) {
  #top .pickup .pickup-item-text {
    font-size: 4vw;
  }
}

#top .parents {
  padding: 80px 0 120px;
}

@media screen and (max-width: 768px) {
  #top .parents {
    padding: 8% 0 10%;
  }
}

#top .parents-links {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #top .parents-links {
    width: 900px;
  }
}

@media screen and (max-width: 768px) {
  #top .parents-links {
    flex-wrap: wrap;
    width: 70%;
  }
}

#top .parents-links .parents-link {
  box-sizing: border-box;
  text-align: center;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  #top .parents-links .parents-link {
    width: 20%;
  }
  #top .parents-links .parents-link:hover {
    color: #222222;
  }
}

@media screen and (max-width: 768px) {
  #top .parents-links .parents-link {
    width: 45%;
  }
}

#top .parents-links .parents-link-image {
  padding: 20px 0 20px 0;
}

@media screen and (max-width: 768px) {
  #top .parents-links .parents-link-image {
    padding: 0 20% 8%;
  }
}

#top .parents-links .parents-link-text {
  font-size: 1.28571rem;
  font-weight: bold;
  padding-bottom: 22px;
}

@media screen and (max-width: 768px) {
  #top .parents-links .parents-link-text {
    font-size: 4vw;
  }
}

#top .l-blogBnr {
  display: flex;
  justify-content: center;
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  #top .l-blogBnr {
    padding: 10.7% 10%;
  }
}

#top #mv .bg,
#top #mv .title,
#top #mv .titleSub,
#top #mv .aiLogo {
  opacity: 0;
  transform: translateY(10px);
}

#top #concept .conceptTitleSub,
#top #concept .conceptTitleMain,
#top #concept .conceptTitle-image-left,
#top #concept .conceptTitle-image-right,
#top #concept .l-stripeBox {
  opacity: 0;
  transform: translateY(10px);
}

#top #concept .conceptBg_img1 {
  opacity: 0;
  transform: translateY(-30px);
}

#top #concept .conceptBg_img2 {
  opacity: 0;
  transform: translateY(-30px) translateX(30px);
}

#top #concept .conceptBg_img3 {
  opacity: 0;
  transform: translateY(30px) translateX(-30px);
}

#top #concept .conceptBg_img4 {
  opacity: 0;
  transform: translateY(30px) translateX(30px);
}

#top #reason .contentTitle,
#top #reason .titleLabel01,
#top #reason .contentTitle-sub,
#top #reason .l-reasonContent {
  opacity: 0;
  transform: translateY(10px);
}

#top #ai #aiTitleSub {
  opacity: 0;
  transform: translateX(100px);
}

#top #ai #aiTitle {
  opacity: 0;
  transform: translateX(-100px);
}

#top #ai .aiText,
#top #ai .l-moreBtn {
  opacity: 0;
  transform: translateY(10px);
}

#top #op .contentTitle,
#top #op .titleLabel01,
#top #op .contentTitle-sub,
#top #op .l-opContent {
  opacity: 0;
  transform: translateY(10px);
}

#top #op .titleLabel01_sub {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 1.0rem;
}

@media screen and (max-width: 768px) {
  #top #op .titleLabel01_sub {
    font-size: 1.0rem;
  }
}

#top #op .titleLabel01_sub span {
  position: relative;
}

#top #op .titleLabel01_sub span:before, #top #op .titleLabel01_sub span:after {
  content: '';
  width: 30px;
  height: 1px;
  background: #000;
}

#top #op .titleLabel01_sub span:before {
  position: absolute;
  left: -40px;
  bottom: 15px;
  transform: rotate(45deg);
}

#top #op .titleLabel01_sub span:after {
  position: absolute;
  right: -35px;
  bottom: 15px;
  transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
  #top #op .titleLabel01_top {
    line-height: 1.3;
  }
}

#top #course {
  padding: 80px 0;
}

@media screen and (max-width: 768px) {
  #top #course {
    padding: 8% 0;
  }
}

#top #course .contentTitle,
#top #course .titleLabel01,
#top #course .contentTitle-sub,
#top #course .course {
  opacity: 0;
  transform: translateY(10px);
}

#top #mirai .mirai,
#top #mirai .mirai-title,
#top #mirai .mirai-text {
  opacity: 0;
  transform: translateY(10px);
}

#top #pickup .contentTitle,
#top #pickup .titleLabel01,
#top #pickup .contentTitle-sub,
#top #pickup .pickup-item {
  opacity: 0;
  transform: translateY(10px);
}

#top #news .titleLabel01,
#top #news .news-items,
#top #news .swiper-button-wrap,
#top #op_schedule .titleLabel01,
#top #op_schedule .news-items,
#top #op_schedule .swiper-button-wrap {
  opacity: 0;
  transform: translateY(10px);
}

#top #vr .contentTitle,
#top #vr .contentTitle-sub {
  opacity: 0;
  transform: translateY(10px);
}

#top #vr .l-movie {
  opacity: 0;
  transform: translateY(40px);
}

#top #parents .contentTitle,
#top #parents .titleLabel01,
#top #parents .contentTitle-sub,
#top #parents .parents-link {
  opacity: 0;
  transform: translateY(10px);
}

#top .l-mv_banner {
  max-width: 500px;
  width: 35%;
  margin-right: auto;
  margin-bottom: 30px;
  margin-left: auto;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #top .l-mv_banner {
    width: 70%;
    margin-bottom: 5%;
  }
}

#top .l-mv_banner_link {
  font-size: 1.14286rem;
  position: relative;
}

#top .l-mv_banner_link img {
  vertical-align: middle;
}

.pickup-news {
  position: relative;
  padding: 0 20px 48px;
}

@media screen and (max-width: 768px) {
  .pickup-news {
    padding: 0 0 5%;
  }
}

.pickup-news *,
.pickup-news *::after,
.pickup-news *::before {
  box-sizing: border-box;
}

.pickup-news_inner {
  width: 100%;
  max-width: 1080px;
  height: 48px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .pickup-news_inner {
    height: auto;
  }
}

.pickup-news_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #004098;
  font-size: 13px;
  padding-left: 25px;
  padding-right: 15px;
}

@media screen and (max-width: 768px) {
  .pickup-news_wrapper {
    padding: 15px;
  }
}

.pickup-news--ttl {
  font-weight: 900;
  color: #fff;
  flex: 0 0 9.6em;
  max-width: 9.6em;
  border-right: 1px solid #fff;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .pickup-news--ttl {
    flex: 0 0 100%;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}

.pickup-news__list {
  flex: 0 0 calc(100% - 9.6em);
  flex-basis: calc(100% - 9.6em);
  max-width: calc(100% - 9.6em);
  height: 100%;
  padding-left: 1.8em;
  margin: 0;
  position: relative;
}

@media screen and (max-width: 768px) {
  .pickup-news__list {
    flex: 0 0 100%;
    flex-basis: 100%;
    max-width: 100%;
    font-size: 12px;
    width: 100%;
    height: 4.3em;
    padding-left: 0;
  }
}

.pickup-news__list::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #fff url(/asset/img/common/ico_arrow_next.svg) no-repeat 52% center;
  background-size: 30%;
}

.pickup-news__list--item {
  line-height: 1.35;
}

.pickup-news-link {
  color: #fff;
  width: 100%;
  height: 48px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 32px;
}

@media screen and (max-width: 768px) {
  .pickup-news-link {
    height: auto;
  }
}

.pickup-news-link:hover {
  color: #fff;
  text-decoration: underline;
}

.pickup-news-date {
  font-weight: 900;
  flex: 0 0 8.8em;
  max-width: 8.8em;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 768px) {
  .pickup-news-date {
    flex: 0 0 100%;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    padding-bottom: 0.2em;
  }
}

.pickup-news-ttl {
  flex: 0 0 calc(100% - 8.8em);
  flex-basis: calc(100% - 8.8em);
  max-width: calc(100% - 8.8em);
}

@media screen and (max-width: 768px) {
  .pickup-news-ttl {
    flex: 0 0 100%;
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
}

#sitemap .sitemapBox {
  border: solid 1px #e5e5e5;
  margin-bottom: 20px;
}

#sitemap .sitemapBox h3 {
  background-color: #f2f5fa;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #sitemap .sitemapBox h3 {
    width: 100%;
    padding: 4.5%;
  }
}

#sitemap .sitemapBox .link:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6.9px;
  border-color: transparent transparent transparent #004098;
  margin-right: 6px;
  margin-bottom: 1px;
}

#sitemap .sitemap_linkList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#sitemap .sitemap_linkList::after {
  content: '';
  display: block;
  width: 33%;
}

#sitemap .sitemap_linkItem {
  box-sizing: border-box;
  width: 33%;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  #sitemap .sitemap_linkItem {
    width: 100%;
    padding: 4.5%;
  }
}

#sitemap .sitemap_linkItem dt {
  font-weight: bold;
}

#sitemap .sitemap_linkItem dd {
  padding: 20px;
}

@media screen and (max-width: 768px) {
  #sitemap .sitemap_linkItem dd {
    width: 100%;
    padding: 4.5%;
  }
}

.formTable {
  font-size: 1.14286rem;
}

.formTable .formTitle {
  background-color: #f4f4f4;
  padding: 10px;
  font-size: 1.14286rem;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  .formTable .formTitle {
    padding-left: 2em;
  }
}

.formTable .formContent {
  padding: 10px;
  margin-bottom: 30px;
}

@media screen and (min-width: 769px), print {
  .formTable .formContent {
    padding-left: 2em;
  }
}

.formTable .formError {
  padding: 10px;
  color: #9a0001;
}

@media screen and (min-width: 769px), print {
  .formTable .formError {
    padding-left: 2em;
  }
}

.formTable .formContentInner {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .formTable .formContentInner {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .formTable .formContentInner.row {
    flex-direction: row;
  }
  .formTable .formContentInner.row .formItem {
    display: flex;
  }
  .formTable .formContentInner.row .formItem:last-child .inputLabel {
    margin-right: 0;
  }
}

.formTable .formContentInner.column {
  flex-direction: column;
  align-items: flex-start;
}

.formTable .formContentInner.column .formItem {
  margin-right: 0;
  margin-bottom: 10px;
}

.formTable .formContentInner.column .formItem .inputLabel {
  width: 120px;
}

.formTable .formContentInner .formItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .formTable .formContentInner .formItem {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

.formTable .formContentInner .formItem > label {
  display: flex;
  align-items: center;
}

.formTable .formContentInner .formItem > label input[type='checkbox'],
.formTable .formContentInner .formItem > label input[type='radio'] {
  margin-right: 6px;
}

.formTable .formContentInner .formItem .inputLabel {
  margin-right: 20px;
}

.l-receiptBox {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .l-receiptBox {
    flex-direction: column;
  }
}

.receiptBox {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .receiptBox {
    width: 100%;
    margin-bottom: 5%;
  }
}

.receiptBox dt {
  border-bottom: solid 1px #222222;
  font-weight: bold;
  font-size: 1.14286rem;
  padding-bottom: 4px;
  margin-bottom: 6px;
}

.thanks_box {
  text-align: center;
}

.thanks_box__tl {
  font-size: 35px;
  letter-spacing: 3px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* checkbox */
.formTable .mwform-checkbox-field {
  width: 30%;
  padding: 0;
  margin: 0 0 8px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .formTable .mwform-checkbox-field {
    width: 100%;
  }
}

.checkboxlist b {
  margin-bottom: 0.6em;
}

.checkboxlist + .checkboxlist {
  margin-top: 1em;
}

#news .l-tagLinkList {
  margin-bottom: 65px;
}

@media screen and (max-width: 768px) {
  #news .l-tagLinkList {
    margin-bottom: 6%;
  }
}

#news .tagLinkList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#news .tagLinkList.tag-more {
  display: none;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  #news .tagLinkList.tag-more {
    margin-top: 3%;
  }
}

#news .tagLinkList.open {
  display: flex;
}

@media screen and (min-width: 769px), print {
  #news .tagLinkList > li {
    width: 235px;
    margin-top: 20px;
  }
  #news .tagLinkList > li:nth-of-type(-n + 4) {
    margin-top: 0;
  }
  #news .tagLinkList > li:nth-of-type(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 768px) {
  #news .tagLinkList > li {
    width: 48%;
    margin-top: 3%;
  }
  #news .tagLinkList > li:nth-of-type(-n + 2) {
    margin-top: 0;
  }
}

@media screen and (min-width: 769px), print {
  #news .tagLinkList > li.dummyTag {
    height: 0;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  #news .tagLinkList > li.dummyTag {
    display: none;
  }
}

#news .tagLinkList .btn {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #news .tagLinkList .btn {
    padding: 3% 5%;
    font-size: 2.66667vw;
  }
}

#news .btn-tag-more {
  display: inline-block;
  color: #004098;
  font-size: 1rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border: none;
  transition: all .3s ease;
}

#news .btn-tag-more::after {
  content: '+';
  display: inline-block;
}

#news .btn-tag-more.open::after {
  content: '-';
  display: inline-block;
}

@media screen and (min-width: 769px), print {
  #news .btn-tag-more:hover {
    opacity: .7;
  }
}

#news .l-pagenation {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

#news .pagenation {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #news .pagenation {
    width: 330px;
  }
}

@media screen and (max-width: 768px) {
  #news .pagenation {
    width: 100%;
  }
}

#news .pagenation > li {
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  #news .pagenation > li {
    font-size: 1rem;
  }
}

#news .pagenation > li.prev-button a {
  background: url(/asset/img/common/ico_arrow_prev.svg) no-repeat center center;
  background-size: 30%;
  text-indent: -99999px;
  border: solid #fff 1px;
}

@media screen and (min-width: 769px), print {
  #news .pagenation > li.prev-button a:hover {
    background: url(/asset/img/common/ico_arrow_prev_white.svg) #004098 no-repeat center center;
    background-size: 30%;
    border: solid #004098 1px;
  }
}

#news .pagenation > li.next-button a {
  background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
  background-size: 30%;
  text-indent: -99999px;
  border: solid #fff 1px;
}

@media screen and (min-width: 769px), print {
  #news .pagenation > li.next-button a:hover {
    background: url(/asset/img/common/ico_arrow_next_white.svg) #004098 no-repeat center center;
    background-size: 30%;
    border: solid #004098 1px;
  }
}

#news .pagenation > li.current {
  background-color: #004098;
  color: #fff;
}

#news .pagenation > li a {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid #004098 1px;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  #news .pagenation > li a:hover {
    background-color: #004098;
    color: #fff;
  }
}

#newsDetail .l-newsDetail-title .titleLabel02 {
  margin-bottom: 30px;
}

#newsDetail .l-newsDetail-meta {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #newsDetail .l-newsDetail-meta {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  #newsDetail .l-newsDetail-meta {
    margin-bottom: 3%;
  }
}

#newsDetail .l-newsDetail-meta .newsDetail-meta-category {
  font-size: 0.71429rem;
  color: #004098;
  display: inline-block;
  border: solid 1px #004098;
  padding: 7px 11px;
  margin: 0 3px;
}

@media screen and (max-width: 768px) {
  #newsDetail .l-newsDetail-meta .newsDetail-meta-category {
    font-size: 3.46667vw;
    padding: 1% 2%;
    margin-bottom: 2.5%;
  }
}

#newsDetail .l-newsDetail-meta .newsDetail-meta-date {
  text-align: right;
}

@media screen and (min-width: 769px), print {
  #newsDetail .newsDetail-content p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
}

#newsDetail .newsDetail-content h1 {
  font-weight: bold;
  font-size: 18px;
  padding: 10px 0;
}

#newsDetail .newsDetail-footer {
  border-top: 1px solid #e5e5e5;
}

@media screen and (min-width: 769px), print {
  #newsDetail .newsDetail-footer {
    padding-top: 30px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  #newsDetail .newsDetail-footer {
    padding-top: 10%;
    margin-top: 10%;
  }
}

@media screen and (min-width: 769px), print {
  #newsDetail .newsDetail-footer .l-buttonLayout {
    width: 40%;
    margin: 70px auto 0 auto;
  }
}

@media screen and (max-width: 768px) {
  #newsDetail .newsDetail-footer .l-buttonLayout {
    margin: 16% auto 0 auto;
  }
}

#newsDetail .newsDetail-footer .pageLink:after {
  content: '';
  display: block;
  clear: both;
}

#newsDetail .newsDetail-footer .pageLink > li {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 769px), print {
  #newsDetail .newsDetail-footer .pageLink > li {
    font-size: 1rem;
  }
}

#newsDetail .newsDetail-footer .pageLink > li.prev-link {
  float: left;
}

@media screen and (max-width: 768px) {
  #newsDetail .newsDetail-footer .pageLink > li.prev-link {
    margin-bottom: 5%;
  }
}

#newsDetail .newsDetail-footer .pageLink > li.prev-link:before {
  display: block;
  content: '';
  background: url(/asset/img/common/ico_arrow_prev.svg) no-repeat center center;
  background-size: contain;
  height: 12px;
  width: 12px;
  margin-right: 8px;
}

#newsDetail .newsDetail-footer .pageLink > li.next-link {
  float: right;
}

#newsDetail .newsDetail-footer .pageLink > li.next-link:after {
  display: block;
  content: '';
  background: url(/asset/img/common/ico_arrow_next.svg) no-repeat center center;
  background-size: contain;
  height: 12px;
  width: 12px;
  margin-left: 8px;
}

#coursePage .l-titleSecondary {
  margin-bottom: 40px;
}

#coursePage .courseBlock {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock:hover .courseImage img {
    transform: scale(1.2);
  }
}

#coursePage .courseBlock .courseImage {
  overflow: hidden;
}

#coursePage .courseBlock .courseImage img {
  transition: transform 0.3s;
  width: 100%;
}

#coursePage .courseBlock .courseName {
  text-align: center;
  font-weight: bold;
  font-size: 1.14286rem;
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock .courseName {
    margin-top: 30px;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 768px) {
  #coursePage .courseBlock .courseName {
    margin-top: 4%;
    margin-bottom: 4%;
  }
}

#coursePage .courseBlock .courseDescription {
  text-align: center;
  font-size: 1rem;
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock .courseDescription {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  #coursePage .courseBlock .courseDescription {
    margin-bottom: 5%;
  }
}

#coursePage .courseBlock.information:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.information:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0 0;
    border-color: #9FC238 transparent transparent transparent;
  }
}

@media screen and (max-width: 768px) {
  #coursePage .courseBlock.information:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #9FC238 transparent transparent transparent;
  }
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.information:hover {
    color: #9FC238;
  }
}

#coursePage .courseBlock.information .courseName {
  color: #9FC238;
}

#coursePage .courseBlock.electronics:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.electronics:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0 0;
    border-color: #2A7FC4 transparent transparent transparent;
  }
}

@media screen and (max-width: 768px) {
  #coursePage .courseBlock.electronics:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #2A7FC4 transparent transparent transparent;
  }
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.electronics:hover {
    color: #2A7FC4;
  }
}

#coursePage .courseBlock.electronics .courseName {
  color: #2A7FC4;
}

#coursePage .courseBlock.electric:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.electric:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0 0;
    border-color: #C83A3F transparent transparent transparent;
  }
}

@media screen and (max-width: 768px) {
  #coursePage .courseBlock.electric:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 20px 0 0;
    border-color: #C83A3F transparent transparent transparent;
  }
}

@media screen and (min-width: 769px), print {
  #coursePage .courseBlock.electric:hover {
    color: #C83A3F;
  }
}

#coursePage .courseBlock.electric .courseName {
  color: #C83A3F;
}

#courseDetailPage .mainVisual-large {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /*
      &.productDesign {
        background: url('/asset/img/course/it_engineer/it_engineer-06/mv_main_product_design@2x.jpg')
          no-repeat center center/cover;
        &--pcMaintenance {
          background: url('/asset/img/course/it_engineer/it_engineer-01/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--it {
          background: url('/asset/img/course/it_engineer/it_engineer-02/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--maintenance {
          background: url('/asset/img/course/it_engineer/it_engineer-03/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--web {
          background: url('/asset/img/course/it_engineer/it_engineer-04/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--graphic {
          background: url('/asset/img/course/it_engineer/it_engineer-05/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        .mainVisual_titleBlock {
          background-color: rgba($color-course-information, 0.8);
        }
      }

      &.electronics {
        &--robot {
          background: url('/asset/img/course/electronics/electronics-01/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--denshi {
          background: url('/asset/img/course/electronics/electronics-02/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--kaden {
          background: url('/asset/img/course/electronics/electronics-03/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--telecom {
          background: url('/asset/img/course/electronics/electronics-04/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--audio {
          background: url('/asset/img/course/electronics/electronics-05/mv_main@2x.jpg') no-repeat
            center center/cover;
        }

        .mainVisual_titleBlock {
          background-color: rgba($color-course-electronics, 0.8);
        }
      }
      &.electric {
        &--facility {
          background: url('/asset/img/course/electric/electric-01/mv_main@2x.jpg') no-repeat center
            center/cover;
        }

        .mainVisual_titleBlock {
          background-color: rgba($color-course-electric, 0.8);
        }
      }
    */
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .mainVisual-large {
    height: 420px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .mainVisual-large {
    height: 180px;
  }
}

#courseDetailPage .mainVisual-large .mainVisual_titleBlock {
  margin: 0 auto;
  color: #fff;
}

#courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-top,
#courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-bottom {
  text-align: center;
  display: block;
}

#courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-top {
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-top {
    font-size: 1.28571rem;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-top {
    font-size: 3.46667vw;
    margin-bottom: 4%;
  }
}

#courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-bottom {
  font-weight: bold;
  border-bottom: solid 1px #fff;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-bottom {
    font-size: 2.14286rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-bottom {
    font-size: 4.8vw;
    padding-bottom: 4%;
    margin-bottom: 4%;
  }
}

#courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-sub {
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-sub {
    font-size: 0.85714rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .mainVisual-large .mainVisual_titleBlock .title-sub {
    font-size: 3.46667vw;
  }
}

#courseDetailPage .mainVisual.productDesign .mainVisual_titleBlock {
  background: rgba(159, 194, 56, 0.85);
}

#courseDetailPage .mainVisual.electronics .mainVisual_titleBlock {
  background-color: rgba(42, 127, 196, 0.8);
}

#courseDetailPage .mainVisual.electric .mainVisual_titleBlock {
  background-color: rgba(200, 58, 63, 0.8);
}

#courseDetailPage .l-textCatch.textCatch {
  color: #222222 !important;
}

#courseDetailPage .l-textLead {
  text-align: center;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-textLead {
    text-align: left;
  }
}

#courseDetailPage .featureBlock {
  background: #F4F4F4;
  line-height: 1.6;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .featureBlock {
    padding: 80px 0;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .featureBlock {
    padding: 8% 0;
  }
}

#courseDetailPage .features {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features.column-4 .featureWrap {
    max-width: 24%;
  }
}

#courseDetailPage .features .featureWrap {
  background-repeat: repeat;
  position: relative;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features .featureWrap {
    max-width: 32%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .featureWrap {
    width: 94% !important;
    margin: 0 auto 6%;
  }
}

#courseDetailPage .features .featureWrap_image {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .featureWrap_image {
    flex-direction: column;
  }
}

#courseDetailPage .features .featureWrap_image .featureBlock_image {
  width: 47.5%;
  background-image: url(/asset/img/common/bg_stripe_pink.png);
  background-repeat: repeat;
  position: relative;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .featureWrap_image .featureBlock_image {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .featureWrap_image .featureBlock_image:not(:first-child) {
    margin-top: 16px;
  }
}

#courseDetailPage .features .featureWrap_image .featureBlock_image.courseElectronics {
  background-image: url(/asset/img/common/bg_stripe_blue.png);
}

#courseDetailPage .features .featureWrap_image .featureBlock_image.courseElectric {
  background-image: url(/asset/img/common/bg_stripe_green.png);
}

#courseDetailPage .features .featureWrap_image img {
  width: 98.5%;
  padding: 0 4% 1.5% 0;
}

#courseDetailPage .features .featureWrap_image .featureBlock_icon {
  position: absolute;
  width: 8%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .featureWrap_image .featureBlock_icon {
    width: 10%;
  }
}

#courseDetailPage .features.electronics .feature .featureSubTitle .subTitleLabel > span {
  color: #2A7FC4;
}

#courseDetailPage .features.electronics .feature .featureTitle {
  color: #2A7FC4;
}

#courseDetailPage .features.electronics .feature dd a {
  color: #2A7FC4;
  text-decoration: underline;
}

#courseDetailPage .features.electronics .feature dd a:hover {
  text-decoration: none;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features.electric .featureWrap {
    max-width: 24%;
  }
}

#courseDetailPage .features.electric .feature .featureSubTitle .subTitleLabel > span {
  color: #C83A3F;
}

#courseDetailPage .features.electric .feature .featureTitle {
  color: #C83A3F;
}

#courseDetailPage .features .feature {
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features .feature {
    width: 100%;
    padding: 48px 28px;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .feature {
    padding: 7% 5%;
    height: 100%;
  }
}

#courseDetailPage .features .feature .featureSubTitle {
  margin: 0 auto 1em;
  text-align: center;
}

#courseDetailPage .features .feature .featureSubTitle .subTitleLabel {
  display: inline-block;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features .feature .featureSubTitle .subTitleLabel {
    font-size: 1.85714rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .feature .featureSubTitle .subTitleLabel {
    font-size: 5.33333vw;
  }
}

#courseDetailPage .features .feature .featureSubTitle .subTitleLabel::before, #courseDetailPage .features .feature .featureSubTitle .subTitleLabel::after {
  content: '―';
  display: inline-block;
  margin: 0 0.5em;
}

#courseDetailPage .features .feature .featureSubTitle .subTitleLabel > span {
  color: #9FC238;
}

#courseDetailPage .features .feature .featureTitle {
  text-align: center;
  color: #9FC238;
  font-weight: bold;
  margin-bottom: 14px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features .feature .featureTitle {
    font-size: 2.28571rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .features .feature .featureTitle {
    font-size: 6.93333vw;
  }
}

#courseDetailPage .features .feature .featureTitle > span {
  display: inline-block;
}

#courseDetailPage .features .feature .iconImage {
  position: absolute;
  top: -42px;
  left: calc(50% - 36px);
  z-index: 1;
}

#courseDetailPage .features .feature .iconImage:before {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 40px;
  transform: scaleX(1.5);
  z-index: -1;
}

#courseDetailPage .features .feature .image {
  width: 98%;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .features .feature dd {
    font-size: 1.14286rem;
  }
}

#courseDetailPage .features .feature dd a {
  color: #9FC238;
  text-decoration: underline;
}

#courseDetailPage .features .feature dd a:hover {
  text-decoration: none;
}

#courseDetailPage .curriculumBlock {
  line-height: 1.6;
}

#courseDetailPage .curriculumBlock.courseElectronics .titleLabel01 .titleLabel01_bottom {
  color: #2A7FC4;
}

#courseDetailPage .curriculumBlock.courseElectronics .curriculumTable:not(:last-of-type)::after {
  border-color: #2A7FC4 #2A7FC4 transparent transparent;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock.courseElectronics .curriculumTable:not(:last-of-type)::after {
    border-color: transparent transparent #2A7FC4 #2A7FC4;
  }
}

#courseDetailPage .curriculumBlock.courseElectric .titleLabel01 .titleLabel01_bottom {
  color: #C83A3F;
}

#courseDetailPage .curriculumBlock.courseElectric .curriculumTable:not(:last-of-type)::after {
  border-color: #C83A3F #C83A3F transparent transparent;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock.courseElectric .curriculumTable:not(:last-of-type)::after {
    border-color: transparent transparent #C83A3F #C83A3F;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock {
    padding: 80px 0;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock {
    padding: 5% 3%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .titleLabel02 {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .titleLabel02 {
    margin-bottom: 45px;
  }
}

#courseDetailPage .curriculumBlock .titleLabel02::before {
  width: 28%;
}

#courseDetailPage .curriculumBlock .titleLabel02::after {
  background-color: #fff;
}

#courseDetailPage .curriculumBlock .curriculumBlock_inner {
  margin: 0 auto;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .curriculumBlock_inner {
    width: 1120px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumBlock_inner {
    width: auto;
  }
}

#courseDetailPage .curriculumBlock .l-curriculumTable {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .l-curriculumTable {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .l-curriculumTable {
    flex-direction: column;
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .l-curriculumTable .table-note {
    font-size: 0.92857rem;
    text-align: right;
  }
}

#courseDetailPage .curriculumBlock .curriculumTable {
  position: relative;
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .curriculumTable {
    width: 47%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumTable {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumTable:last-of-type {
    margin-bottom: 0;
  }
}

#courseDetailPage .curriculumBlock .curriculumTable:not(:last-of-type)::after {
  content: '';
  position: absolute;
  bottom: calc(40% - 8px);
  right: -40px;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-color: #9FC238 #9FC238 transparent transparent;
  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumTable:not(:last-of-type)::after {
    bottom: -40px;
    right: calc(50% - 8px);
    border-color: transparent transparent #9FC238 #9FC238;
    transform: rotate(-45deg);
  }
}

#courseDetailPage .curriculumBlock .curriculumTable dt {
  width: 100%;
  padding-bottom: 0.5em;
  font-weight: bold;
  border-bottom: 2px solid #F4F4F4;
  background: transparent !important;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .curriculumTable dt {
    font-size: 1.85714rem;
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumTable dt {
    font-size: 5.33333vw;
    margin-bottom: 5%;
  }
}

#courseDetailPage .curriculumBlock .curriculumTable dd p {
  margin-bottom: 20px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .curriculumBlock .curriculumTable dd p {
    font-size: 1.14286rem;
    min-height: 5.0em;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .curriculumTable dd p {
    min-height: auto;
  }
}

#courseDetailPage .curriculumBlock .curriculumTable dd p.sub_category {
  min-height: auto;
  color: #9FC238;
  margin-bottom: 10px;
}

#courseDetailPage .curriculumBlock .curriculumTable .smallTxt {
  color: #BF2221;
}

#courseDetailPage .curriculumBlock .curriculumTitle {
  font-weight: bold;
  margin-bottom: 10px;
}

#courseDetailPage .curriculumBlock .pickupBlock .pickupBlock_image {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .curriculumBlock .pickupBlock .pickupBlock_image {
    margin-bottom: 3%;
  }
}

#courseDetailPage .curriculumBlock .pickupBlock .pickupBlock_image img {
  width: 100%;
  height: auto;
}

#courseDetailPage .curriculumBlock .pickupBlock .pickupBlock_title02 {
  font-size: 1.14286rem;
  font-weight: bold;
  margin-bottom: 16px;
}

#courseDetailPage .curriculumBlock.courseInformation .curriculumTable dt {
  background-color: #9FC238;
}

#courseDetailPage .curriculumBlock.courseInformation .curriculumTitle {
  color: #9FC238;
}

#courseDetailPage .curriculumBlock.courseInformation .titleLabel02::before {
  background-color: #9FC238;
}

#courseDetailPage .curriculumBlock.courseInformation .pickupBlock_title02 {
  color: #9FC238;
}

#courseDetailPage .curriculumBlock.courseElectronics .curriculumTable dt {
  background-color: #2A7FC4;
}

#courseDetailPage .curriculumBlock.courseElectronics .curriculumTitle {
  color: #2A7FC4;
}

#courseDetailPage .curriculumBlock.courseElectronics .titleLabel02::before {
  background-color: #2A7FC4;
}

#courseDetailPage .curriculumBlock.courseElectronics .pickupBlock_title02 {
  color: #2A7FC4;
}

#courseDetailPage .curriculumBlock.courseElectric .curriculumTable dt {
  background-color: #C83A3F;
}

#courseDetailPage .curriculumBlock.courseElectric .curriculumTitle {
  color: #C83A3F;
}

#courseDetailPage .curriculumBlock.courseElectric .titleLabel02::before {
  background-color: #C83A3F;
}

#courseDetailPage .curriculumBlock.courseElectric .pickupBlock_title02 {
  color: #C83A3F;
}

#courseDetailPage .sceneWrap {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .sceneWrap {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .sceneWrap .scene_images {
    width: calc(100% - 58%);
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .sceneWrap .scene_images {
    margin-bottom: 8%;
  }
}

#courseDetailPage .sceneWrap .scene_images .scene {
  width: 100%;
  margin-bottom: 10px;
}

#courseDetailPage .sceneWrap .scene_images .scene:last-of-type {
  margin-bottom: 0;
}

#courseDetailPage .sceneWrap .scene_images .scene img {
  width: 100%;
  height: auto;
}

#courseDetailPage .sceneWrap .scene_pickup {
  position: relative;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .sceneWrap .scene_pickup {
    width: 56%;
  }
}

#courseDetailPage .sceneWrap .scene_pickup.courseElectronics h3 {
  background: linear-gradient(-45deg, transparent 10px, #2A7FC4 0);
}

#courseDetailPage .sceneWrap .scene_pickup.courseElectric h3 {
  background: linear-gradient(-45deg, transparent 10px, #C83A3F 0);
}

#courseDetailPage .sceneWrap .scene_pickup h3 {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 0.5em 2em;
  color: #fff;
  background: linear-gradient(-45deg, transparent 10px, #9FC238 0);
  font-size: 1.07143rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .sceneWrap .scene_pickup h3 {
    font-size: 4vw;
  }
}

#courseDetailPage .sceneWrap .scene_pickup h4 {
  margin-bottom: 0.5em;
  font-size: 1.07143rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .sceneWrap .scene_pickup h4 {
    font-size: 4vw;
  }
}

#courseDetailPage .voiceBlock {
  background-color: rgba(159, 194, 56, 0.15);
}

#courseDetailPage .voiceBlock.courseElectronics {
  background-color: rgba(42, 127, 196, 0.15);
}

#courseDetailPage .voiceBlock.courseElectronics .titleLabel01 .titleLabel01_bottom {
  color: #2A7FC4;
}

#courseDetailPage .voiceBlock.courseElectronics .voice_content .titleLabel02::before {
  background-color: #2A7FC4;
}

#courseDetailPage .voiceBlock.courseElectronics .voice_content .titleLabel02_sub01 {
  color: #2A7FC4;
}

#courseDetailPage .voiceBlock.courseElectric {
  background-color: rgba(200, 58, 63, 0.15);
}

#courseDetailPage .voiceBlock.courseElectric .titleLabel01 .titleLabel01_bottom {
  color: #C83A3F;
}

#courseDetailPage .voiceBlock.courseElectric .voice_content .titleLabel02::before {
  background-color: #C83A3F;
}

#courseDetailPage .voiceBlock.courseElectric .voice_content .titleLabel02_sub01 {
  color: #C83A3F;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock {
    padding: 80px 0;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock {
    padding: 10% 0;
  }
}

#courseDetailPage .voiceBlock .voiceBlock_inner {
  margin: 0 auto 40px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voiceBlock_inner {
    width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voiceBlock_inner {
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voiceBlock_inner {
    margin-bottom: 5%;
  }
}

#courseDetailPage .voiceBlock .voiceBlock_inner:last-of-type {
  margin-bottom: 0;
}

#courseDetailPage .voiceBlock .voice {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice {
    flex-direction: column-reverse;
    padding: 0 4%;
  }
}

#courseDetailPage .voiceBlock .voice_content {
  padding: 30px;
  background: #fff;
  border-radius: 6px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content {
    position: relative;
  }
  #courseDetailPage .voiceBlock .voice_content::before {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: -17px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 17.3px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content {
    padding: 8% 5%;
  }
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  letter-spacing: normal;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02 {
    align-items: flex-start;
    margin-bottom: 10%;
  }
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02::before {
  display: none;
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02_name {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #222222;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_name {
    margin: 0 16px;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_name {
    margin-bottom: 2%;
    font-size: 5.33333vw;
  }
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02_name span {
  align-self: flex-end;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_name span {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_name span {
    font-size: 4vw;
  }
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02_sub01 {
  width: 100%;
  font-weight: bold;
  color: #9FC238;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_sub01 {
    font-size: 1.07143rem;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_sub01 {
    font-size: 3.46667vw;
    margin-bottom: 2%;
  }
}

#courseDetailPage .voiceBlock .voice_content .titleLabel02_sub02 {
  color: #222222;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_sub02 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_content .titleLabel02_sub02 {
    font-size: 3.46667vw;
  }
}

#courseDetailPage .voiceBlock .voice_title {
  color: #9FC238;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_title {
    font-size: 1.14286rem;
    font-weight: bold;
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_title {
    margin-bottom: 3%;
  }
}

#courseDetailPage .voiceBlock .voice_text {
  line-height: 1.6;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_text {
    font-size: 1.14286rem;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .voiceBlock .voice_image {
    margin-right: 40px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .voiceBlock .voice_image {
    width: 50%;
    margin: 0 auto 6% auto;
  }
}

#courseDetailPage .voiceBlock .voice_image img {
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .titleLabel01 {
    padding-bottom: 50px;
  }
}

#courseDetailPage .careerBlock .titleLabel02 {
  color: #9FC238;
}

#courseDetailPage .careerBlock .titleLabel02:before {
  background: #9FC238;
}

#courseDetailPage .careerBlock.courseElectronics .titleLabel01 .titleLabel01_bottom {
  color: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectronics .titleLabel02 {
  color: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectronics .titleLabel02:before {
  background: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectronics .careerList > dd {
  background: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectric .titleLabel01 .titleLabel01_bottom {
  color: #C83A3F;
}

#courseDetailPage .careerBlock.courseElectric .titleLabel02 {
  color: #C83A3F;
}

#courseDetailPage .careerBlock.courseElectric .titleLabel02:before {
  background: #C83A3F;
}

#courseDetailPage .careerBlock.courseElectric .careerList > dd {
  background: #C83A3F;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock {
    padding-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock {
    padding-top: 10%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock .careerBlock_inner {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .careerBlock_inner {
    margin-bottom: 10%;
    padding: 0 5%;
  }
}

#courseDetailPage .careerBlock .careerList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock .careerList {
    padding: 0;
    line-height: 1.6;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .careerList {
    margin-bottom: 8%;
  }
}

#courseDetailPage .careerBlock .careerList:last-of-type {
  margin-bottom: 0;
}

#courseDetailPage .careerBlock .careerList > dt {
  width: 100%;
  padding-bottom: 0.5em;
  font-weight: bold;
  border-bottom: 2px solid #F4F4F4;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock .careerList > dt {
    font-size: 1.85714rem;
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .careerList > dt {
    font-size: 5.33333vw;
    margin-bottom: 5%;
  }
}

#courseDetailPage .careerBlock .careerList > dd {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 5px;
  padding: 0 1em;
  line-height: 2em;
  color: #fff;
  font-size: 0.71429rem;
  border-radius: 10px;
  background: #9FC238;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock .careerList > dd {
    width: auto;
  }
}

#courseDetailPage .careerBlock .careerList > dd::before {
  content: '●';
  display: none;
  font-size: 10px;
  color: #9FC238;
  margin-right: 4px;
  transform: scale(0.6);
}

#courseDetailPage .careerBlock .careerList > dd.other {
  margin-left: 18px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .careerBlock .careerList > dd.other {
    margin-left: -100px;
  }
}

#courseDetailPage .careerBlock .careerList > dd.other::before {
  display: none;
}

#courseDetailPage .careerBlock .qualification-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .qualification-wrap {
    margin-top: 50px;
    margin-left: -5%;
    margin-right: -5%;
  }
}

#courseDetailPage .careerBlock .qualification-wrap li {
  width: 31.5%;
  padding-left: 18px;
  text-indent: -18px;
  margin-bottom: 15px;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .qualification-wrap li {
    width: 100%;
    /*&:nth-of-type(odd){
            margin-right: 2%;
          }*/
  }
}

#courseDetailPage .careerBlock .qualification-wrap li:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #9FC238;
  margin-right: 8px;
}

#courseDetailPage .careerBlock .career-contents {
  margin-top: 80px;
}

#courseDetailPage .careerBlock .career-contents .career-list-wrap {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .career-contents .career-list-wrap {
    margin-top: 60px;
  }
}

#courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list {
  width: 41.5%;
  margin-bottom: 30px;
  background: rgba(159, 194, 56, 0.08);
  padding: 30px 40px;
  border-radius: 15px;
  margin-right: 2.5%;
}

#courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list:nth-of-type(even) {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list {
    width: 100%;
    padding: 30px 25px;
    margin-right: 0;
  }
}

#courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list dt {
  font-size: 1.5rem;
  color: #9FC238;
  margin-bottom: 1.0em;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list dt {
    font-size: 1.15rem;
  }
}

#courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list dd {
  font-size: 1.14286rem;
  line-height: 1.6;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .careerBlock .career-contents .career-list-wrap .career-list dd {
    font-size: 1.0rem;
  }
}

#courseDetailPage .careerBlock.courseElectronics .qualification-wrap li:before {
  background: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectronics .career-contents .career-list-wrap .career-list {
  background: #f2f7fa;
}

#courseDetailPage .careerBlock.courseElectronics .career-contents .career-list-wrap .career-list dt {
  color: #2A7FC4;
}

#courseDetailPage .careerBlock.courseElectric .qualification-wrap li:before {
  background: #C83A3F;
}

#courseDetailPage .careerBlock.courseElectric .career-contents .career-list-wrap .career-list {
  background-color: rgba(200, 58, 63, 0.08);
}

#courseDetailPage .careerBlock.courseElectric .career-contents .career-list-wrap .career-list dt {
  color: #C83A3F;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .l-stripeBox_outer {
    width: 1120px;
    margin: 0 auto;
  }
}

#courseDetailPage .l-stripeBox {
  padding: 0;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .l-stripeBox .l-stripeBoxInner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
  }
  #courseDetailPage .l-stripeBox .l-stripeBoxInner > dl {
    width: 48%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-stripeBox .l-stripeBoxInner {
    padding: 0 5%;
  }
}

#courseDetailPage .l-contentTitle {
  text-align: center;
  padding-bottom: 64px;
  padding-top: 120px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-contentTitle {
    padding-bottom: 7%;
    padding-top: 12%;
  }
}

#courseDetailPage .l-contentTitle .contentTitle {
  color: #e16969;
  font-size: 2.14286rem;
  font-weight: normal;
  position: relative;
  display: inline-block;
  padding: 0 10px;
  z-index: 0;
  margin-bottom: 23px;
  letter-spacing: 0.12em;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-contentTitle .contentTitle {
    font-size: 4.8vw;
    margin-bottom: 4%;
  }
}

#courseDetailPage .l-contentTitle .contentTitle::after {
  position: absolute;
  display: block;
  content: '';
  background-image: url(/asset/img/common/bg_stripe_yellow.png);
  background-repeat: repeat;
  height: 14px;
  width: 100%;
  bottom: -3px;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-contentTitle .contentTitle::after {
    height: 8px;
  }
}

#courseDetailPage .l-contentTitle .contentTitle-sub {
  font-weight: bold;
  letter-spacing: 0.12em;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-contentTitle .contentTitle-sub {
    font-size: 2.93333vw;
  }
}

#courseDetailPage .l-contentTitle .titleLabel01 {
  padding-bottom: 0;
}

#courseDetailPage .l-contentTitle .titleLabel01_bottom {
  color: #9FC238;
}

#courseDetailPage .l-newsInner {
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-newsInner {
    padding-bottom: 8%;
  }
}

#courseDetailPage .news-item-category {
  color: #9FC238;
  border: 1px solid #9FC238;
}

#courseDetailPage .courseElectronics .l-contentTitle .titleLabel01_bottom {
  color: #2A7FC4;
}

#courseDetailPage .courseElectric .l-contentTitle .titleLabel01_bottom {
  color: #C83A3F;
}

#courseDetailPage .courseElectronics .news-item-category {
  color: #2A7FC4;
  border: 1px solid #2A7FC4;
}

#courseDetailPage .courseElectric .news-item-category {
  color: #C83A3F;
  border: 1px solid #C83A3F;
}

#courseDetailPage .btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 200px;
  min-height: 50px;
  border: solid 1px #9FC238;
  color: #9FC238;
  font-size: 1rem;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .btn {
    font-size: 3.73333vw;
    min-width: auto;
    min-height: auto;
    padding: 3% 15%;
  }
}

#courseDetailPage .btn::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  _background: url(/asset/img/common/ico_arrow_next_pink.svg) no-repeat center center;
  _background-size: 35%;
  background: none;
  border-top: 2px solid #9FC238;
  border-right: 2px solid #9FC238;
  position: absolute;
  _top: calc(50% - 10px);
  right: 7%;
  top: 40%;
  /*transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);*/
  transform: rotate(45deg);
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .btn::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    content: '';
    width: 120%;
    height: 100%;
    background: #9FC238;
    transform-origin: right top;
    transform: skewX(-30deg) scale(0, 1);
    transition: transform 0.3s;
  }
  #courseDetailPage .btn.selected, #courseDetailPage .btn:hover {
    color: #fff;
  }
  #courseDetailPage .btn.selected::after, #courseDetailPage .btn:hover::after {
    _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
    _background-size: 35%;
    background: none;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  #courseDetailPage .btn.selected::before, #courseDetailPage .btn:hover::before {
    transform-origin: left top;
    transform: skewX(-30deg) scale(1, 1);
  }
}

#courseDetailPage .btn.btnRound {
  border-radius: 25px;
}

#courseDetailPage .l-buttonLayout {
  margin: 0 auto;
  text-align: center;
  padding: 12px 56px 0;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-buttonLayout {
    padding-top: 48px;
    padding-bottom: 32px;
  }
}

#courseDetailPage .l-buttonLayout .btn {
  width: 100%;
  max-width: 800px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .l-buttonLayout .btn {
    font-size: 1.14286rem;
  }
  #courseDetailPage .l-buttonLayout .btn.selected, #courseDetailPage .l-buttonLayout .btn:hover {
    border-color: #fff;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-buttonLayout .btn {
    max-width: 274px;
    padding: 3% 8% 4% 4.5%;
  }
}

#courseDetailPage .l-buttonLayout .btn::after {
  border-color: transparent;
  width: 26px;
  height: 26px;
  top: calc(50% - 15px);
  transform: rotate(0);
  right: 2%;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .l-buttonLayout .btn::after {
    width: 20px;
    height: 20px;
    top: calc(50% - 13px);
  }
}

#courseDetailPage .courseInformation .l-buttonLayout {
  background: #e16969;
}

#courseDetailPage .courseElectronics .l-buttonLayout {
  background: #2A7FC4;
}

#courseDetailPage .courseElectronics .btn {
  border: solid 1px #2A7FC4;
  color: #2A7FC4;
}

#courseDetailPage .courseElectronics .btn::after {
  _background: url(/asset/img/common/ico_arrow_next_blue.svg) no-repeat center center;
  background: none;
  border-top: 2px solid #2A7FC4;
  border-right: 2px solid #2A7FC4;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .courseElectronics .btn::before {
    background: #2A7FC4;
  }
  #courseDetailPage .courseElectronics .btn.selected, #courseDetailPage .courseElectronics .btn:hover {
    color: #fff;
  }
  #courseDetailPage .courseElectronics .btn.selected::after, #courseDetailPage .courseElectronics .btn:hover::after {
    _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
    background: none;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}

#courseDetailPage .courseElectric .l-buttonLayout {
  background: #C83A3F;
}

#courseDetailPage .courseElectric .btn {
  border: solid 1px #C83A3F;
  color: #C83A3F;
}

#courseDetailPage .courseElectric .btn::after {
  _background: url(/asset/img/common/ico_arrow_next_green.svg) no-repeat center center;
  background: none;
  border-top: 2px solid #C83A3F;
  border-right: 2px solid #C83A3F;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .courseElectric .btn::before {
    background: #C83A3F;
  }
  #courseDetailPage .courseElectric .btn.selected, #courseDetailPage .courseElectric .btn:hover {
    color: #fff;
  }
  #courseDetailPage .courseElectric .btn.selected::after, #courseDetailPage .courseElectric .btn:hover::after {
    _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
    background: none;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
}

#courseDetailPage .news {
  padding: 0 3%;
}

#courseDetailPage .news-link:hover {
  color: #9FC238;
}

#courseDetailPage .courseElectronics .news-link:hover {
  color: #2A7FC4;
}

#courseDetailPage .courseElectric .news-link:hover {
  color: #C83A3F;
}

#courseDetailPage .scrollWrap {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .scrollWrap {
    overflow-x: scroll;
  }
}

#courseDetailPage .table {
  width: 100%;
  text-align: center;
  background-color: #fff;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table {
    min-width: 720px;
  }
}

#courseDetailPage .table th span,
#courseDetailPage .table td span {
  display: inline-block;
  font-size: 0.71429rem;
  line-height: 1.4em;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table th span,
  #courseDetailPage .table td span {
    font-size: 2.66667vw;
  }
}

#courseDetailPage .table th.time {
  font-size: 0.7rem;
  border-top: 2px solid #fff;
}

#courseDetailPage .table th {
  height: 43px;
  color: #fff;
  background-color: #9FC238;
  border-right: 2px solid #fff;
  vertical-align: middle;
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table th {
    height: 4em;
    font-size: 3.2vw;
  }
}

#courseDetailPage .table th span {
  font-size: 1.5rem;
  margin-right: 2px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table th span {
    font-size: 4.26667vw;
  }
}

#courseDetailPage .table td {
  color: #222222;
  background: #f4f4f4;
  vertical-align: middle;
  border-top: solid 2px #fff;
  border-left: solid 2px #fff;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table td {
    height: 2.5em;
  }
}

#courseDetailPage .table td.bgBlank {
  background: #fff;
}

#courseDetailPage .table td .bgDark {
  border-radius: 10px;
  color: #fff;
  background-color: #9FC238;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 3px * 2);
  height: calc(100% - 3px * 2);
  margin: 3px;
}

#courseDetailPage .table td .bgLine {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#courseDetailPage .table .labelTop {
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table .labelTop {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table .labelTop:last-child {
    width: 15%;
  }
}

#courseDetailPage .table .labelTop:not(:last-child) {
  width: 15%;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

#courseDetailPage .table .labelLeft {
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table .labelLeft {
    height: 5em;
  }
}

#courseDetailPage .table-scrollNote {
  font-size: 0.85714rem;
  margin-bottom: 5px;
}

#courseDetailPage .table-note {
  margin-top: 5px;
  text-align: right;
  font-size: 0.85714rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .table-note {
    font-size: 0.71429rem;
    text-align: left;
  }
}

#courseDetailPage .courseElectric .table th {
  background-color: #C83A3F;
}

#courseDetailPage .courseElectric .table td {
  color: #C83A3F;
}

#courseDetailPage .courseElectric td .bgLine {
  background-color: rgba(83, 166, 108, 0.05);
}

#courseDetailPage .courseElectronics .table th {
  background-color: #2A7FC4;
}

#courseDetailPage .courseElectronics .table td {
  color: #2A7FC4;
}

#courseDetailPage .courseElectronics td .bgLine {
  background-color: rgba(83, 166, 108, 0.05);
}

#courseDetailPage #course {
  background: #f2f7fa;
}

#courseDetailPage .courseElectric .table td {
  width: 19%;
}

/*******講師紹介追加********/
#courseDetailPage .teacherBlock {
  background: #f2f7fa;
  padding: 80px 0 120px;
}

#courseDetailPage .teacherBlock .teacher-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list {
    padding: 0 5%;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof {
  width: 47%;
  margin-right: 6%;
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof {
    width: 100%;
  }
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof:nth-of-type(odd) {
    margin-right: 0;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof:nth-of-type(even) {
  margin-right: 0;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .teacher-pic {
  width: 30%;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof .teacher-pic {
    margin: 0 auto 25px;
    width: 40%;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .teacher-pic img {
  max-width: 100%;
  border-radius: 50%;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about {
  width: 65%;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof .about {
    width: 100%;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about dt {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.0rem;
  border-bottom: 1px solid #9fc238;
  padding-bottom: 15px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof .about dt {
    font-size: 1.25rem;
    text-align: center;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about dd {
  font-size: 1.0rem;
  line-height: 1.6;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list {
    justify-content: center;
  }
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li {
  width: 45px;
  height: 45px;
  margin-right: 15px;
  transition: all .3s ease;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li:last-of-type {
  margin-right: 0;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li:hover {
  opacity: 0.8;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.insta {
  background: url(/asset/img/common/icon_insta.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.twitter {
  background: url(/asset/img/common/icon_x.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.fb {
  background: url(/asset/img/common/icon_fb.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.note {
  background: url(/asset/img/common/icon_note.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.youtube {
  background: url(/asset/img/common/icon_youtube.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li.web {
  background: url(/asset/img/common/icon_web_g.png) no-repeat;
  background-size: contain;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li a {
  display: block;
  width: 100%;
  height: 100%;
  transition: all .3s ease;
}

#courseDetailPage .teacherBlock .teacher-list .teacher-plof .about .sns-list li a:hover {
  opacity: 0.8;
}

/****各種コースのみの設定***/
#courseDetailPage {
  /*プロダクトコース、Webデザイン、ITコース*/
}

#courseDetailPage .it_engineer06 .careerBlock,
#courseDetailPage .it_engineer04 .careerBlock,
#courseDetailPage .it_engineer02 .careerBlock {
  padding-top: 40px;
  padding-bottom: 100px;
}

#support .mainVisual {
  /*background: url('/asset/img/support/mv.jpg') no-repeat center center/cover;*/
  background-size: cover;
}

#support .featureBlock {
  background: #F2F7FA;
}

#support .supportTitle {
  text-align: center;
  font-size: 20px;
  color: #004098;
  margin-bottom: 20px;
}

@media screen and (min-width: 769px), print {
  #support .supportTitle {
    font-size: 28px;
    margin-bottom: 30px;
  }
}

#support .supportTitle_sub {
  text-align: center;
}

#support .supportLicenseGraf {
  margin: 30px 0 0 0;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 769px), print {
  #support .supportLicenseGraf {
    margin: 60px 0 0 0;
  }
}

#support .supportLicenseGraf li {
  margin: 0 3%;
}

#support .supportLicenseGraf li img {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #support .supportLicenseGraf li {
    width: 24%;
  }
}

#support .features .feature .featureTitle,
#support .features .feature .featureSubTitle .subTitleLabel > span {
  color: #003281;
}

#support .curriculumBlock .l-curriculumTable {
  display: block;
}

#support .curriculumBlock .curriculumTable {
  width: 100%;
}

#support .curriculumBlock .curriculumTable {
  text-align: center;
  z-index: 0;
}

#support .curriculumBlock .curriculumTable dt {
  font-size: 1.5rem;
}

@media screen and (min-width: 769px), print {
  #support .curriculumBlock .curriculumTable dt {
    font-size: 2.4rem;
  }
}

#support .curriculumBlock .curriculumTable dd {
  position: relative;
  margin-bottom: 80px;
}

#support .curriculumBlock .curriculumTable dd:before {
  content: '';
  position: absolute;
  top: 0%;
  left: 36px;
  transform: translateX(-50%);
  width: 2px;
  height: 88%;
  background-color: #004098;
  z-index: -1;
}

@media screen and (min-width: 769px), print {
  #support .curriculumBlock .curriculumTable dd:before {
    height: 93%;
  }
}

#support .curriculumBlock .curriculumTable dd.second:before {
  height: 70%;
}

@media screen and (min-width: 769px), print {
  #support .curriculumBlock .curriculumTable dd.second:before {
    height: 80%;
  }
}

#support .curriculumBlock .curriculumTable:not(:last-of-type)::after {
  content: none;
}

#support .support_voice {
  /*background: url('/asset/img/common/bg_stripe_yellow.png');*/
  background: #F2F7FA;
  padding: 5% 0;
}

@media screen and (min-width: 769px), print {
  #support .support_voice {
    padding: 80px 0;
  }
}

#support .support_voice__rap {
  padding: 0 3%;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__rap {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0;
  }
}

#support .support_voice__inner {
  line-height: 1.6;
  margin-bottom: 30px;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__inner {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
}

#support .support_voice__inner.center {
  justify-content: center;
}

#support .support_voice__inner__box {
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 20px;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__inner__box {
    width: 48%;
    margin-bottom: 0;
  }
}

#support .support_voice__photo {
  position: absolute;
  width: 23%;
  top: 40px;
  right: 20px;
}

#support .support_voice__photo img {
  width: 100%;
  border-radius: 50%;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__photo {
    width: 20%;
    top: 15px;
  }
}

#support .support_voice__ttl {
  background: #004098;
  color: #fff;
  display: inline-block;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 12px;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__ttl {
    font-size: 14px;
  }
}

#support .support_voice__name {
  margin: 5px 0 20px 0;
}

#support .support_voice__name .bold {
  font-weight: bold;
}

#support .support_voice__name .big {
  font-size: 22px;
}

#support .support_voice__box02 {
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
  position: relative;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__box02 {
    display: flex;
    justify-content: space-between;
  }
}

#support .support_voice__box02__r {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  position: relative;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__box02__r {
    width: 65%;
    padding: 40px 30px;
  }
}

#support .support_voice__box02__r:after {
  content: none;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__box02__r:after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(255, 255, 255, 0);
    border-top-width: 12px;
    border-bottom-width: 12px;
    border-left-width: 12px;
    border-right-width: 18px;
    margin-top: -12px;
    border-right-color: #fff;
    right: 100%;
    top: 50%;
  }
}

#support .support_voice__box02__l {
  position: static;
  width: 80%;
  margin: 0 auto 20px;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__box02__l {
    position: absolute;
    width: 22%;
    position: initial;
    top: 20px;
    right: 10px;
  }
}

#support .support_voice__box02__l img {
  width: 100%;
  border-radius: 50%;
}

#support .support_voice__box02__tl {
  color: #004098;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #support .support_voice__box02__tl {
    font-size: 18px;
  }
}

#support .support_voice__box02__name {
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 15px;
}

#support .support_voice__box02__name span {
  font-size: 18px;
}

#support .supportList {
  padding: 5% 3%;
}

@media screen and (min-width: 769px), print {
  #support .supportList {
    padding: 80px 0;
    max-width: 1120px;
    margin: 0 auto;
  }
}

#support .supportList h3.titleLabel03 {
  font-size: 20px;
}

@media screen and (min-width: 769px), print {
  #support .supportList h3.titleLabel03 {
    font-size: 32px;
  }
}

#support .supportList .course01.titleLabel02 {
  color: #9FC238;
}

#support .supportList .course01::before {
  background: #9FC238;
}

#support .supportList .course01 .courseTl__inner {
  color: #9FC238;
}

#support .supportList .course02.titleLabel02,
#support .supportList .course02 .courseTl__inner {
  color: #2A7FC4;
}

#support .supportList .course02::before {
  background: #2A7FC4;
}

#support .supportList .course03.titleLabel02,
#support .supportList .course03 .courseTl__inner {
  color: #C83A3F;
}

#support .supportList .course03::before {
  background: #C83A3F;
}

#support .supportList__box {
  line-height: 1.4;
}

@media screen and (min-width: 769px), print {
  #support .supportList__box {
    display: flex;
    flex-wrap: wrap;
  }
}

#support .supportList__box li {
  margin-bottom: 20px;
}

@media screen and (min-width: 769px), print {
  #support .supportList__box li {
    width: 22.2%;
    margin: 0 1.3% 30px 1.3%;
  }
}

#support .supportList__box li .courseTl {
  background: #f4f4f4;
  margin-bottom: 10px;
  color: #2A7FC4;
  text-align: center;
  min-height: 80px;
  padding: 35px;
  position: relative;
}

#support .supportList__box li .courseTl__inner {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

#support .supportList__box li .courseTl__inner span {
  display: inline-block;
  margin-bottom: 5px;
}

#support .supportList__box li .courseTl__inner span.lv {
  font-family: "Oswald", sans-serif;
  font-size: 13px;
}

#support .supportList__box li .courseTl__inner span.memo {
  color: #000;
  font-size: 1.0em;
  font-weight: 400;
}

#support .supportList__box li .courseTl__inner span {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 34px;
}

#support .supportList__box li .courseTl__inner span.lv {
  font-size: 13px;
}

#support .supportList__box.course01 .courseTl {
  color: #9FC238;
}

#support .supportList__box.course03 .courseTl {
  color: #C83A3F;
}

#support .supportList__box--career li {
  padding-left: 18px;
  text-indent: -18px;
  margin-bottom: 15px;
}

@media screen and (min-width: 769px), print {
  #support .supportList__box--career li {
    width: 28.5%;
  }
}

#support .supportList__box--career li::before {
  content: '●';
  color: #004098;
  font-size: 10px;
  padding-right: 8px;
}

#support .career_bg {
  background: url("/asset/img/support/bg_transfer.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  padding: 5% 3%;
}

@media screen and (min-width: 769px), print {
  #support .career_bg {
    padding: 80px 0;
  }
}

#support .career_bg__inner {
  background: rgba(255, 255, 255, 0.7);
  max-width: 1120px;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
}

#support .career_bg02 {
  background: #f2f5fa;
}

#support .career_bg02__inner {
  max-width: 1120px;
  margin: 0 auto;
}

#support .career_bg02 .careerFormat dt {
  background: #f3e100;
  color: #004098;
}

#support .career_bg02 .careerDay {
  display: flex;
  align-items: top;
  margin-bottom: 40px;
}

#support .career_bg02 .careerDay .day img {
  width: 72px;
  height: 72px;
  /*background: $color-blue;
        color: #fff;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        margin-right: 10px;
        font-size: 14px;
        position: relative;*/
}

#support .career_bg02 .careerDay .day img__inner img {
  /* position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);*/
  width: 72px;
  height: 72px;
}

#support .career_bg02 .careerDay .day img span {
  font-size: 10px;
}

#support .career_bg02 .careerDayText {
  margin: 0 0 20px 25px;
  text-align: left;
}

@media screen and (min-width: 769px), print {
  #support .career_bg02 .careerDayText {
    margin: 0 0 20px 45px;
  }
}

#support .career_bg02 .careerDayText span {
  color: #004098;
  font-size: 24px;
  display: inline-block;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #support .career_bg02 .careerDayText span {
    font-size: 26px;
  }
}

#support .career_bg02 .careerDayText span.n02 {
  margin-top: 40px;
}

#support .career_bg02 .careerDayText02 {
  color: #004098;
  margin: 20px 0 5px 0;
}

#support .arrowBtn {
  width: 16%;
  margin: 20px auto 0 auto;
}

#support .arrowBtn img {
  width: 100%;
}

#support #career01,
#support #career02,
#support #career03 {
  margin-top: -250px;
  padding-top: 250px;
}

.txt-c {
  text-align: center;
}

.rate {
  color: #004098;
  line-height: 1.7;
  text-align: center;
  background: #F4F4F4;
  padding: 20px 35px;
}

@media screen and (min-width: 769px), print {
  .rate {
    width: 65%;
    margin: 35px auto;
    padding: 35px;
  }
}

.rate p {
  font-size: 14px;
}

@media screen and (min-width: 769px), print {
  .rate p {
    font-size: 16px;
  }
}

.rate img {
  width: 100%;
  display: inline-block;
  margin: 20px 0;
}

@media screen and (min-width: 769px), print {
  .rate img {
    width: 320px;
  }
}

.rate h4 {
  font-size: 18px;
}

@media screen and (min-width: 769px), print {
  .rate h4 {
    font-size: 26px;
  }
}

.iconImage--support {
  width: 65px;
}

.iconImage--support img {
  width: 100%;
}

.featureTitle--support {
  color: #004098;
  text-align: center;
  font-weight: 700;
  margin-bottom: 14px;
}

@media screen and (min-width: 769px), print {
  .featureTitle--support {
    font-size: 1.28571rem;
  }
}

@media screen and (max-width: 768px) {
  .featureTitle--support {
    font-size: 4.53333vw;
  }
}

.featureTitle--support span {
  border-bottom: solid 2px #004098;
  padding-bottom: 5px;
  display: inline-block;
}

#opencampus .mainVisual {
  max-width: 100%;
  min-height: 64vw;
  background: url("/asset/img/opencampus/kv@2x.jpg") no-repeat center center/cover;
  background-size: cover;
}

#opencampus .mainVisual::before {
  display: none;
}

@media screen and (min-width: 769px), print {
  #opencampus .mainVisual {
    min-height: 420px;
    background: url("/asset/img/opencampus/kv.jpg") no-repeat center center/cover;
  }
}

#opencampus .mainVisual_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  color: #004098;
  letter-spacing: 5px;
  font-size: 1.5rem;
  line-height: 1.4;
  width: 100%;
  top: 60%;
  max-width: 180px;
}

@media screen and (min-width: 769px), print {
  #opencampus .mainVisual_title {
    font-size: 2.14286rem;
  }
}

#opencampus .mainVisual_title img {
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #opencampus .mainVisual_title {
    max-width: 475px;
  }
}

#opencampus .opencampusDays {
  background: #F2F7FA;
  padding: 8% 3%;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays {
    padding: 50px 0;
  }
}

#opencampus .opencampusDays_box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays_box {
    margin: 0 auto;
  }
}

#opencampus .opencampusDays_box li {
  background: #fff;
  text-align: center;
  box-shadow: 0px 3px 3px 2px #ddd;
  margin: 0 1% 20px;
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays_box li {
    width: 31%;
    min-height: 300px;
  }
}

#opencampus .opencampusDays_box li a {
  display: block;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays_box li a {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px 30px;
  }
}

#opencampus .opencampusDays_box li .daysTxt {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 15px;
  line-height: 1em;
  text-align: center;
  font-family: "Oswald", sans-serif;
}

#opencampus .opencampusDays_box li .daysTxt span {
  display: inline-block;
  margin-left: 10px;
  color: #004098;
  font-size: 24px;
}

#opencampus .opencampusDays_box li .daySubtxt {
  margin-bottom: 1em;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

#opencampus .opencampusDays_box li h3 {
  text-align: left;
  margin-bottom: 1em;
  line-height: 1.5em;
}

@media screen and (max-width: 768px) {
  #opencampus .opencampusDays_box li h3 {
    text-align: center;
  }
}

#opencampus .opencampusDays_box li p.op_text {
  margin-bottom: 1.5em;
  line-height: 1.5em;
  text-align: left;
}

@media screen and (max-width: 768px) {
  #opencampus .opencampusDays_box li p.op_text {
    display: none;
  }
}

#opencampus .opencampusDays_box li .buttonLabel {
  display: inline-block;
  min-width: 160px;
  margin: 0 auto;
  padding: 0.5em 1em;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays_box li .buttonLabel {
    font-size: 14px;
  }
  #opencampus .opencampusDays_box li .buttonLabel::after {
    top: 10px;
  }
}

#opencampus .opencampusDays_box li.personal {
  background: #FFFABE;
}

#opencampus .opencampusDays_box li.personal .personal-ttl {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

#opencampus .opencampusDays_box li.personal .personal-ttl span {
  display: block;
  margin-bottom: 1em;
  font-size: 18px;
  color: #004098;
}

#opencampus .opencampusDays_box li.personal .personal-ttl span::before, #opencampus .opencampusDays_box li.personal .personal-ttl span::after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 1.5em;
  margin: 0.2em 0.5em 0;
  background: #004098;
  transform: rotate(-25deg);
  transform-origin: center bottom;
}

#opencampus .opencampusDays_box li.personal .personal-ttl span::after {
  transform: rotate(25deg);
}

#opencampus .opencampusDays .l-buttonLayout {
  margin-top: 30px;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusDays .l-buttonLayout {
    width: 25%;
    margin-top: 50px;
  }
}

#opencampus .boxInner {
  position: relative;
  margin: 0 auto;
}

#opencampus .programBox {
  margin: 0 auto;
  padding: 8% 0;
}

@media screen and (min-width: 769px), print {
  #opencampus .programBox {
    padding: 80px 0;
  }
}

#opencampus .programBox .boxInner {
  margin: 0 auto 5%;
  padding: 8% 3%;
}

@media screen and (min-width: 769px), print {
  #opencampus .programBox .boxInner {
    margin: 0 auto 80px;
    padding: 80px 0;
  }
}

#opencampus .programBox .boxInner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 100%;
  background: rgba(159, 194, 56, 0.15);
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .boxInner::before {
    width: 80%;
  }
}

#opencampus .programBox .boxInner.courseElectronics::before {
  left: auto;
  right: 0;
  background: rgba(42, 127, 196, 0.15);
}

#opencampus .programBox .boxInner.courseElectronics .programBox__txt .txtTl {
  color: #2A7FC4;
}

#opencampus .programBox .boxInner.courseElectronics .programBox__txt .txtTl_top::before, #opencampus .programBox .boxInner.courseElectronics .programBox__txt .txtTl_top::after {
  background: #2A7FC4;
}

#opencampus .programBox .boxInner.courseElectric::before {
  background: rgba(200, 58, 63, 0.15);
}

#opencampus .programBox .boxInner.courseElectric .programBox__txt .txtTl {
  color: #C83A3F;
}

#opencampus .programBox .boxInner.courseElectric .programBox__txt .txtTl_top::before, #opencampus .programBox .boxInner.courseElectric .programBox__txt .txtTl_top::after {
  background: #C83A3F;
}

#opencampus .programBox .programBox__item {
  position: relative;
  max-width: 1200px;
  margin: 0 auto 62px auto;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .programBox__item {
    display: flex;
    justify-content: space-between;
    width: auto;
    flex-direction: column-reverse;
    padding: 0 3%;
    margin: 0 auto 10% auto;
  }
}

#opencampus .programBox .programBox__item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  #opencampus .programBox .programBox__item:nth-of-type(odd) .programBox__txt {
    left: 0;
    right: auto;
  }
  #opencampus .programBox .programBox__item:nth-of-type(odd) .programBox__img {
    float: right;
  }
}

#opencampus .programBox .programBox__txt {
  position: relative;
  width: 600px;
  background: #fff;
  box-sizing: border-box;
  z-index: 1;
}

@media screen and (min-width: 769px), print {
  #opencampus .programBox .programBox__txt {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80%;
    padding: 50px 40px;
  }
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .programBox__txt {
    width: 94%;
    margin: -8% auto 0;
    padding: 8% 5%;
  }
}

#opencampus .programBox .programBox__txt .txtTl {
  color: #9FC238;
  text-align: center;
}

#opencampus .programBox .programBox__txt .txtTl_top {
  display: block;
  margin-bottom: 1em;
  font-size: 28px;
  font-weight: normal;
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .programBox__txt .txtTl_top {
    font-size: 20px;
  }
}

#opencampus .programBox .programBox__txt .txtTl_top::before, #opencampus .programBox .programBox__txt .txtTl_top::after {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1px;
  background: #9FC238;
}

#opencampus .programBox .programBox__txt .txtTl_bottom {
  display: block;
  margin-bottom: 1em;
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .programBox__txt .txtTl_bottom {
    font-size: 26px;
  }
}

#opencampus .programBox .programBox__txt .textDesk {
  line-height: 1.5em;
}

#opencampus .programBox .programBox__img {
  width: 680px;
}

@media screen and (min-width: 769px), print {
  #opencampus .programBox .programBox__img {
    float: left;
    margin-top: 60px;
  }
}

@media screen and (max-width: 768px) {
  #opencampus .programBox .programBox__img {
    width: auto;
    margin: 0 -3%;
  }
}

#opencampus .programBox .programBox__cyuki {
  text-align: center;
  margin-bottom: 20px;
}

#opencampus .privilegeBox {
  background: #f2f7fa;
  padding: 8% 0;
  line-height: 1.6;
  overflow: hidden;
}

@media screen and (min-width: 769px), print {
  #opencampus .privilegeBox {
    padding: 80px 0;
  }
}

#opencampus .privilegeBox .features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

#opencampus .privilegeBox .features .feature {
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  padding: 7% 5%;
  height: 100%;
}

@media screen and (min-width: 769px), print {
  #opencampus .privilegeBox .features .feature {
    width: 100%;
    padding: 48px 28px;
    height: 100%;
  }
}

#opencampus .privilegeBox .features .featureWrap {
  width: 94% !important;
  margin: 0 0.5% 6%;
}

@media screen and (min-width: 769px), print {
  #opencampus .privilegeBox .features .featureWrap {
    max-width: 32%;
  }
}

#opencampus .privilegeBox .features .featureSubTitle {
  margin: 0 auto 1em;
  text-align: center;
}

#opencampus .privilegeBox .features .featureSubTitle .subTitleLabel {
  display: inline-block;
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  #opencampus .privilegeBox .features .featureSubTitle .subTitleLabel {
    font-size: 1.85714rem;
  }
}

@media screen and (max-width: 768px) {
  #opencampus .privilegeBox .features .featureSubTitle .subTitleLabel {
    font-size: 5.33333vw;
  }
}

#opencampus .privilegeBox .features .featureSubTitle .subTitleLabel::before, #opencampus .privilegeBox .features .featureSubTitle .subTitleLabel::after {
  content: '―';
  display: inline-block;
  margin: 0 0.5em;
}

#opencampus .privilegeBox .features .featureSubTitle .subTitleLabel > span {
  color: #004098;
}

#opencampus .privilegeBox .features .featureTitle {
  text-align: center;
  color: #004098;
  font-weight: bold;
  margin-bottom: 14px;
}

@media screen and (min-width: 769px), print {
  #opencampus .privilegeBox .features .featureTitle {
    font-size: 2.28571rem;
  }
}

@media screen and (max-width: 768px) {
  #opencampus .privilegeBox .features .featureTitle {
    font-size: 6.93333vw;
  }
}

#opencampus .privilegeBox .features .featureTitle > span {
  display: inline-block;
}

#opencampus .privilegeBox .featureTitle--support {
  font-size: 32px;
}

@media screen and (max-width: 768px) {
  #opencampus .privilegeBox .featureTitle--support {
    font-size: 26px;
  }
}

#opencampus .privilegeBox .featureTitle--support span {
  border: none;
}

#opencampus .scheduleBox {
  padding: 8% 3%;
}

@media screen and (min-width: 769px), print {
  #opencampus .scheduleBox {
    padding: 80px 0;
  }
}

@media screen and (min-width: 769px), print {
  #opencampus .scheduleBox__inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
}

#opencampus .scheduleBox__item {
  position: relative;
  margin: 0 0 60px;
}

@media screen and (min-width: 769px), print {
  #opencampus .scheduleBox__item {
    width: 29%;
    margin: 0 2%;
  }
}

#opencampus .scheduleBox__item:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -7.5%;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 0px;
  border-top: solid 2px #222222;
  border-right: solid 2px #222222;
  transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
  #opencampus .scheduleBox__item:not(:last-child)::after {
    top: auto;
    bottom: -30px;
    right: 50%;
    transform: rotate(135deg);
  }
}

#opencampus .scheduleBox__pic {
  margin-bottom: 20px;
}

#opencampus .scheduleBox__pic img {
  width: 100%;
  height: auto;
}

#opencampus .scheduleBox .scheduleBox__txt {
  margin-bottom: 0.5em;
  color: #004098;
  font-size: 20px;
  font-weight: bold;
}

#opencampus .scheduleBox .scheduleBox__txt span {
  font-family: "Oswald", sans-serif;
}

#opencampus .scheduleBox p {
  margin-bottom: 1em;
  font-size: 16px;
  line-height: 1.5em;
}

#opencampus .scheduleBox .scheduleBox__detail h2 {
  margin-bottom: 0.5em;
  color: #004098;
  font-size: 20px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
}

#opencampus .opencampusBnr {
  background: url(/asset/img/opencampus/bg.jpg) no-repeat;
  padding: 5% 3%;
  background-size: cover;
  background-position: center;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusBnr {
    padding: 80px 0;
  }
}

#opencampus .opencampusBnr .l-buttonLayout {
  margin-top: 30px;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusBnr .l-buttonLayout {
    width: 25%;
    margin-top: 50px;
  }
}

#opencampus .opencampusBnr img {
  width: 100%;
}

#opencampus .opencampusBnr__tl {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #004098;
}

@media screen and (min-width: 769px), print {
  #opencampus .opencampusBnr__tl {
    font-size: 28px;
  }
}

#opencampus .opencampusBnr__pic01 {
  position: relative;
}

#opencampus .time_tt {
  background: #004098;
  position: absolute;
  top: 0px;
  left: 0;
  padding: 2px 20px;
  color: #fff;
  border-radius: 10px 0 0 0;
}

@media screen and (min-width: 769px), print {
  #opencampus .time_tt {
    display: none;
  }
}

#opencampus .daysTxt {
  font-size: 46px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  line-height: 1em;
}

#opencampus .daysTxt span {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.6em;
  line-height: 1em;
}

#opencampus .oc_info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 730px;
  margin: 0 auto 50px;
}

@media screen and (max-width: 768px) {
  #opencampus .oc_info {
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
    margin: 5% auto;
  }
}

#opencampus .oc_info .oc_date {
  position: relative;
  margin-bottom: 5%;
  padding-bottom: 5%;
}

@media screen and (min-width: 769px), print {
  #opencampus .oc_info .oc_date {
    width: 35%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

#opencampus .oc_info .oc_date::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: calc(50% - 2em);
  display: inline-block;
  width: 4em;
  height: 1px;
  background: #000;
}

@media screen and (min-width: 769px), print {
  #opencampus .oc_info .oc_date::after {
    display: none;
  }
}

#opencampus .oc_info .oc_date .daysTxt {
  margin-bottom: 15px;
  font-size: 58px;
}

#opencampus .oc_info .oc_date .daysTxt span {
  color: #004098;
}

#opencampus .oc_info .oc_date .daySubtxt {
  font-weight: bold;
}

@media screen and (min-width: 769px), print {
  #opencampus .oc_info .oc_title {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  #opencampus .oc_info .oc_title h3 {
    text-align: center;
  }
}

#opencampus .oc_info .oc_body {
  width: 100%;
  margin-top: 8%;
}

@media screen and (min-width: 769px), print {
  #opencampus .oc_info .oc_body {
    margin-top: 40px;
    text-align: center;
  }
  #opencampus .oc_info .oc_body.column2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #opencampus .oc_info .oc_body.column2 > div {
    margin: 0;
    width: 47%;
  }
}

#opencampus .oc_info .oc_body > div {
  width: 100%;
  margin: 0 auto 20px;
  text-align: left;
}

#opencampus .oc_info .oc_body > div img {
  width: 100%;
  height: auto;
}

#opencampus .oc_info .oc_text {
  color: #555555;
}

#campuslife .mainVisual {
  background: url("/asset/img/campuslife/kv.jpg") no-repeat center center/cover;
  background-size: cover;
}

#campuslife img {
  width: 100%;
  vertical-align: middle;
}

#campuslife .m_space {
  margin-bottom: 0;
  padding: 0;
}

#campuslife .innerRap {
  padding: 5% 3%;
  line-height: 1.6;
}

@media screen and (min-width: 769px), print {
  #campuslife .innerRap {
    padding: 80px 0;
    max-width: 1120px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap {
    display: flex;
    justify-content: space-between;
  }
}

#campuslife .lifeRap__box {
  padding: 20px;
  background: #fae8e9;
  position: relative;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__box {
    width: 43%;
    min-width: 445px;
  }
}

#campuslife .lifeRap__box--type02 {
  background: #f9feeb;
}

#campuslife .lifeRap__pic {
  width: 50%;
  margin: 0 auto;
  position: absolute;
  top: 100px;
  right: 0;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__pic {
    margin: 0;
    width: 36%;
    top: 85px;
    right: 5px;
  }
}

#campuslife .lifeRap__ttl {
  font-size: 19px;
  color: #C83A3F;
  margin-bottom: 15px;
}

#campuslife .lifeRap__ttl--type02 {
  color: #9FC238;
}

#campuslife .lifeRap__category {
  font-size: 14px;
  color: #fff;
  background: #C83A3F;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 5px;
}

#campuslife .lifeRap__category--type02 {
  background: #9FC238;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 5px;
}

#campuslife .lifeRap__name {
  font-size: 12px;
  margin-bottom: 15px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__name {
    font-size: 15px;
  }
}

#campuslife .lifeRap__name span {
  font-size: 14px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__name span {
    font-size: 20px;
  }
}

#campuslife .lifeRap__comment {
  font-size: 14px;
  margin-bottom: 80px;
  width: 50%;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__comment {
    width: 60%;
  }
}

#campuslife .lifeRap__comment span {
  font-size: 18px;
  color: #C83A3F;
  display: inline-block;
  margin-bottom: 5px;
}

#campuslife .lifeRap__comment--type02 {
  font-size: 14px;
  margin-bottom: 80px;
  width: 50%;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__comment--type02 {
    width: 60%;
  }
}

#campuslife .lifeRap__comment--type02 span {
  font-size: 18px;
  color: #9FC238;
  display: inline-block;
  margin-bottom: 5px;
}

#campuslife .lifeRap__schedule {
  padding: 5% 3% 3%;
  background: #fff;
  position: relative;
  margin-top: -20px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule {
    margin-top: 0;
  }
}

#campuslife .lifeRap__schedule--type02 {
  margin-top: -300px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule--type02 {
    margin-top: 0;
  }
}

#campuslife .lifeRap__schedule .what_day {
  background: #C83A3F;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px 10px;
  color: #fff;
}

#campuslife .lifeRap__schedule .what_day span {
  background: url("/asset/img/campuslife/time.png") no-repeat;
  background-size: 18px;
  background-position: left center;
  padding-left: 25px;
}

#campuslife .lifeRap__schedule .what_day--type02 {
  background: #9FC238;
}

#campuslife .lifeRap__schedule dl {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

#campuslife .lifeRap__schedule dl dt {
  color: #C83A3F;
  width: 20%;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule dl dt {
    width: 12%;
  }
}

#campuslife .lifeRap__schedule dl dt span {
  border-bottom: 1px solid #C83A3F;
  display: inline-block;
}

#campuslife .lifeRap__schedule dl dd {
  width: 86%;
}

#campuslife .lifeRap__schedule dl dd .small {
  font-size: 12px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule dl dd .photo {
    display: flex;
    justify-content: space-between;
  }
}

#campuslife .lifeRap__schedule dl dd .photo_l {
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule dl dd .photo_l {
    width: 55%;
  }
}

@media screen and (min-width: 769px), print {
  #campuslife .lifeRap__schedule dl dd .photo_r {
    width: 40%;
  }
}

#campuslife .lifeRap__schedule--type02 {
  border-color: #9FC238;
}

#campuslife .lifeRap__schedule--type02 .type02 {
  color: #9FC238;
}

#campuslife .lifeRap__schedule--type02 .type02 span {
  border-color: #9FC238;
}

#campuslife .lifeRap__schedule--type02 .under {
  padding-bottom: 30px;
}

#campuslife .calendarRap {
  background: #F2F7FA;
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}

#campuslife .calendarRap__month .monthList {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month .monthList {
    display: block;
    width: 12%;
    letter-spacing: -1px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month .monthList::before {
    content: '';
    position: absolute;
    display: block;
    background: #023281;
    height: 4px;
    width: 54%;
    top: 62px;
    right: -54%;
  }
}

#campuslife .calendarRap__month .monthList::after {
  content: '';
  position: absolute;
  display: block;
  background: #023281;
  height: 40px;
  width: 4px;
  bottom: -40px;
  left: 15%;
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month .monthList::after {
    display: none;
  }
}

#campuslife .calendarRap__month .monthList .monthImg {
  width: 30%;
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month .monthList .monthImg {
    width: auto;
  }
}

#campuslife .calendarRap__month .monthList.last::before, #campuslife .calendarRap__month .monthList.last::after {
  display: none;
}

#campuslife .calendarRap__month__list {
  width: 62%;
}

@media screen and (min-width: 769px), print {
  #campuslife .calendarRap__month__list {
    width: auto;
    margin-top: 10px;
  }
}

#campuslife .calendarRap__month__list li {
  margin-bottom: 5px;
  font-size: 13px;
  text-indent: -8px;
  padding-left: 8px;
}

#campuslife .calendarRap__month__list li::before {
  content: '●';
  color: #004098;
  font-size: 10px;
  display: inline-block;
  margin-right: 3px;
}

#campuslife .internationalBox {
  background: #f2f5fa;
  padding: 0 3%;
}

#campuslife .internationalBox__pic {
  margin-bottom: 30px;
}

@media screen and (min-width: 769px), print {
  #campuslife .internationalBox__pic {
    width: 60%;
    max-width: 715px;
    margin: 0 auto 50px auto;
  }
}

#campuslife .internationalBox__picTxt {
  text-align: center;
  margin-top: 15px;
}

#campuslife .internationalBox__picTxt span {
  font-size: 18px;
  color: #004098;
  display: block;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #campuslife .internationalBox__picTxt span {
    font-size: 24px;
  }
}

#campuslife .internationalBox .titleLabel02::after {
  background: #fff;
}

#campuslife .internationalBox .internationalList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#campuslife .internationalBox .internationalList li {
  margin: 0 1% 1% 0;
  width: 48%;
}

@media screen and (min-width: 769px), print {
  #campuslife .internationalBox .internationalList li {
    width: 24%;
  }
}

#campuslife .internationalBox .internationalList li::before {
  content: '●';
  color: #004098;
  font-size: 10px;
  display: inline-block;
  margin-right: 5px;
}

#teacher .mainVisual {
  background: url("/asset/img/teacher/mv.jpg") no-repeat center center/cover;
  background-size: cover;
}

#teacher .teacherTitle {
  text-align: center;
}

#teacher .teacherTitle h2 {
  font-size: 30px;
  color: #004098;
  margin-bottom: 35px;
}

#teacher .featureBlock {
  background: #F2F7FA;
}

#teacher .features .feature .featureTitle,
#teacher .features .feature .featureSubTitle .subTitleLabel > span {
  color: #004098;
}

#teacher .teacherPoint {
  justify-content: center;
}

#teacher .teacherPointBox {
  position: relative;
  max-width: 1120px;
  margin: 0 auto;
}

#teacher .teacherPointBox img {
  width: 100%;
}

#teacher .teacherPointBox .subTitleLabel span {
  color: #004098;
}

#teacher .teacherPointBox .featureTitle {
  color: #004098;
}

#teacher .teacherPointBox__pic01 {
  display: none;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherPointBox__pic01 {
    display: block;
    position: absolute;
    bottom: 0;
    left: -10px;
    width: 12.5%;
  }
}

#teacher .teacherPointBox__pic02 {
  display: none;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherPointBox__pic02 {
    display: block;
    position: absolute;
    bottom: 0;
    right: -10px;
    width: 14%;
  }
}

#teacher .featureWrap:first-child {
  margin-right: 1%;
}

#teacher .teacherVoice {
  padding: 5% 0;
  background: #f2f7fa;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice {
    padding: 80px 0;
  }
}

#teacher .teacherVoice__rap {
  max-width: 1120px;
  margin: 0 auto;
}

#teacher .teacherVoice__box {
  padding: 50px 20px 20px 20px;
  box-sizing: border-box;
  line-height: 1.6;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice__box {
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

#teacher .teacherVoice__box__course {
  color: #9FC238;
  font-weight: bold;
}

#teacher .teacherVoice__box__course--02 {
  color: #2A7FC4;
}

#teacher .teacherVoice__box__course--03 {
  color: #C83A3F;
}

#teacher .teacherVoice__box__l {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice__box__l {
    width: 75%;
  }
}

#teacher .teacherVoice__box__l:after {
  content: none;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice__box__l:after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(255, 255, 255, 0);
    border-top-width: 12px;
    border-bottom-width: 12px;
    border-left-width: 12px;
    border-right-width: 18px;
    margin-top: -12px;
    border-right-color: #fff;
    left: 18%;
    top: 50%;
  }
}

#teacher .teacherVoice__box__r {
  width: 80%;
  margin: 0 auto 20px;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice__box__r {
    position: absolute;
    width: 16%;
    top: 20%;
    left: 0;
  }
}

#teacher .teacherVoice__box__r img {
  width: 100%;
}

#teacher .teacherVoice__box__tl {
  color: #9FC238;
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #teacher .teacherVoice__box__tl {
    font-size: 18px;
  }
}

#teacher .teacherVoice__box__tl--02 {
  color: #2A7FC4;
}

#teacher .teacherVoice__box__tl--03 {
  color: #C83A3F;
}

#teacher .teacherVoice__box__name {
  font-weight: bold;
  margin-bottom: 20px;
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 15px;
}

#teacher .teacherVoice__box__name span {
  font-size: 18px;
}

#future-creation .mainVisual {
  max-width: 100%;
  background: url("/asset/img/future_creation/kv.jpg") no-repeat center center/cover;
  background-size: cover;
  padding-bottom: 17.4%;
  background-position: center;
  min-height: 175px;
}

#future-creation .mainVisual::before {
  display: none;
}

#future-creation .mainVisual_title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  color: #004098;
  letter-spacing: 5px;
  font-size: 1.5rem;
  line-height: 1.4;
  width: 100%;
}

@media screen and (min-width: 769px), print {
  #future-creation .mainVisual_title {
    font-size: 2.14286rem;
  }
}

#future-creation .mainVisual_title span {
  display: block;
  max-width: 40%;
  margin: 0 auto 10px auto;
  color: #fff;
  background: #004098;
  font-size: 0.85714rem;
  border-radius: 30px;
  padding: 5px 20px 7px 20px;
  letter-spacing: 3px;
}

@media screen and (min-width: 769px), print {
  #future-creation .mainVisual_title span {
    font-size: 1rem;
    max-width: 14%;
  }
}

#future-creation .mainVisual_title {
  color: #333;
  letter-spacing: 5px;
}

#future-creation .mainVisual_title span {
  background: none;
  display: inline-block;
  font-size: initial;
  padding: 0;
  letter-spacing: 5px;
  max-width: auto;
  font-size: 1.2rem;
  color: #f3e100;
}

@media screen and (min-width: 769px), print {
  #future-creation .mainVisual_title span {
    font-size: 2.14286rem;
  }
}

#future-creation .mainVisual_title .futureImg {
  width: 60%;
  max-width: 820px;
  margin: 0 auto 20px auto;
  display: block;
}

@media screen and (min-width: 769px), print {
  #future-creation .mainVisual_title .futureImg {
    width: 30%;
  }
}

#future-creation .futureTxt {
  text-align: center;
}

#future-creation .futureTxt h3 {
  font-size: 22px;
  color: #004098;
  margin-bottom: 20px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureTxt h3 {
    font-size: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg {
    background: url("/asset/img/future_creation/bg01.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
    padding: 10% 0;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img02 {
    background: url("/asset/img/future_creation/bg02.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img03 {
    background: url("/asset/img/future_creation/bg03.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img04 {
    background: url("/asset/img/future_creation/bg04.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img05 {
    background: url("/asset/img/future_creation/bg05.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img06 {
    background: url("/asset/img/future_creation/bg06.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg--img07 {
    background: url("/asset/img/future_creation/bg07.jpg") no-repeat;
    background-size: cover;
    background-position: 83%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureBg {
    background-position: top center;
    padding: 100px 0;
  }
}

#future-creation .futureBg img {
  width: 100%;
}

#future-creation .futureName {
  background: #f3e100;
  color: #004098;
  display: flex;
  padding: 3%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName {
    max-width: 650px;
    width: 80%;
    padding: 30px 0 0 30px;
    margin: -20px 10% 0 0;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName--right {
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName--right .futureName {
    margin: -20px 0 0 10%;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName--left {
    display: flex;
    justify-content: flex-end;
  }
}

#future-creation .futureName--left.no-movie {
  margin-top: 200px;
}

@media screen and (max-width: 768px) {
  #future-creation .futureName--left.no-movie {
    margin-top: 0;
  }
}

#future-creation .futureName__img {
  width: 4%;
  max-width: 23px;
  padding-right: 3%;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__img {
    padding-right: 20px;
    padding-bottom: 30px;
  }
}

#future-creation .futureName__txt {
  border-left: 1px solid #fff;
  padding-left: 3%;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__txt {
    padding: 0 0 30px 20px;
  }
}

#future-creation .futureName__company {
  font-size: 12px;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__company {
    font-size: 14px;
    margin-bottom: 15px;
  }
}

#future-creation .futureName__name {
  font-size: 24px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__name {
    font-size: 30px;
  }
}

#future-creation .futureName__name .middle {
  font-size: 18px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__name .middle {
    font-size: 24px;
  }
}

#future-creation .futureName__name .small {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureName__name .small {
    margin-top: 0;
    display: inline-block;
  }
}

#future-creation .futureRap {
  padding: 3% 3% 10% 3%;
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  line-height: 1.6;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap {
    background: rgba(255, 255, 255, 0.8);
    margin: -20px 10% 0 0;
    padding: 50px 40px 40px 40px;
    max-width: 650px;
    width: 80%;
    box-shadow: 2px 3px 16px -8px #000000;
  }
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap--right {
    margin: -20px 0 0 10%;
  }
}

#future-creation .futureRap .small {
  font-size: 12px;
}

#future-creation .futureRap h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap h4 {
    margin-bottom: 20px;
    font-size: 24px;
  }
}

#future-creation .futureRap h4 span {
  color: #f3e100;
  text-shadow: 2px 2px 3px #bbb;
}

#future-creation .futureRap__flow {
  background: #004098;
  margin: 10px auto 0 auto;
  color: #fff;
  font-size: 12px;
  width: 300px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap__flow {
    display: flex;
    padding: 20px;
    width: auto;
  }
}

#future-creation .futureRap__flow li {
  position: relative;
  padding: 3%;
  margin-bottom: 60px;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap__flow li {
    padding: 0;
    margin-right: 50px;
    margin-bottom: 0;
    min-height: 135px;
  }
}

#future-creation .futureRap__flow li::after {
  content: '';
  position: absolute;
  top: 25px;
  right: 10px;
  width: 140px;
  height: 140px;
  margin-left: 45%;
  transform-origin: left bottom;
  box-sizing: border-box;
  border-left: solid 4px #fff;
  border-bottom: solid 4px #fff;
  transform: rotate(-46deg) skew(28deg, 28deg);
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap__flow li::after {
    content: '';
    position: absolute;
    top: -19px;
    right: -125px;
    width: 90px;
    height: 90px;
    transform-origin: left bottom;
    box-sizing: border-box;
    border-left: solid 4px #fff;
    border-bottom: solid 4px #fff;
    transform: rotate(-130deg) skew(23deg, 23deg);
  }
}

#future-creation .futureRap__flow li:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px), print {
  #future-creation .futureRap__flow li:last-child {
    margin-right: 0;
  }
}

#future-creation .futureRap__flow li:last-child::after {
  display: none;
}

#future-creation .futureRap__flow li h5 {
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

#future-creation .futureRap__flow li h5::before {
  content: '';
  position: absolute;
  background: #f3e100;
  width: 12%;
  height: 3px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto auto -8px auto;
}

#future-creation .futureMovie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

#future-creation .futureMovie iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.ai_mainVisual {
  box-sizing: border-box;
}

@media screen and (min-width: 769px), print {
  .ai_mainVisual {
    height: 560px;
    padding: 10px;
    box-sizing: border-box;
    background: url(/asset/img/ai_robot/pc/bg_ai_04.png) center center no-repeat, url(/asset/img/ai_robot/pc/bg_ai_02.png) no-repeat center center/100% 100%, url(/asset/img/ai_robot/pc/bg_ai_03.png) center center no-repeat, url(/asset/img/ai_robot/pc/bg_ai_01.png) no-repeat center center/100% 100%;
  }
}

@media screen and (max-width: 768px) {
  .ai_mainVisual {
    background: url(/asset/img/ai_robot/sp/bg_ai_01.png) no-repeat top center/100%;
    height: calc(100vw * 0.64);
    padding: 4px;
  }
}

.ai_mainVisual_inner {
  height: 100%;
  box-sizing: border-box;
  border: solid #fff 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ai_mainVisual_inner .aiTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .ai_mainVisual_inner .aiTitle {
    width: 80%;
  }
}

.ai_mainVisual_inner .aiTitle .aiTitle_main {
  margin-bottom: 26px;
}

@media screen and (max-width: 768px) {
  .ai_mainVisual_inner .aiTitle .aiTitle_main {
    margin-bottom: 4%;
  }
}

.ai_mainVisual_inner .aiText {
  color: #fff;
  margin-top: 20px;
  font-size: 1.71429rem;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .ai_mainVisual_inner .aiText {
    font-size: 4vw;
    text-align: center;
    line-height: 1.5;
    margin-top: 3%;
  }
}

@media screen and (min-width: 769px), print {
  #ai .gnav_contListItm,
  #ai .footerInner_head,
  #ai .footerInner_headItm::after {
    font-weight: 500;
  }
}

@media screen and (max-width: 768px) {
  #ai .gnav_contListItm,
  #ai .footerInner_head,
  #ai .footerInner_headItm::after {
    font-weight: 500;
  }
}

#ai .ai_content {
  background: url(/asset/img/ai_robot/bg_content.jpg) -100px 0 repeat;
}

@media screen and (min-width: 769px), print {
  #ai .ai_content {
    padding-top: 168px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_content {
    padding-top: 15%;
  }
}

#ai .ai_content2 {
  background: url(/asset/img/ai_robot/bg_content2.jpg) center center no-repeat;
  background-size: cover;
}

@media screen and (min-width: 769px), print {
  #ai .ai_content2 {
    padding-top: 168px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_content2 {
    padding-top: 15%;
  }
}

#ai .ai_content2 *,
#ai .ai_content2 *::before,
#ai .ai_content2 *::after {
  box-sizing: border-box;
}

#ai .ai_content3 {
  background: #134861 url(/asset/img/ai_robot/bg_column.jpg) center center no-repeat;
  background-size: cover;
  padding: 134px 0 140px 0;
}

@media screen and (max-width: 768px) {
  #ai .ai_content3 {
    padding: 10% 0 10% 0;
  }
}

#ai .ai_content3 *,
#ai .ai_content3 *::before,
#ai .ai_content3 *::after {
  box-sizing: border-box;
}

#ai .ai_section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  padding-left: 15px;
  padding-right: 15px;
}

#ai .ai_section--ttl {
  text-align: center;
  font-size: 2.85714rem;
  color: #fff;
  text-shadow: 0 0 0.17em rgba(36, 179, 214, 0.5), 0 0 0.35em rgba(36, 179, 214, 0.6);
  margin-bottom: 1.5em;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  #ai .ai_section--ttl {
    font-size: 1.71429rem;
  }
}

#ai .ai_section--ttl > span {
  display: inline-block;
}

#ai .ai_section_inner {
  display: flex;
  flex-wrap: wrap;
}

#ai .ai_message {
  border: solid 2px #4d7a8f;
  background-color: rgba(0, 21, 51, 0.6);
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}

@media screen and (min-width: 769px), print {
  #ai .ai_message {
    max-width: 1120px;
    padding: 0 60px 78px 60px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_message {
    width: 92%;
    box-sizing: border-box;
    padding: 0 4% 4% 4%;
  }
}

#ai .ai_message .ai_message_title {
  text-align: center;
}

@media screen and (min-width: 769px), print {
  #ai .ai_message .ai_message_title {
    margin-top: -42px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_message .ai_message_title {
    height: 55px;
    width: auto;
    margin: -20px auto 5% auto;
  }
  #ai .ai_message .ai_message_title img {
    height: 100%;
    width: auto;
  }
}

#ai .ai_message .ai_message_title_sub {
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 1.615;
  font-size: 1.85714rem;
}

@media screen and (min-width: 769px), print {
  #ai .ai_message .ai_message_title_sub {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_message .ai_message_title_sub {
    font-size: 1rem;
    margin-bottom: 7%;
  }
}

#ai .ai_message .ai_message_content {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #ai .ai_message .ai_message_content {
    flex-direction: column;
  }
}

#ai .ai_message .ai_message_text {
  color: #fff;
  line-height: 1.7;
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  #ai .ai_message .ai_message_text {
    font-size: 0.85714rem;
    margin-bottom: 8%;
  }
}

#ai .ai_message .ai_message_image {
  margin-left: 42px;
}

@media screen and (max-width: 768px) {
  #ai .ai_message .ai_message_image {
    margin: auto;
  }
}

#ai .ai_message .corner {
  pointer-events: none;
  user-select: none;
}

@media screen and (min-width: 769px), print {
  #ai .ai_message .corner {
    width: 76px;
    height: 75px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_message .corner {
    width: 55px;
    height: 55px;
  }
}

#ai .ai_message .cornerTopLeft {
  position: absolute;
  top: -15px;
  left: -15px;
}

#ai .ai_message .cornerTopRight {
  position: absolute;
  top: -15px;
  right: -15px;
  transform: scaleX(-1);
}

#ai .ai_message .cornerBottomLeft {
  position: absolute;
  bottom: -15px;
  left: -15px;
  transform: scaleY(-1);
}

#ai .ai_message .cornerBottomRight {
  position: absolute;
  bottom: -15px;
  right: -15px;
  transform: scale(-1);
}

#ai .ai_relation {
  margin-top: 140px;
  padding-bottom: 140px;
}

@media screen and (max-width: 768px) {
  #ai .ai_relation {
    margin-top: 12%;
    padding-bottom: 8%;
  }
}

#ai .ai_relation .ai_relation_title {
  text-align: center;
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_title {
    height: 55px;
    margin: 0 auto 5% auto;
  }
  #ai .ai_relation .ai_relation_title img {
    height: 100%;
    width: auto;
  }
}

#ai .ai_relation .ai_relation_slogan_box {
  background: url(/asset/img/ai_robot/bg_circle.png) center center no-repeat;
  width: 721px;
  height: 706px;
  margin: 140px auto 60px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_slogan_box {
    flex-direction: column;
    width: 92%;
    padding: 4%;
    height: auto;
    margin: 0 auto 26% auto;
    background-size: 100%;
    background: rgba(255, 255, 255, 0.5);
    border: solid 2px #007697;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_slogan_title1 {
    width: 70%;
    text-align: center;
  }
  #ai .ai_relation .ai_relation_slogan_title1 img {
    width: auto;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_slogan_title2 {
    width: 90%;
    text-align: center;
  }
  #ai .ai_relation .ai_relation_slogan_title2 img {
    width: auto;
    max-width: 100%;
  }
}

#ai .ai_relation .ai_relation_slogan_text {
  text-align: center;
  line-height: 1.75;
  margin-top: 12px;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_slogan_text {
    font-size: 1rem;
    text-align: left;
    align-self: flex-start;
  }
}

#ai .ai_relation .ai_relation_character {
  position: absolute;
  bottom: 4px;
  left: -56px;
  user-select: none;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  #ai .ai_relation .ai_relation_character {
    width: 28%;
    max-width: 150px;
    left: auto;
    top: 90%;
    right: -4%;
  }
}

#ai .ai_job_inner {
  margin: 0 -12px;
}

#ai .job {
  height: 100%;
  border: 2px solid #fff;
  padding: 30px 24px 24px 200px;
}

#ai .job_box {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 12px;
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  #ai .job_box {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #ai .job {
    padding: 188px 15px 20px;
    background-position: center 24px !important;
  }
}

#ai .job.job_01 {
  background: rgba(255, 255, 255, 0.85) url(/asset/img/ai_robot/job_01.png) 24px center no-repeat;
  background-size: 150px 150px;
}

#ai .job.job_02 {
  background: rgba(255, 255, 255, 0.85) url(/asset/img/ai_robot/job_02.png) 24px center no-repeat;
  background-size: 150px 150px;
}

#ai .job.job_03 {
  background: rgba(255, 255, 255, 0.85) url(/asset/img/ai_robot/job_03.png) 24px center no-repeat;
  background-size: 150px 150px;
}

#ai .job.job_04 {
  background: rgba(255, 255, 255, 0.85) url(/asset/img/ai_robot/job_04.png) 24px center no-repeat;
  background-size: 150px 150px;
}

#ai .job.job_04 .job--ttl {
  margin-bottom: 0;
}

#ai .job--ttl {
  color: #004098;
  font-weight: 900;
  margin-bottom: 0.5em;
  font-size: 1.85714rem;
  letter-spacing: 0.1em;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  #ai .job--ttl {
    text-align: center;
    font-size: 1.42857rem;
  }
}

#ai .job--ttl > span {
  font-size: 0.65em;
  display: inline-block;
  vertical-align: text-top;
}

#ai .job--text {
  font-size: 1.14286rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  #ai .job--text {
    font-size: 0.92857rem;
  }
}

@media screen and (min-width: 769px), print {
  #ai .ai_certificate {
    padding-top: 168px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_certificate {
    padding-top: 15%;
  }
}

#ai .ai_certificate_inner {
  display: block;
}

#ai .certificate_box {
  padding: 48px 40px;
  background: rgba(36, 179, 214, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.6);
  margin-bottom: 24px;
}

@media screen and (max-width: 768px) {
  #ai .certificate_box {
    padding: 24px 20px;
  }
}

#ai .certificate.c_eng h5::before {
  background: #9FC238;
}

#ai .certificate.c_elec h5::before {
  background: linear-gradient(to right, #2A7FC4 0%, #2A7FC4 50%, #C83A3F 50%, #C83A3F 100%);
}

#ai .certificate--ttl {
  display: inline-block;
  margin-bottom: 24px;
  color: #fff;
  font-size: 1.85714rem;
  margin-right: 40px;
  font-weight: 500;
  letter-spacing: 0.1em;
  padding-bottom: 4px;
}

@media screen and (max-width: 768px) {
  #ai .certificate--ttl {
    margin-right: 0;
    text-align: center;
    font-size: 1.28571rem;
  }
}

#ai .certificate--ttl > img {
  width: auto;
  height: 40px;
}

@media screen and (max-width: 768px) {
  #ai .certificate--ttl > img {
    height: 32px;
  }
}

#ai .certificate--ttl > span {
  display: inline-block;
  padding-left: 8px;
  padding-bottom: 4px;
  vertical-align: text-top;
}

#ai .certificate--ttl.ttl-engineer {
  background: linear-gradient(to bottom, transparent 0%, transparent 70%, #9FC238 70%, #9FC238 100%);
}

#ai .certificate--ttl.ttl-electronics {
  background: linear-gradient(to bottom, transparent 0%, transparent 70%, #2A7FC4 70%, #2A7FC4 100%);
}

#ai .certificate--ttl.ttl-electric {
  background: linear-gradient(to bottom, transparent 0%, transparent 70%, #C83A3F 70%, #C83A3F 100%);
}

#ai .certificate--text {
  color: #fff;
}

#ai .certificate--text li:not(:last-child) {
  margin-bottom: 32px;
}

#ai .certificate--text h5 {
  padding-left: 1.25em;
  font-size: 1.28571rem;
  line-height: 1.4;
  margin-bottom: 0.75em;
  position: relative;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  #ai .certificate--text h5 {
    font-size: 1.07143rem;
  }
}

#ai .certificate--text h5::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  width: 10px;
  height: 4px;
}

#ai .certificate--text h5 > span {
  font-size: 0.777777778em;
}

#ai .certificate--text p {
  font-size: 1.14286rem;
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  #ai .certificate--text p {
    font-size: 0.92857rem;
  }
}

@media screen and (min-width: 769px), print {
  #ai .ai_department {
    padding-top: 168px;
    padding-bottom: 132px;
  }
}

@media screen and (max-width: 768px) {
  #ai .ai_department {
    padding-top: 15%;
    padding-bottom: 12.5%;
  }
}

#ai .ai_department_inner {
  margin-left: -10px;
  margin-right: -10px;
}

#ai .department {
  border: 2px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  position: relative;
  padding: 63% 20px 24px;
  color: #fff;
}

#ai .department_wrap {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 10px;
  margin-bottom: 32px;
}

@media screen and (max-width: 768px) {
  #ai .department_wrap {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#ai .department.it_engineer {
  background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_01.jpg) top center no-repeat;
  background-size: contain;
}

#ai .department.it_engineer .department_title {
  background: url(/asset/img/ai_robot/bg_it_engineer.png) top left no-repeat;
  background-size: contain;
}

#ai .department.it_engineer .department_title_sub {
  color: #9FC238;
}

#ai .department.electronics {
  background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_02.jpg) top center no-repeat;
  background-size: contain;
}

#ai .department.electronics .department_title {
  background: url(/asset/img/ai_robot/bg_electronics.png) top left no-repeat;
  background-size: contain;
}

#ai .department.electronics .department_title_sub {
  color: #2A7FC4;
}

#ai .department.electric {
  background: rgba(0, 21, 51, 0.6) url(/asset/img/ai_robot/department_03.jpg) top center no-repeat;
  background-size: contain;
}

#ai .department.electric .department_title {
  background: url(/asset/img/ai_robot/bg_electric.png) top left no-repeat;
  background-size: contain;
}

#ai .department.electric .department_title_sub {
  color: #C83A3F;
}

#ai .department_title {
  font-size: 1.14286rem;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 30px;
  padding: 0 0.875em 0.15em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #ai .department_title {
    font-size: 1rem;
  }
}

#ai .department_title_sub {
  text-align: center;
  font-size: 1.14286rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 1.5em;
}

#ai .department_title_sub > span {
  display: inline-block;
}

#ai .department_image {
  text-align: center;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {
  #ai .department_image {
    width: 36%;
    margin: 0 auto 6% auto;
  }
}

#ai .department_text > li {
  font-size: 1rem;
  line-height: 1.714;
  text-indent: -1em;
  padding-left: 1em;
}

@media screen and (max-width: 768px) {
  #ai .department_text > li {
    font-size: 0.85714rem;
  }
}

#ai .ai_oc_inner {
  display: block;
  background: rgba(0, 21, 51, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 40px 40px;
}

@media screen and (max-width: 768px) {
  #ai .ai_oc_inner {
    padding: 24px 20px 32px;
  }
}

#ai .ai_oc .l-moreBtn {
  margin-top: 16px;
}

#ai .ai_oc .l-moreBtn .btn {
  font-weight: 500;
}

@media screen and (min-width: 769px), print {
  #ai .ai_oc .l-moreBtn .btn {
    min-width: 400px;
  }
}

#ai .oc--ttl {
  font-size: 1.28571rem;
  color: #fff;
  margin-bottom: 1em;
  letter-spacing: 0.1em;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  #ai .oc--ttl {
    font-size: 1.14286rem;
  }
}

#ai .oc--text {
  color: #fff;
  font-size: 1.14286rem;
  line-height: 1.75;
  margin-bottom: 1em;
}

@media screen and (max-width: 768px) {
  #ai .oc--text {
    font-size: 1rem;
  }
}

#ai .oc__photo {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

#ai .oc__photo figure {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 8px 16px;
}

@media screen and (max-width: 768px) {
  #ai .oc__photo figure {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#ai .oc__photo figure img {
  max-width: 100%;
  height: auto;
}

#ai .ai_column {
  background: #134861 url(/asset/img/ai_robot/bg_column.jpg) top center no-repeat;
  padding: 134px 0 140px 0;
}

@media screen and (max-width: 768px) {
  #ai .ai_column {
    padding: 10% 0 10% 0;
  }
}

#ai .ai_column .ai_column_title {
  text-align: center;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  #ai .ai_column .ai_column_title {
    height: 55px;
    width: auto;
    margin-bottom: 5%;
  }
  #ai .ai_column .ai_column_title img {
    height: 100%;
    width: auto;
  }
}

#disclosure .disclosure_list li {
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  padding-left: 18px;
}

#disclosure .disclosure_list li::before {
  content: '●';
  position: absolute;
  top: 5px;
  left: 0;
  font-size: 10px;
}

#disclosure .disclosure_list li a {
  background: url(/asset/img/common/ico_pdf.png) no-repeat;
  background-size: 20px;
  background-position: right center;
  padding-right: 25px;
}

#disclosure .disclosure_pdf {
  background: #ddd;
  margin-top: 60px;
  padding: 4%;
}

#disclosure .disclosure_pdf a {
  color: #004098;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  #disclosure .disclosure_pdf {
    font-size: 14px;
    margin-top: 30px;
  }
}

#entrance .l-contentRap {
  max-width: 1120px;
}

#entrance .textBlue {
  color: #004098;
}

#entrance .textWhite {
  color: #fff;
}

#entrance .textRed {
  color: #9a0001;
}

#entrance .text18Red {
  color: #9a0001;
  font-size: 1.28571rem;
}

#entrance .bgYellow {
  background-color: #f3e100;
}

#entrance .bgBlue {
  background-color: #004098;
}

#entrance .p-entranceRap {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap {
    flex-direction: column;
  }
}

#entrance .p-entranceRap .l-contentRap_space {
  width: 46%;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap .l-contentRap_space {
    width: 100%;
    margin-bottom: 5em;
  }
}

#entrance .p-entranceRap_box {
  min-height: 300px;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap_box {
    min-height: 320px;
  }
}

#entrance .p-entranceRap_ttl {
  margin-bottom: 50px;
  padding: 0.3em 0.3em 0;
  text-align: center;
  position: relative;
  font-size: 1.57143rem;
}

#entrance .p-entranceRap_ttl:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -20px;
  left: 0;
  border-style: solid;
  border-color: #004098 transparent transparent transparent;
  border-width: 20px 235px 0 235px;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap_ttl:after {
    border-width: 20px 47vw 0 47vw;
  }
}

#entrance .p-entranceRap_subttl {
  margin-bottom: 10px;
  font-size: 1.42857rem;
}

#entrance .p-entranceRap li {
  margin-bottom: 4px;
  margin-left: 5%;
  list-style: disc;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap li {
    margin-left: 6%;
    font-size: 1rem;
  }
}

#entrance .p-entranceRap_container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

#entrance .p-entranceRap_container:before {
  content: '';
  position: absolute;
  top: 32%;
  left: 8%;
  transform: translateX(-50%);
  width: 2px;
  height: 70%;
  background-color: #f3e100;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap_container:before {
    top: 24%;
    height: 82%;
  }
}

#entrance .p-entranceRap_container.lineBlue:before {
  background-color: #004098;
}

#entrance .p-entranceRap_container.last:before {
  background: none;
}

#entrance .p-entranceRap_container.last dl {
  height: auto;
}

#entrance .p-entranceRap dl {
  width: 80%;
  height: 230px;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap dl {
    width: 82%;
  }
}

#entrance .p-entranceRap dt {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  font-size: 1.71429rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap dt {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
    font-size: 1.28571rem;
  }
}

#entrance .p-entranceRap dd {
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap dd {
    font-size: 1rem;
  }
}

#entrance .p-entranceRap dd.p-entranceRap_danger {
  color: #9a0001;
  font-size: 1.28571rem;
}

#entrance .p-entranceRap_circle {
  width: 8%;
  position: absolute;
  top: 59%;
  left: 4%;
}

#entrance .p-entranceRap_circle img {
  width: 100%;
}

#entrance .p-entranceRap_icon {
  width: 16%;
}

#entrance .p-entranceRap_icon img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap_note {
    font-size: 0.8rem !important;
  }
}

#entrance .p-entranceRap_note_rev {
  margin-top: 1.5em;
  font-size: 1.71429rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  #entrance .p-entranceRap_note_rev {
    margin-top: 1.2em;
    font-size: 1.28571rem;
  }
}

#entrance .p-entranceRap_note_rev + dt {
  margin-top: 0;
}

#entrance .p-entranceRap_ttl.bgYellow:after {
  border-color: #f3e100 transparent transparent transparent;
}

#entrance-i .pointList {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #entrance-i .pointList {
    align-items: normal;
  }
}

#entrance-i .pointList dt {
  width: 76px;
  height: 76px;
  margin: 0 40px 0 0;
}

@media screen and (max-width: 768px) {
  #entrance-i .pointList dt {
    margin: 0 20px 0 0;
  }
}

#entrance-i .pointList dt img {
  width: 76px;
  height: 76px;
}

@media screen and (max-width: 768px) {
  #entrance-i .pointList dt img {
    width: 60px;
    height: 60px;
  }
}

#entrance-i .pointList dd {
  width: 100%;
}

.facility {
  max-width: 1020px;
  padding: 3%;
  margin: 30px 0;
  line-height: 1.6;
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
}

@media screen and (min-width: 769px), print {
  .facility {
    margin: 60px auto;
  }
}

@media screen and (max-width: 768px) {
  .facility {
    margin-bottom: 50px;
  }
}

.facility img {
  width: 100%;
}

.facility .textCenter {
  text-align: center;
}

.facility .textCenter:not(:first-of-type) {
  margin-bottom: 80px;
}

.facility .facility3colRap {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .facility .facility3colRap {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 769px), print {
  .facility .facility3colRap.rowReverse {
    flex-direction: row-reverse;
  }
}

.facility .facility3colRap .textRap {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .facility .facility3colRap .textRap {
    width: 100%;
  }
}

.facility .facility3colRap h4 {
  height: 40px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #004098;
  line-height: 40px;
  letter-spacing: 0.5px;
  border-radius: 2px;
  background-color: #f3e100;
  box-shadow: 3px 3px 3px #e5e5e5;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  .facility .facility3colRap h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .facility .facility3colRap p {
    margin-bottom: 30px;
  }
}

.facility .facility3colRap picture {
  width: 30%;
}

@media screen and (max-width: 768px) {
  .facility .facility3colRap picture {
    width: 48.5%;
    margin-top: 15px;
  }
}

.facility .facility2colRap {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap {
    margin-top: 56px;
  }
}

.facility .facility2colRap.change202303 .textRap {
  margin-bottom: 0 !important;
}

.facility .facility2colRap .textRap {
  width: 48%;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap .textRap {
    width: 100%;
    margin-bottom: 56px;
  }
}

.facility .facility2colRap .textRap.col1 {
  width: 100%;
  order: 1;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap .textRap.col1 {
    order: 0;
  }
}

.facility .facility2colRap h4 {
  height: 40px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #004098;
  line-height: 40px;
  letter-spacing: 0.5px;
  border-radius: 2px;
  background-color: #f3e100;
  box-shadow: 3px 3px 3px #e5e5e5;
  order: 2;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap h4 {
    margin-bottom: 20px;
    order: 1;
  }
}

.facility .facility2colRap p {
  order: 3;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap p {
    margin-bottom: 20px;
    order: 2;
  }
}

.facility .facility2colRap picture {
  margin-bottom: 30px;
  order: 1;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap picture {
    margin: 0;
    order: 3;
  }
}

.facility .facility2colRap picture.roompickup {
  border: solid 10px #f3e100;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .facility .facility2colRap picture.roompickup {
    margin: 0;
  }
}

.facility .facility2colAsy {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .facility .facility2colAsy {
    flex-wrap: wrap;
  }
}

.facility .facility2colAsy .textRap {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .facility .facility2colAsy .textRap {
    width: 100%;
  }
}

.facility .facility2colAsy h4 {
  height: 40px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #004098;
  line-height: 40px;
  letter-spacing: 0.5px;
  border-radius: 2px;
  background-color: #f3e100;
  box-shadow: 3px 3px 3px #e5e5e5;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  .facility .facility2colAsy h4 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .facility .facility2colAsy p {
    margin-bottom: 30px;
  }
}

.facility .facility2colAsy picture {
  width: 70%;
}

@media screen and (max-width: 768px) {
  .facility .facility2colAsy picture {
    width: 100%;
    order: 3;
  }
}

.facility .facilityRapBgBlue {
  background: #ebeef4;
}

@media screen and (min-width: 769px), print {
  .facility.onlineClasses {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.facility.onlineClasses .facility2colRap {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .facility.onlineClasses .facility2colRap {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 768px) {
  .facility.onlineClasses .facility2colRap .textRap {
    margin-bottom: 0;
  }
}

.facilityBgBlue {
  background: #ebeef4;
}

.facilityBgBlue img {
  width: 100%;
}

.facilityBgBlue .facilityInner {
  padding-top: 64px;
}

.facilityBgBlue .readText .textCenter {
  text-align: center;
  margin-bottom: 0;
}

.facilityBgBlue .readText .textCenter:last-of-type {
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .facilityBgBlue .readText .textCenter:last-of-type {
    margin-bottom: 30px;
  }
}

.facilityBgBlue .facility2colImg {
  margin: 30px 0;
}

@media screen and (min-width: 769px), print {
  .facilityBgBlue .facility2colImg {
    margin: 60px 0 80px 0;
  }
}

.facilityBgBlue .facility2colImg ul {
  width: 100%;
  display: flex;
  justify-content: center;
}

.facilityBgBlue .facility2colImg li {
  margin: 0 3%;
}

@media screen and (min-width: 769px), print {
  .facilityBgBlue .facility2colImg li {
    width: 24%;
  }
}

.facilityBgBlue .facility2colRap .textRap h4 {
  height: 40px;
  margin-bottom: 30px;
  /*padding-left: 40px;*/
  color: #004098;
  line-height: 40px;
  letter-spacing: 0.5px;
  border-radius: 2px;
  background-color: #f3e100;
  box-shadow: 3px 3px 3px #e5e5e5;
  font-size: 1.14286rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .facilityBgBlue .facility2colRap .textRap h4 {
    margin-bottom: 20px;
  }
}

.facilityBgBlue .facility2colRap .textRap h4 img {
  width: 24px !important;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.facilityBgBlue h3 {
  margin-bottom: 30px;
  color: #204f8e;
  text-align: center;
  font-size: 2.14286rem;
}

@media screen and (max-width: 768px) {
  .facilityBgBlue h3 {
    font-size: 1.28571rem;
  }
}

.facilityBgBlue .facility3colRap h4 {
  height: 40px;
  margin-bottom: 30px;
  padding-left: 40px;
  color: #004098;
  line-height: 40px;
  letter-spacing: 0.5px;
  border-radius: 2px;
  background-color: #f3e100;
  box-shadow: 3px 3px 3px #e5e5e5;
  font-size: 1.14286rem;
  position: relative;
}

@media screen and (max-width: 768px) {
  .facilityBgBlue .facility3colRap h4 {
    margin-bottom: 20px;
  }
}

#online .l-contInner {
  width: 940px;
  margin: 0 auto;
}

#online .mainBnr {
  width: 100%;
  position: relative;
}

#online .mainBnr img {
  width: 100%;
}

#online .mainBnr_txt {
  text-align: center;
  line-height: 1.5;
  position: absolute;
  top: 67%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  #online .mainBnr_txt {
    width: 90%;
    top: 70%;
    font-size: 0.57143rem;
  }
}

#online .mainTtl {
  text-align: center;
}

#online .mainTtl img {
  width: auto;
  height: 38px;
  margin: auto;
  margin: 80px auto 45px;
}

@media screen and (max-width: 768px) {
  #online .mainTtl img {
    height: 45px;
    margin: 35px auto 30px;
  }
}

#online .mainTtl02 {
  text-align: center;
}

#online .mainTtl02 img {
  width: auto;
  height: 38px;
  margin: auto;
  margin: 80px auto 45px;
}

@media screen and (max-width: 768px) {
  #online .mainTtl02 img {
    height: 25px;
    margin: 35px auto 30px;
  }
}

#online .container {
  width: 50%;
  padding: 80px 0 55px;
  background: url(/asset/img/online/bg_green.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  #online .container {
    width: 100%;
    padding: 45px 0 35px;
  }
}

#online .container:nth-of-type(2) {
  background: url(/asset/img/online/bg_blue.jpg);
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

#online .container .ttl02 {
  width: 100%;
  margin-bottom: 3em;
  text-align: center;
}

@media screen and (max-width: 768px) {
  #online .container .ttl02 {
    margin-bottom: 1.5em;
  }
}

#online .container .ttl02 img {
  width: auto;
  height: 35px;
}

@media screen and (max-width: 768px) {
  #online .container .ttl02 img {
    height: 28px;
  }
}

#online .container .item {
  padding: 0 6%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  max-width: 600px;
}

#online .container .item img {
  width: 170px;
}

@media screen and (max-width: 768px) {
  #online .container .item img {
    width: 30%;
    margin-top: 20px;
  }
}

#online .container .txt {
  margin-bottom: 0.5em;
  font-size: 1.07143rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .container .txt {
    font-size: 0.71429rem;
  }
}

#online .container .note {
  font-size: 1rem;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .container .note {
    margin-bottom: 1em;
    font-size: 0.71429rem;
  }
}

#online .balloon2-left {
  max-width: 380px;
  position: relative;
  display: inline-block;
  margin-left: 15px;
  padding: 10px 10px 30px;
  min-width: 120px;
  color: #555;
  font-size: 16px;
  background: #fff;
  border: solid 1px #e5e5e5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 3px 5px #e5e5e5;
  box-shadow: 0 3px 5px #e5e5e5;
}

#online .balloon2-left:before {
  content: '';
  position: absolute;
  top: 40px;
  left: -24px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid #fff;
  z-index: 2;
}

#online .balloon2-left:after {
  content: '';
  position: absolute;
  top: 40px;
  left: -30px;
  margin-top: -15px;
  border: 15px solid transparent;
  border-right: 15px solid #e5e5e5;
  z-index: 1;
}

#online .balloon2-left p {
  margin: 0;
  padding: 0;
}

#online .titleLabel03 {
  padding: 13px 0;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 {
    padding: 0 0 5px;
  }
}

#online .titleLabel03 span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span {
    padding: 5px;
    font-size: 1rem;
  }
}

#online .titleLabel03 span.green {
  border-left: 8px solid #4ecd00;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span.green {
    border-left: 6px solid #4ecd00;
  }
}

#online .titleLabel03 span.blue {
  border-left: 8px solid #509aff;
}

@media screen and (max-width: 768px) {
  #online .titleLabel03 span.blue {
    border-left: 6px solid #509aff;
  }
}

#online .ttl03 {
  max-width: 300px;
  margin: 0 auto;
  padding: 0.6em 0;
  color: #4ecd00;
  background-color: #fff;
  text-align: center;
  letter-spacing: 2px;
  border: 2px solid #4ecd00;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  position: relative;
  z-index: 2;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .ttl03 {
    width: 50%;
    font-size: 1rem;
  }
}

#online .ttl04 {
  max-width: 300px;
  margin: 0 auto;
  padding: 0.6em 0;
  color: #509aff;
  background-color: #fff;
  text-align: center;
  letter-spacing: 2px;
  border: 2px solid #509aff;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  position: relative;
  z-index: 2;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .ttl04 {
    width: 60%;
    font-size: 1rem;
  }
}

#online .l-col3 {
  width: 1120px;
  margin: 0 auto 35px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

@media screen and (max-width: 768px) {
  #online .l-col3 {
    width: 100%;
    margin-bottom: 10px;
  }
}

#online .l-col3Inner {
  width: 860px;
  margin: 0 auto;
  padding: 80px 0 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #4ecd00;
}

@media screen and (max-width: 768px) {
  #online .l-col3Inner {
    width: 70%;
    padding: 25px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

#online .l-col3 .cardThum {
  width: 12%;
  height: auto;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardThum {
    width: 18%;
  }
}

#online .l-col3 .cardThum01 {
  -webkit-transform: translate(20px, 35px);
  -ms-transform: translate(20px, 35px);
  transform: translate(20px, 35px);
}

#online .l-col3 .cardThum02 {
  -webkit-transform: translate(-15px, 50px);
  -ms-transform: translate(-15px, 50px);
  transform: translate(-15px, 50px);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardThum02 {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-transform: translate(-20px, -70px) scale(1.2);
    -ms-transform: translate(-20px, -70px) scale(1.2);
    transform: translate(-20px, -70px) scale(1.2);
  }
}

#online .l-col3Item {
  width: 33.3333%;
  padding: 0 3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col3Item {
    width: 70%;
    margin: 0 15%;
    padding: 0;
  }
}

#online .l-col3Item:not(:last-child) {
  border-right: 2px solid #c4c4c4;
}

@media screen and (max-width: 768px) {
  #online .l-col3Item:not(:last-child) {
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
  }
}

#online .l-col3 .cardTtl01 {
  width: 100%;
  margin: 0 auto 1em;
  padding: 0.8em 0;
  color: #fff;
  background-image: url(/asset/img/online/ttl_online_bg01.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01 {
    width: 100%;
    padding-bottom: 1.3em;
    margin-top: 20px;
    background-image: url(/asset/img/online/ttl_online_bg01-sp.png);
    background-size: contain;
    font-size: 0.85714rem;
  }
}

#online .l-col3 .cardTtl01.bgLight {
  background-image: url(/asset/img/online/ttl_online_bg02.png);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01.bgLight {
    background-image: url(/asset/img/online/ttl_online_bg02-sp.png);
  }
}

#online .l-col3 .cardTtl01.bgDefault {
  background-image: url(/asset/img/online/ttl_online_bg03.png);
}

@media screen and (max-width: 768px) {
  #online .l-col3 .cardTtl01.bgDefault {
    background-image: url(/asset/img/online/ttl_online_bg03-sp.png);
  }
}

#online .l-col4 {
  width: 1120px;
  margin: 0 auto;
  padding: 80px 40px 55px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 2px solid #509aff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col4 {
    width: 70%;
    padding: 25px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

#online .l-col4Item {
  width: 25%;
  padding: 0 3%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  #online .l-col4Item {
    width: 70%;
    margin: 0 15%;
    padding: 0;
  }
}

#online .l-col4Item:not(:last-child) {
  border-right: 2px solid #c4c4c4;
}

@media screen and (max-width: 768px) {
  #online .l-col4Item:not(:last-child) {
    border-right: none;
    border-bottom: 2px solid #c4c4c4;
  }
}

#online .l-col4 .cardTtl01 {
  width: 100%;
  margin: 0 auto 1em;
  padding: 0.8em 0;
  color: #fff;
  background-image: url(/asset/img/online/ttl_online_bg01.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01 {
    width: 75%;
    margin-top: 20px;
    padding-bottom: 1.3em;
    background-image: url(/asset/img/online/ttl_online_bg01-sp.png);
    font-size: 0.85714rem;
  }
}

#online .l-col4 .cardTtl01.bgLight {
  background-image: url(/asset/img/online/ttl_online_bg04.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgLight {
    background-image: url(/asset/img/online/ttl_online_bg04-sp.png);
  }
}

#online .l-col4 .cardTtl01.bgMid {
  background-image: url(/asset/img/online/ttl_online_bg05.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgMid {
    background-image: url(/asset/img/online/ttl_online_bg05-sp.png);
  }
}

#online .l-col4 .cardTtl01.bgDefault {
  background-image: url(/asset/img/online/ttl_online_bg06.png);
}

@media screen and (max-width: 768px) {
  #online .l-col4 .cardTtl01.bgDefault {
    background-image: url(/asset/img/online/ttl_online_bg06-sp.png);
  }
}

#online .cardTxt {
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  #online .cardTxt {
    font-size: 0.71429rem;
  }
}

#online .cardTxt:last-of-type {
  margin-bottom: 1.5em;
}

#online .cardNote {
  margin-top: 0.5em;
  line-height: 1.5;
  font-size: 0.85714rem;
}

@media screen and (max-width: 768px) {
  #online .cardNote {
    margin-bottom: 1em;
    font-size: 0.71429rem;
  }
}

#online .cardImg {
  width: 100%;
  margin: auto auto 0;
  display: block;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 768px) {
  #online .cardImg {
    width: 80%;
    margin-bottom: 20px;
  }
}

#online .l-moreBtn {
  margin-top: 70px;
  margin-bottom: 70px;
}

@media screen and (max-width: 768px) {
  #online .l-moreBtn {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

#online .entryBtn {
  width: 400px;
  min-height: 60px;
  border-radius: 30px;
  font-size: 1.28571rem;
}

@media screen and (max-width: 768px) {
  #online .entryBtn {
    width: 80%;
    padding-left: 0;
    padding-right: 0;
    font-size: 0.92857rem;
  }
}

#online .faq {
  padding-bottom: 100px;
  background: url(/asset/img/common/bg_stripe_yellow.png) repeat;
}

@media screen and (max-width: 768px) {
  #online .faq {
    padding-bottom: 50px;
  }
}

.accordionWrap {
  width: 1120px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  .accordionWrap {
    width: 100%;
  }
}

.accordion {
  width: 100%;
}

.accordionTtl {
  background-color: #ebeef4;
  padding: 1.2em 3.5em;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  font-size: 1.14286rem;
}

@media screen and (max-width: 768px) {
  .accordionTtl {
    font-size: 0.85714rem;
  }
}

.accordionTtl::after,
.accordionTtl::before {
  content: '';
  display: block;
  background-color: #004098;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.accordionTtl .icon:before {
  content: 'Q';
  width: 2em;
  height: 2em;
  color: #004098;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 1em;
  -webkit-transform: translateY(-50%) scale(1.5);
  -ms-transform: translateY(-50%) scale(1.5);
  transform: translateY(-50%) scale(1.5);
}

.accordionTtl::before {
  width: 20px;
  height: 2px;
  right: 2em;
}

@media screen and (max-width: 768px) {
  .accordionTtl::before {
    width: 14px;
  }
}

.accordionTtl::after {
  width: 2px;
  height: 20px;
  right: 2.55em;
  -webkit-transition: opacity 0.36s;
  -o-transition: opacity 0.36s;
  transition: opacity 0.36s;
}

@media screen and (max-width: 768px) {
  .accordionTtl::after {
    height: 14px;
    right: 2.4em;
  }
}

.accordionTtl.is-active::after {
  opacity: 0;
}

.accordionTxt {
  margin-bottom: 0.4em;
  background-color: #fff;
  padding: 0 3.5em;
  line-height: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: padding 0.3s, opacity 0.25s;
  -o-transition: padding 0.3s, opacity 0.25s;
  transition: padding 0.3s, opacity 0.25s;
}

@media screen and (max-width: 768px) {
  .accordionTxt {
    font-size: 0.85714rem;
  }
}

.accordionTxt.is-open {
  padding: 1.5em 3.5em;
  line-height: normal;
  height: auto;
  opacity: 1;
}

#courseDetailPage .international *,
#courseDetailPage .international *::before,
#courseDetailPage .international *::after {
  box-sizing: border-box;
}

#courseDetailPage .international .titleLabel01 {
  line-height: 1.6;
}

#courseDetailPage .international .titleLabel01_bottom {
  text-transform: uppercase;
}

#courseDetailPage .international .l-textLead {
  text-align: center;
  line-height: 1.75;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .l-textLead {
    font-size: 1.14286rem;
  }
}

#courseDetailPage .international .l-textLead > span {
  display: inline-block;
}

#courseDetailPage .international .features .feature .featureSubTitle .subTitleLabel > span {
  color: #004098;
}

#courseDetailPage .international section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1150px;
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .featureBlock {
    padding-top: 12.5%;
    padding-bottom: 20%;
  }
}

#courseDetailPage .international .featureBlock .features {
  max-width: initial;
  flex-wrap: wrap;
}

#courseDetailPage .international .featureBlock .feature {
  max-width: 100%;
}

#courseDetailPage .international .featureBlock .featureWrap {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100% !important;
  margin: 0 !important;
  height: 100%;
  background-image: url(/asset/img/common/bg_stripe_blue.png);
}

#courseDetailPage .international .featureBlock .featureTitle {
  color: #004098;
}

#courseDetailPage .international .featureBlock .featureTitle > span {
  border-color: #004098;
}

#courseDetailPage .international .featureBlock .featureTitle > span > span {
  display: inline-block;
}

#courseDetailPage .international .featureBlock.common .features {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .featureBlock.common .features {
    display: block;
  }
}

#courseDetailPage .international .featureBlock.common .featureWrap {
  max-width: 48%;
  background: none;
  margin-bottom: 30px !important;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .featureBlock.common .featureWrap {
    max-width: 94% !important;
    margin: 0 auto 6% !important;
  }
}

#courseDetailPage .international .featureBlock.common .features .feature {
  padding: 48px 28px;
  min-height: 370px;
}

#courseDetailPage .international .feature01 {
  max-width: 1157px;
  padding-left: 22px;
}

#courseDetailPage .international .feature01 .features {
  margin: 60px -10px 0;
}

#courseDetailPage .international .feature01 .featureBox {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 10px 72px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature01 .featureBox {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature01 .feature {
    padding: 8% 5% 5%;
  }
}

#courseDetailPage .international .feature01 .featureWrap {
  margin: 0 !important;
}

#courseDetailPage .international .feature01 .featureTitle {
  font-size: 1.42857rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature01 .featureTitle {
    font-size: 1.21429rem;
  }
}

#courseDetailPage .international .feature01 .iconImage img {
  width: 72px;
}

#courseDetailPage .international .feature01 dd {
  line-height: 1.75;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature01 dd {
    font-size: 0.92857rem;
  }
}

#courseDetailPage .international .feature01 dd > .y--border {
  background-image: linear-gradient(to bottom, transparent 0%, transparent 70%, #f3e100 70%, #f3e100 100%);
  background-repeat: no-repeat;
  background-size: 99% 100%;
}

#courseDetailPage .international .feature02 {
  max-width: 1157px;
  padding-left: 22px;
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature02 {
    overflow: hidden;
  }
}

#courseDetailPage .international .feature02 .titleLabel03 {
  margin-left: -4px;
}

#courseDetailPage .international .feature02 .features {
  margin: 0 0 60px;
}

#courseDetailPage .international .feature02 .feature {
  padding-right: 41.668%;
  position: relative;
  padding-bottom: 32px;
}

#courseDetailPage .international .feature02 .feature::after {
  content: '';
  position: absolute;
  width: 41.668%;
  padding-bottom: 32.079879565%;
  right: -1.5%;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url(/asset/img/international/feature_middle.png) top center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature02 .feature::after {
    right: -8.5%;
    top: auto;
    width: 71.668%;
    bottom: -15%;
    padding-bottom: 52.079879565%;
  }
}

#courseDetailPage .international .feature02 .result__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

#courseDetailPage .international .feature02 .result__list > li {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 10px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature02 .result__list > li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#courseDetailPage .international .feature02 .result__list > li > span {
  display: block;
  padding-left: 18px;
  text-indent: -18px;
  margin-bottom: 16px;
}

#courseDetailPage .international .feature02 .result__list > li > span::before {
  content: '●';
  color: #004098;
  font-size: 10px;
  padding-right: 8px;
}

#courseDetailPage .international .feature03 {
  max-width: 1157px;
  padding-left: 22px;
  padding-top: 20px;
}

#courseDetailPage .international .feature03 .titleLabel03 {
  margin-left: -4px;
}

#courseDetailPage .international .feature03 .features {
  margin: 0 -10px;
}

#courseDetailPage .international .feature03 .featureBox {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding: 0 10px 24px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .feature03 .featureBox {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#courseDetailPage .international .feature03 .feature dd {
  text-align: center;
}

#courseDetailPage .international .voiceBlock {
  background: #f2f5fa;
}

#courseDetailPage .international .voice01 {
  position: relative;
}

#courseDetailPage .international .voice01::before {
  content: '';
  position: absolute;
  width: 33.218%;
  padding-bottom: 26.000476437%;
  left: -3%;
  top: -140px;
  background: url(/asset/img/international/voice_top.png) top center no-repeat;
  background-size: contain;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice01::before {
    top: -6.25%;
    left: -7%;
    width: 45.218%;
    padding-bottom: 33.000476437%;
  }
}

#courseDetailPage .international .voice__wrapper {
  position: relative;
  margin-bottom: 80px;
}

#courseDetailPage .international .voice__wrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
}

#courseDetailPage .international .voice__wrapper.i_voice01::after {
  right: -4px;
  width: 256px;
  height: 543px;
  background: url(/asset/img/international/voice_01.png) top center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice01::after {
    width: 50%;
    right: -2%;
    height: auto;
    padding-bottom: 80.109375%;
    bottom: -4%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice__wrapper.i_voice01 dt {
    margin-right: 280px;
    text-align: right;
    padding-bottom: 40px;
  }
  #courseDetailPage .international .voice__wrapper.i_voice01 dt .cource {
    margin-right: 5.75em;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice01 dt {
    margin-top: -18%;
    margin-right: 45%;
    padding-left: 15px;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice__wrapper.i_voice01 dd {
    padding-right: 224px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice01 dd {
    padding-bottom: 68%;
  }
}

#courseDetailPage .international .voice__wrapper.i_voice02::after {
  left: -50px;
  width: 264px;
  height: 600px;
  background: url(/asset/img/international/voice_02.png) top center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice02::after {
    width: 38%;
    left: 0;
    height: auto;
    padding-bottom: 100.109375%;
    bottom: -4%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice__wrapper.i_voice02 dt {
    margin-left: 188px;
    padding-bottom: 64px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice02 dt {
    margin-top: -18%;
    margin-left: 38%;
  }
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice__wrapper.i_voice02 dd {
    padding-left: 216px;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice__wrapper.i_voice02 dd {
    padding-bottom: 78%;
  }
}

#courseDetailPage .international .voice {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice {
    padding: 0;
  }
}

#courseDetailPage .international .voice dt {
  padding-top: 20px;
  line-height: 1.2;
  position: relative;
}

#courseDetailPage .international .voice dt .cource {
  display: block;
  margin-bottom: 12px;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice dt .cource {
    font-size: 0.85714rem;
  }
}

#courseDetailPage .international .voice dt .name {
  font-size: 1.71429rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice dt .name {
    font-size: 1.42857rem;
  }
}

#courseDetailPage .international .voice dt .suffix {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice dt .suffix {
    font-size: 0.85714rem;
  }
}

#courseDetailPage .international .voice dd {
  flex: 0 0 100%;
  max-width: 100%;
  background: #fff;
  padding: 40px 48px;
  line-height: 1.75;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice dd {
    font-size: 1.14286rem;
  }
}

@media screen and (max-width: 768px) {
  #courseDetailPage .international .voice dd {
    padding: 20px 15px;
  }
}

#courseDetailPage .international .voice02 {
  padding-top: 40px;
  padding-bottom: 88px;
}

#courseDetailPage .international .voice02 .titleLabel02::after {
  background: #fff;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice02 .voice_inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}

#courseDetailPage .international .voice02 .voice__text {
  margin-bottom: 32px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice02 .voice__text {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
    font-size: 1.14286rem;
  }
}

#courseDetailPage .international .voice02 .voice__text h4 {
  margin-bottom: 1em;
  line-height: 1.4;
}

#courseDetailPage .international .voice02 .voice__text p {
  line-height: 1.75;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .voice02 .voice__photo {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }
}

#courseDetailPage .international .voice02 .voice__photo img {
  max-width: 100%;
  height: auto;
}

#courseDetailPage .international .qaBlock {
  padding-top: 80px;
  padding-bottom: 8px;
}

#courseDetailPage .international .qa__list {
  margin-bottom: 80px;
}

@media screen and (min-width: 769px), print {
  #courseDetailPage .international .qa__list {
    font-size: 1.14286rem;
  }
}

#courseDetailPage .international .qa__list dt,
#courseDetailPage .international .qa__list dd {
  position: relative;
  padding-left: 2.675rem;
}

#courseDetailPage .international .qa__list dt::before,
#courseDetailPage .international .qa__list dd::before {
  position: absolute;
  font-size: 2.14286rem;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  width: 1em;
  height: 1em;
  text-align: center;
  line-height: 1;
}

#courseDetailPage .international .qa__list dt {
  padding-top: 0.25em;
  margin-bottom: 32px;
  line-height: 1.4;
}

#courseDetailPage .international .qa__list dt::before {
  content: 'Q';
  color: #004098;
}

#courseDetailPage .international .qa__list dt::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #222222;
  left: 0;
  bottom: -20px;
}

#courseDetailPage .international .qa__list dd {
  line-height: 1.75;
}

#courseDetailPage .international .qa__list dd::before {
  content: 'A';
  color: #f3e100;
}
