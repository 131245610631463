#disclosure {
  .disclosure_list {
    & li {
      margin-bottom: 15px;
      font-size: 16px;
      position: relative;
      padding-left: 18px;
      &::before {
        content: '●';
        position: absolute;
        top: 5px;
        left: 0;
        font-size: 10px;
      }
      a {
        background: url(/asset/img/common/ico_pdf.png) no-repeat;
        background-size: 20px;
        background-position: right center;
        padding-right: 25px;
      }
    }
  }

  .disclosure_pdf {
    background: #ddd;
    margin-top: 60px;
    padding: 4%;
    & a {
      color: #004098;
      text-decoration: underline;
    }
    @include sp {
      font-size: 14px;
      margin-top: 30px;
    }
  }
}
