#coursePage {
  .l-titleSecondary {
    margin-bottom: 40px;
  }

  .courseBlock {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    @include pc() {
      &:hover {
        .courseImage {
          img {
            transform: scale(1.2);
          }
        }
      }
    }

    .course {
      &Image {
        overflow: hidden;
        img {
          transition: transform 0.3s;
          width: 100%;
        }
      }
      &Name {
        text-align: center;
        font-weight: bold;
        @include fontsize(16);
        @include pc() {
          margin-top: 30px;
          margin-bottom: 25px;
        }
        @include sp() {
          margin-top: 4%;
          margin-bottom: 4%;
        }
      }
      &Description {
        text-align: center;
        @include fontsize(14);
        @include pc() {
          margin-bottom: 40px;
        }
        @include sp() {
          margin-bottom: 5%;
        }
      }
    }

    &.information {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        @include pc() {
          @include triangle(30, $color-course-information);
        }
        @include sp() {
          @include triangle(20, $color-course-information);
        }
      }
      @include pc() {
        &:hover {
          color: $color-course-information;
        }
      }
      .courseName {
        color: $color-course-information;
      }
    }

    &.electronics {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        @include pc() {
          @include triangle(30, $color-course-electronics);
        }
        @include sp() {
          @include triangle(20, $color-course-electronics);
        }
      }
      @include pc() {
        &:hover {
          color: $color-course-electronics;
        }
      }
      .courseName {
        color: $color-course-electronics;
      }
    }

    &.electric {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
        @include pc() {
          @include triangle(30, $color-course-electric);
        }
        @include sp() {
          @include triangle(20, $color-course-electric);
        }
      }
      @include pc() {
        &:hover {
          color: $color-course-electric;
        }
      }
      .courseName {
        color: $color-course-electric;
      }
    }
  }
}

#courseDetailPage {
  .mainVisual {
    &-large {
      position: relative;
      display: flex;
      // justify-content: center;
      flex-direction: row;
      align-items: center;
      @include pc() {
        height: 420px;
      }
      @include sp() {
        height: 180px;
      }

      .mainVisual_titleBlock {
        margin: 0 auto;
        color: $color-white;
        .title-top,
        .title-bottom {
          text-align: center;
          display: block;
        }
        .title-top {
          font-weight: bold;
          @include pc() {
            @include fontsize(18);
            margin-bottom: 18px;
          }
          @include sp() {
            @include fontsizeVw(26);
            margin-bottom: 4%;
          }
        }
        .title-bottom {
          font-weight: bold;
          border-bottom: solid 1px $color-white;
          @include pc() {
            @include fontsize(30);

            padding-bottom: 10px;
            margin-bottom: 10px;
          }
          @include sp() {
            @include fontsizeVw(36);
            padding-bottom: 4%;
            margin-bottom: 4%;
          }
        }
        .title-sub {
          color: rgba($color-white, 0.6);
          @include pc() {
            @include fontsize(12);
          }
          @include sp() {
            @include fontsizeVw(26);
          }
        }
      }
      /*
      &.productDesign {
        background: url('/asset/img/course/it_engineer/it_engineer-06/mv_main_product_design@2x.jpg')
          no-repeat center center/cover;
        &--pcMaintenance {
          background: url('/asset/img/course/it_engineer/it_engineer-01/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--it {
          background: url('/asset/img/course/it_engineer/it_engineer-02/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--maintenance {
          background: url('/asset/img/course/it_engineer/it_engineer-03/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--web {
          background: url('/asset/img/course/it_engineer/it_engineer-04/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--graphic {
          background: url('/asset/img/course/it_engineer/it_engineer-05/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        .mainVisual_titleBlock {
          background-color: rgba($color-course-information, 0.8);
        }
      }

      &.electronics {
        &--robot {
          background: url('/asset/img/course/electronics/electronics-01/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--denshi {
          background: url('/asset/img/course/electronics/electronics-02/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--kaden {
          background: url('/asset/img/course/electronics/electronics-03/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--telecom {
          background: url('/asset/img/course/electronics/electronics-04/mv_main@2x.jpg') no-repeat
            center center/cover;
        }
        &--audio {
          background: url('/asset/img/course/electronics/electronics-05/mv_main@2x.jpg') no-repeat
            center center/cover;
        }

        .mainVisual_titleBlock {
          background-color: rgba($color-course-electronics, 0.8);
        }
      }
      &.electric {
        &--facility {
          background: url('/asset/img/course/electric/electric-01/mv_main@2x.jpg') no-repeat center
            center/cover;
        }

        .mainVisual_titleBlock {
          background-color: rgba($color-course-electric, 0.8);
        }
      }
    */
    }
    &.productDesign {
      .mainVisual_titleBlock {
        background: rgba($color-course-information, 0.85);
      }
    }
    &.electronics {
      .mainVisual_titleBlock {
        background-color: rgba($color-course-electronics, 0.8);
      }
    }
    &.electric {
      .mainVisual_titleBlock {
        background-color: rgba($color-course-electric, 0.8);
      }
    }
  }
  .l-textCatch.textCatch {
    color: $color-text !important;
  }
  .l-textLead {
    text-align: center;
    @include sp() {
      text-align: left;
    }
  }

  .featureBlock {
    background: #F4F4F4;
    //background: url(/asset/img/common/img_dot_pink.gif) repeat;
    &.electronics {
      //background: url(/asset/img/common/img_dot_light_blue.gif) repeat;
    }
    &.electric {
      //background: url(/asset/img/common/img_dot_green.gif) repeat;
    }

    line-height: 1.6;
    @include pc() {
      padding: 80px 0;
    }
    @include sp() {
      padding: 8% 0;
    }
  }

  .features {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @include sp() {
      flex-wrap: wrap;
    }
    &.column-4 {
      .featureWrap {
        @include pc() {
          max-width: 24%;
        }
      }
    }
    .featureWrap {
      //background-image: url(/asset/img/common/bg_stripe_pink.png);
      background-repeat: repeat;
      position: relative;
      @include pc() {
        max-width: 32%;
      }
      @include sp() {
        width: 94% !important;
        margin: 0 auto 6%;
      }
      &_image {
        display: flex;
        justify-content: space-between;
        @include sp() {
          flex-direction: column;
        }
        .featureBlock_image {
          width: 47.5%;
          background-image: url(/asset/img/common/bg_stripe_pink.png);
          background-repeat: repeat;
          position: relative;
          @include sp() {
            width: 100%;
          }
          &:not(:first-child) {
            @include sp() {
              margin-top: 16px;
            }
          }
          &.courseElectronics {
            background-image: url(/asset/img/common/bg_stripe_blue.png);
          }
          &.courseElectric {
            background-image: url(/asset/img/common/bg_stripe_green.png);
          }
        }
        img {
          width: 98.5%;
          padding: 0 4% 1.5% 0;
        }
        .featureBlock_icon {
          position: absolute;
          width: 8%;
          top: 0;
          left: 0;
          @include sp() {
            width: 10%;
          }
        }
      }
    }
    &.electronics {
      .featureWrap {
        //background-image: url(/asset/img/common/bg_stripe_light_blue.png);
      }
      .feature {
        .featureSubTitle .subTitleLabel > span {
          color: $color-course-electronics;
        }
        .featureTitle {
          color: $color-course-electronics;
          span {
            //border-bottom: solid 2px $color-course-electronics;
          }
        }
        dd{
          a{
            color: $color-course-electronics;
            text-decoration: underline;
            &:hover{
              text-decoration: none;
            }
          }
        }
      }
    }
    &.electric {
      .featureWrap {
        //background-image: url(/asset/img/common/bg_stripe_green.png);
        @include pc() {
          max-width: 24%;
        }
      }
      .feature {
        .featureSubTitle .subTitleLabel > span {
          color: $color-course-electric;
        }
        .featureTitle {
          color: $color-course-electric;
          span {
            //border-bottom: solid 2px $color-course-electric;
          }
        }
      }
    }
    .feature {
      //transform: translate(-4px, -4px);
      background-color: $color-white;
      position: relative;
      box-sizing: border-box;
      @include pc() {
        width: 100%;
        padding: 48px 28px;
        height: 100%;
      }
      @include sp() {
        padding: 7% 5%;
        height: 100%;
      }

      .featureSubTitle {
        margin: 0 auto 1em;
        text-align: center;
        .subTitleLabel {
          display: inline-block;
          font-weight: bold;
          @include pc() {
            @include fontsize(26);
          }
          @include sp() {
            @include fontsizeVw(40);
          }
          &::before,
          &::after {
            content: '―';
            display: inline-block;
            margin: 0 0.5em;
          }
          > span {
            color: $color-course-information;
          }
        }
      }
      .featureTitle {
        text-align: center;
        color: $color-course-information;
        font-weight: bold;
        margin-bottom: 14px;
        @include pc() {
          @include fontsize(32);
        }
        @include sp() {
          @include fontsizeVw(52);
          //@include fontsize(16);
        }
        > span {
          //border-bottom: solid 2px $color-course-information;
          //padding-bottom: 5px;
          display: inline-block;
        }
      }
      .iconImage {
        position: absolute;
        top: -42px;
        left: calc(50% - 36px);
        z-index: 1;
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: $color-white;
          position: absolute;
          top: 40px;
          transform: scaleX(1.5);
          z-index: -1;
        }
      }
      .image {
        width: 98%;
      }
      dd {
        @include pc() {
          @include fontsize(16);
        }
        a{
          color: $color-course-information;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }

  .curriculumBlock {
    &.courseElectronics {
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electronics;
        }
      }
      .curriculumTable {
        &:not(:last-of-type) {
          &::after {
            border-color: $color-course-electronics $color-course-electronics transparent transparent;
            @include sp() {
              border-color:  transparent transparent $color-course-electronics $color-course-electronics;
            }
          }
        }
      }
    }
    &.courseElectric {
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electric;
        }
      }
      .curriculumTable {
        &:not(:last-of-type) {
          &::after {
            border-color: $color-course-electric $color-course-electric transparent transparent;
            @include sp() {
              border-color:  transparent transparent $color-course-electric $color-course-electric;
            }
          }
        }
      }
    }

    line-height: 1.6;
    @include pc() {
      padding: 80px 0;
    }
    @include sp() {
      padding: 5% 3%;
    }

    .titleLabel02 {
      @include pc() {
        margin-bottom: 45px;
      }
      @include sp() {
        margin-bottom: 45px;
      }
      &::before {
        width: 28%;
      }
      &::after {
        background-color: $color-white;
      }
    }

    .curriculumBlock_inner {
      @include pc() {
        width: 1120px;
      }
      @include sp() {
        width: auto;
      }
      margin: 0 auto;
    }

    .l-curriculumTable {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include pc() {
        margin-bottom: 60px;
      }
      @include sp() {
        flex-direction: column;
        margin-bottom: 10%;
      }

      .table-note {
        @include pc() {
          @include fontsize(13);
          text-align: right;
        }
      }
    }
    .curriculumTable {
      position: relative;
      width: 100%;
      @include pc() {
        width: 47%;
      }
      @include sp() {
        margin-bottom: 60px;
      }
      &:last-of-type {
        @include sp() {
          margin-bottom: 0;
        }
      }
      &:not(:last-of-type) {
        &::after {
          content: '';
          position: absolute;
          bottom: calc(40% - 8px);
          right: -40px;
          width: 16px;
          height: 16px;
          border: 2px solid;
          border-color: $color-course-information $color-course-information transparent transparent;
          transform: rotate(45deg);
          @include sp() {
            bottom: -40px;
            right: calc(50% - 8px);
            border-color:  transparent transparent $color-course-information $color-course-information;
            transform: rotate(-45deg);
          }
        }
      }

      dt {
        width: 100%;
        padding-bottom: 0.5em;
        font-weight: bold;
        border-bottom: 2px solid #F4F4F4;
        background: transparent !important;

        @include pc() {
          @include fontsize(26);
          margin-bottom: 26px;
        }
        @include sp() {
          @include fontsizeVw(40);
          margin-bottom: 5%;
        }
      }
      dd {
        p {
          margin-bottom: 20px;
          @include pc() {
            @include fontsize(16);
            min-height: 5.0em;
          }
          @include sp() {
            min-height: auto;
          }
        }
        p.sub_category{
          min-height: auto;
          color: $color-course-information;
          margin-bottom: 10px;
        }
      }
      .smallTxt {
        color: #BF2221;
      }
    }

    .curriculumTitle {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .pickupBlock {
      .pickupBlock_image {
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 15px;
        @include sp() {
          margin-bottom: 3%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      .pickupBlock_content {
      }
      .pickupBlock_title01 {
      }
      .pickupBlock_title02 {
        @include fontsize(16);
        font-weight: bold;
        margin-bottom: 16px;
      }
    }

    &.courseInformation {
      .curriculumTable {
        dt {
          background-color: $color-course-information;
        }
      }
      .curriculumTitle {
        color: $color-course-information;
      }
      .titleLabel02 {
        &::before {
          background-color: $color-course-information;
        }
      }
      .titleLabel03 {
        span {
          //border-left: 8px solid $color-course-information;
        }
      }
      .pickupBlock_title02 {
        color: $color-course-information;
      }
    }
    &.courseElectronics {
      .curriculumTable {
        dt {
          background-color: $color-course-electronics;
        }
      }
      .curriculumTitle {
        color: $color-course-electronics;
      }
      .titleLabel02 {
        &::before {
          background-color: $color-course-electronics;
        }
      }
      .titleLabel03 {
        span {
          //border-left: 8px solid $color-course-electronics;
        }
      }
      .pickupBlock_title02 {
        color: $color-course-electronics;
      }
    }
    &.courseElectric {
      .curriculumTable {
        dt {
          background-color: $color-course-electric;
        }
      }
      .curriculumTitle {
        color: $color-course-electric;
      }
      .titleLabel02 {
        &::before {
          background-color: $color-course-electric;
        }
      }
      .titleLabel03 {
        span {
          //border-left: 8px solid $color-course-electric;
        }
      }
      .pickupBlock_title02 {
        color: $color-course-electric;
      }
    }
  }

  .sceneWrap {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @include sp() {
      flex-wrap: wrap;
      //margin-bottom: 8%;
    }
    .scene_images {
      @include pc {
        width: calc(100% - 58%);
      }
      @include sp {
        margin-bottom: 8%;
      }
      .scene {
        width: 100%;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .scene_pickup {
      position: relative;
      @include pc {
        width: 56%;
      }

      &.courseElectronics {
        h3 {
          background: linear-gradient(-45deg, transparent 10px, $color-course-electronics 0);
        }
      }
      &.courseElectric {
        h3 {
          background: linear-gradient(-45deg, transparent 10px, $color-course-electric 0);
        }
      }

      h3 {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        padding: 0.5em 2em;
        color: $color-white;
        background: linear-gradient(-45deg, transparent 10px, $color-course-information 0);
        @include fontsize(15);
        @include sp {
          @include fontsizeVw(30);
        }
      }
      h4 {
        margin-bottom: 0.5em;
        @include fontsize(15);
        @include sp {
          @include fontsizeVw(30);
        }
      }
    }
  }

  .voiceBlock {
    &.courseElectronics {
      background-color: rgba($color-course-electronics, 0.15);
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electronics;
        }
      }
      .voice_content {
        .titleLabel02 {
          &::before {
            background-color: $color-course-electronics;
          }
        }
        .titleLabel02_sub01 {
          color: $color-course-electronics;
        }
      }
    }

    &.courseElectric {
      background-color: rgba($color-course-electric, 0.15);
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electric;
        }
      }
      .voice_content {
        .titleLabel02 {
          &::before {
            background-color: $color-course-electric;
          }
        }
        .titleLabel02_sub01 {
          color: $color-course-electric;
        }
      }
    }

    background-color: rgba($color-course-information, 0.15);
    @include pc() {
      padding: 80px 0;
    }
    @include sp() {
      //margin: 0 -3%;
      padding: 10% 0;
    }

    .voiceBlock_inner {
      @include pc() {
        width: 1000px;
      }
      @include sp() {
        width: auto;
      }
      margin: 0 auto 40px;
      @include sp() {
        margin-bottom: 5%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .voice {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;

      @include sp() {
        flex-direction: column-reverse;
        padding: 0 4%;
      }
    }
    .voice_content {
      padding: 30px;
      background: $color-white;
      border-radius: 6px;
      @include pc() {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: calc(50% - 10px);
          left: -17px;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 17.3px 10px 0;
          border-color: transparent $color-white transparent transparent;

        }
      }
      @include sp() {
        padding: 8% 5%;
      }
      .titleLabel02 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        letter-spacing: normal;
        @include pc() {
          margin-bottom: 40px;
        }
        @include sp() {
          align-items: flex-start;
          margin-bottom: 10%;
        }
        &::before {
          display: none;
        }

        &_name {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: $color-text;

          @include pc() {
            margin: 0 16px;
            margin-left: 0;
          }
          @include sp() {
            margin-bottom: 2%;
            @include fontsizeVw(40);
            //@include fontsize(20);
          }
          span {
            align-self: flex-end;
            @include pc() {
              @include fontsize(14);
            }
            @include sp() {
              @include fontsizeVw(30);
              //@include fontsize(14);
            }
          }
        }
        &_sub01 {
          width: 100%;
          font-weight: bold;
          color: $color-course-information;
          @include pc() {
            @include fontsize(15);
            margin-bottom: 15px;
          }
          @include sp() {
            @include fontsizeVw(26);
            //@include fontsize(12);
            margin-bottom: 2%;
          }
        }
        &_sub02 {
          color: $color-text;

          @include pc() {
            @include fontsize(14);
          }
          @include sp() {
            @include fontsizeVw(26);
            //@include fontsize(12);
          }
        }
      }
    }
    .voice_title {
      color: $color-course-information;
      @include pc() {
        @include fontsize(16);
        font-weight: bold;
        margin-bottom: 18px;
      }
      @include sp() {
        margin-bottom: 3%;
      }
    }
    .voice_text {
      line-height: 1.6;
      @include pc() {
        @include fontsize(16);
      }
    }
    .voice_image {
      @include pc() {
        margin-right: 40px;
      }
      @include sp() {
        width: 50%;
        margin: 0 auto 6% auto;
      }

      img {
        border-radius: 50%;
      }
    }
  }

  .careerBlock {
    .titleLabel01 {
      @include sp() {
        padding-bottom: 50px;
      }
    }
    .titleLabel02 {
      color: $color-course-information;
      
      &:before{
        background:  $color-course-information;
      }
    }
    
    &.courseElectronics {
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electronics;
        }
      }

      .titleLabel02 {
        color: $color-course-electronics;
        &:before{
          background:  $color-course-electronics;
        }
      }
      .careerList {
        > dt {
          //color: $color-course-electronics;
        }
        > dd {
          background: $color-course-electronics;
        }
      }
    }
    &.courseElectric {
      .titleLabel01 {
        .titleLabel01_bottom {
          color: $color-course-electric;
        }
      }
      .titleLabel02 {
        color: $color-course-electric;
        &:before{
          background: $color-course-electric;
        }
      }
      .careerList {
        > dt {
          //color: $color-course-electric;
        }
        > dd {
          background: $color-course-electric;
        }
      }
    }
    @include pc() {
      padding-top: 80px;
    }
    @include sp() {
      padding-top: 10%;
    }

    .careerBlock_inner {
      @include pc() {
        //background-color: $color-course-information;
        padding-bottom: 60px;
      }
      @include sp() {
        margin-bottom: 10%;
        padding: 0 5%;
      }
    }

    .careerList {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      @include pc() {
        padding: 0;
        line-height: 1.6;
      }
      @include sp() {
        margin-bottom: 8%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      > dt {
        width: 100%;
        padding-bottom: 0.5em;
        font-weight: bold;
        border-bottom: 2px solid #F4F4F4;

        @include pc() {
          @include fontsize(26);
          margin-bottom: 26px;
        }
        @include sp() {
          @include fontsizeVw(40);
          margin-bottom: 5%;
        }
      }
      > dd {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 6px;
        margin-right: 5px;
        padding: 0 1em;
        line-height: 2em;
        color: $color-white;
        @include fontsize(10);
        border-radius: 10px;
        background: $color-course-information;
        @include pc() {
          width: auto;
        }
        @include sp() {
        }

        &::before {
          content: '●';
          display: none;
          font-size: 10px;
          color: $color-course-information;
          margin-right: 4px;
          transform: scale(0.6);
        }

        &.other {
          margin-left: 18px;
          @include pc {
            margin-left: -100px;
          }
          &::before {
            display: none;
          }
        }
      }
    }

    //2024年度更新 目指せる資格レイアウト変更
    .qualification-wrap{
      display: flex;
      flex-wrap: wrap;

      @include sp() {
        margin-top: 50px;
        margin-left: -5%;
        margin-right: -5%;
      }

      li{
        width: 31.5%;
        padding-left: 18px;
        text-indent: -18px;
        margin-bottom: 15px;
        line-height: 1.5;

        @include sp() {
          width: 100%;

          /*&:nth-of-type(odd){
            margin-right: 2%;
          }*/
        }

        &:before{
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $color-course-information;
          margin-right: 8px;
        }
      }
    }

    //2024年度更新 目指せる職業レイアウト変更
    .career-contents{
      margin-top: 80px;

      .career-list-wrap{
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;

        @include sp() {
          margin-top: 60px;
        }

        .career-list{
          width: 41.5%;
          margin-bottom: 30px;
          //background: #f2f7fa;
          background: rgba(159, 194, 56, .08);
          padding: 30px 40px;
          border-radius: 15px;
          margin-right: 2.5%;

          &:nth-of-type(even){
            margin-right: 0;
          }

          @include sp() {
            width: 100%;
            padding: 30px 25px;
            margin-right: 0;
          }

          dt{
            font-size: 1.5rem;
            color:  $color-course-information;
            margin-bottom: 1.0em;

            @include sp() {
              font-size: 1.15rem;
            }
          }

          dd{
            font-size: 1.14286rem;
            line-height: 1.6;

            @include sp() {
              font-size: 1.0rem;
            }
          }
        }
      }

    }

    &.courseElectronics {
      .qualification-wrap{
        li{
          &:before{
            background: $color-course-electronics;
          }
        }
      }

      .career-contents{
        .career-list-wrap{
          .career-list{
            background: #f2f7fa;
            dt{
              color: $color-course-electronics;
            }
          }
        }
      }
    }

    &.courseElectric {
      .qualification-wrap{
        li{
          &:before{
            background: $color-course-electric;
          }
        }
      }

      .career-contents{
        .career-list-wrap{
          .career-list{
            background-color: rgba(200, 58, 63, .08);
            dt{
              color: $color-course-electric;
            }
          }
        }
      }
    }
  }

  .l-stripeBox_outer {
    @include pc() {
      width: 1120px;
      margin: 0 auto;
    }
  }
  .l-stripeBox {
    padding: 0;

    .l-stripeBoxInner {
      @include pc() {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;

        > dl {
          width: 48%;
        }
      }
      @include sp() {
        padding: 0 5%;
      }
    }
  }
  // ここから追加した分
  .l-contentTitle {
    text-align: center;
    padding-bottom: 64px;
    padding-top: 120px;
    @include sp() {
      padding-bottom: 7%;
      padding-top: 12%;
    }
    .contentTitle {
      color: $color-pale-pink;
      @include fontsize(30);
      font-weight: normal;
      position: relative;
      display: inline-block;
      padding: 0 10px;
      z-index: 0;
      margin-bottom: 23px;
      letter-spacing: 0.12em;
      @include sp() {
        @include fontsizeVw(36);
        margin-bottom: 4%;
      }
      &::after {
        position: absolute;
        display: block;
        content: '';
        background-image: url(/asset/img/common/bg_stripe_yellow.png);
        background-repeat: repeat;
        height: 14px;
        width: 100%;
        bottom: -3px;
        left: 0;
        z-index: -1;
        @include sp() {
          height: 8px;
        }
      }
    }
    .contentTitle-sub {
      font-weight: bold;
      letter-spacing: 0.12em;
      @include fontsize(16);
      @include sp() {
        @include fontsizeVw(22);
      }
    }
    .titleLabel01 {
      padding-bottom: 0;

      &_bottom {
        color: $color-course-information;
      }
    }
  }
  .l-newsInner {
    padding-bottom: 80px;
    @include sp() {
      padding-bottom: 8%;
    }
  }
  .news-item-category {
    color: $color-course-information;
    border: 1px solid $color-course-information;
  }
  .courseElectronics .l-contentTitle .titleLabel01_bottom {
    color: $color-course-electronics;
  }
  .courseElectric .l-contentTitle .titleLabel01_bottom {
    color: $color-course-electric;
  }
  .courseElectronics .news-item-category {
    color: $color-course-electronics;
    border: 1px solid $color-course-electronics;
  }
  .courseElectric .news-item-category {
    color: $color-course-electric;
    border: 1px solid $color-course-electric;
  }
}

#courseDetailPage {
  // ボタン
  .btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 50px;
    border: solid 1px $color-course-information;
    color:$color-course-information;
    @include fontsize(14);
    background-color: $color-white;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    z-index: 0;
    @include sp() {
      @include fontsizeVw(28);
      min-width: auto;
      min-height: auto;
      padding: 3% 15%;
    }

    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      _background: url(/asset/img/common/ico_arrow_next_pink.svg) no-repeat center center;
      _background-size: 35%;
      background: none;
      border-top: 2px solid $color-course-information;
      border-right: 2px solid $color-course-information;
      position: absolute;
      _top: calc(50% - 10px);
      right: 7%;
      top: 40%;
      /*transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);*/
      transform: rotate(45deg);
    }

    @include pc() {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        content: '';
        width: 120%;
        height: 100%;
        background: $color-course-information;
        transform-origin: right top;
        transform: skewX(-30deg) scale(0, 1);
        transition: transform 0.3s;
      }
      &.selected,
      &:hover {
        color: $color-white;
        &::after {
          _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
          _background-size: 35%;
          background: none;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
        &::before {
          transform-origin: left top;
          transform: skewX(-30deg) scale(1, 1);
        }
      }
    }

    &.btnRound {
      border-radius: 25px;
    }
  }
  .l-buttonLayout {
    margin: 0 auto;
    text-align: center;
    padding: 12px 56px 0;
    @include sp {
      padding-top: 48px;
      padding-bottom: 32px;
    }
    .btn {
      width: 100%;
      max-width: 800px;
      @include pc() {
        @include fontsize(16);
        &.selected,
        &:hover {
          border-color: #fff;
        }
      }
      @include sp {
        max-width: 274px;
        padding: 3% 8% 4% 4.5%;
      }
      &::after {
        border-color: transparent;
        width: 26px;
        height: 26px;
        top: calc(50% - 15px);
        transform: rotate(0);
        right: 2%;
        @include sp() {
          width: 20px;
          height: 20px;
          top: calc(50% - 13px);
        }
      }
    }
  }
  .courseInformation .l-buttonLayout {
    background: $color-pale-pink;
  }
  // .courseInformation .btn {
  //   border: solid 1px $color-pale-pink;
  //   color: $color-pale-pink;
  //   &::after {
  //     background: url(/asset/img/common/ico_arrow_next_pink.svg) no-repeat center center;
  //   }
  //   @include pc() {
  //     &::before {
  //       background: $color-pale-pink;
  //     }
  //   }
  // }
  .courseElectronics .l-buttonLayout {
    background: $color-course-electronics;
  }
  .courseElectronics .btn {
    border: solid 1px $color-course-electronics;
    color: $color-course-electronics;
    &::after {
      _background: url(/asset/img/common/ico_arrow_next_blue.svg) no-repeat center center;
      background: none;
      border-top: 2px solid $color-course-electronics;
      border-right: 2px solid $color-course-electronics;
    }
    @include pc() {
      &::before {
        background: $color-course-electronics;
      }
      &.selected,
      &:hover {
        color: $color-white;
        &::after {
          _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
          background: none;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
    }
  }
  .courseElectric .l-buttonLayout {
    background: $color-course-electric;
  }
  .courseElectric .btn {
    border: solid 1px $color-course-electric;
    color: $color-course-electric;
    &::after {
      _background: url(/asset/img/common/ico_arrow_next_green.svg) no-repeat center center;
      background: none;
      border-top: 2px solid $color-course-electric;
      border-right: 2px solid $color-course-electric;
    }
    @include pc() {
      &::before {
        background: $color-course-electric;
      }
      &.selected,
      &:hover {
        color: $color-white;
        &::after {
          _background: url(/asset/img/common/ico_arrow_next_white.svg) no-repeat center center;
          background: none;
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
        }
      }
    }
  }
  // カード
  & .news {
    padding: 0 3%;
  }
  .news-link:hover {
    color: $color-course-information;
  }
  .courseElectronics .news-link:hover {
    color: $color-course-electronics;
  }
  .courseElectric .news-link:hover {
    color: $color-course-electric;
  }

  // 時間割テーブル
  .scrollWrap {
    margin-bottom: 0;
    @include sp() {
      overflow-x: scroll;
    }
  }
  .table {
    width: 100%;
    text-align: center;
    background-color: $color-white;
    @include sp() {
      min-width: 720px;
    }
    th span,
    td span {
      display: inline-block;
      @include fontsize(10);
      line-height: 1.4em;
      @include sp() {
        // @include fontsize(12);
        @include fontsizeVw(20);
      }
    }
    th.time{
      font-size: 0.7rem;
      border-top: 2px solid #fff;
    }
    th {
      height: 43px;
      color: $color-white;
      background-color: $color-course-information;
      border-right: 2px solid #fff;
      vertical-align: middle;
      @include fontsize(18);
      @include sp() {
        // height: 80px;
        height: 4em;
        @include fontsizeVw(24);
      }

      span {
        @include fontsize(21);
        margin-right: 2px;
        @include sp() {
          @include fontsizeVw(32);
        }
      }
    }

    td {
      //height: 96px;
      //margin: 10px;
      color: $color-text;
      background: #f4f4f4;
      // background-color: rgba($color-pale-pink, 0.15);
      // background: url(/asset/img/common/bg_stripe_pink.png) repeat;
      vertical-align: middle;
      border-top: solid 2px $color-white;
      border-left: solid 2px $color-white;
      // position: relative;
      @include sp() {
        // height: 80px;
        // height: 64px;
        height: 2.5em;
      }

      &.bgBlank {
        background: $color-white;
      }
      .bgDark {
        border-radius: 10px;
        color: $color-white;
        background-color: $color-course-information;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 3px * 2);
        height: calc(100% - 3px * 2);
        margin: 3px;
      }
      .bgLine {
        //border-radius: 10px;
        //background-color: rgba($color-pale-pink, 0.05);
        //background-image: url(/asset/img/common/bg_stripe_pink_light.png);
        //background-size: 5px;
        //background-repeat: repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        //width: calc(100% - 3px * 2);
        //height: calc(100% - 3px * 2);
        height: 100%;
        //margin: 3px;
      }
    }
    .labelTop {
      @include fontsize(18);
      @include sp() {
        // @include fontsize(16);
        @include fontsizeVw(24);
      }
      &:last-child {
       // width: 10%;
       @include sp() {
         width: 15%;
       }
      }
      &:not(:last-child) {
        width: 15%;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
      }
    }
    .labelLeft {
      border-top: 2px solid $color-white;
      border-left: 2px solid $color-white;
      @include sp() {
        height: 5em;
      }
    }
    &-scrollNote {
      @include fontsize(12);
      margin-bottom: 5px;
    }
    &-note {
      margin-top: 5px;
      text-align: right;
      @include fontsize(12);

      @include sp() {
        @include fontsize(10);
        text-align: left;
      }
    }
  }
  .courseElectric .table th {
    background-color: $color-course-electric;
  }
  .courseElectric .table td {
    color: $color-course-electric;
  }
  .courseElectric td .bgLine {
    background-color: rgba($color-pale-green, 0.05);
    //background-image: url(/asset/img/common/bg_stripe_green_light.png);
  }
  .courseElectronics .table th {
    background-color: $color-course-electronics;
  }
  .courseElectronics .table td {
    color: $color-course-electronics;
  }
  .courseElectronics td .bgLine {
    background-color: rgba($color-pale-green, 0.05);
    //background-image: url(/asset/img/common/bg_stripe_blue_light.png);
  }

  #course {
    background: #f2f7fa;
  }
}

#courseDetailPage .courseElectric .table td{
  width: 19%;
}


/*******講師紹介追加********/
#courseDetailPage{
  .teacherBlock{
    background: #f2f7fa;
    padding: 80px 0 120px;

    .teacher-list{
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      @include sp() {
        padding: 0 5%;
      }
      
      .teacher-plof{
        width: 47%;
        margin-right: 6%;
        margin-bottom: 80px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include sp() {
          width: 100%;

          &:nth-of-type(odd){
            margin-right: 0;
          }
        }

        &:nth-of-type(even){
          margin-right: 0;
        }

        .teacher-pic{
          width: 30%;

          @include sp() {
            margin: 0 auto 25px;
            width: 40%;
          }

          img{
            max-width: 100%;
            border-radius: 50%;
          }
        }

        .about{
          width: 65%;

          @include sp() {
            width: 100%;
          }

          dt{
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 1.0rem;
            border-bottom: 1px solid #9fc238;
            padding-bottom: 15px;

            @include sp() {
              font-size: 1.25rem;
              text-align: center;
            }
          }

          dd{
            font-size: 1.0rem;
            line-height: 1.6;
          }

          .sns-list{
            display: flex;
            flex-wrap: wrap;
            margin-top: 25px;

            @include sp() {
              justify-content: center;
            }

            li{
              width: 45px;
              height: 45px;
              margin-right: 15px;
              transition: all .3s ease;

              &:last-of-type(){
                margin-right: 0;
              }

              &:hover{
                opacity: 0.8;
              }

              &.insta{
                background: url(/asset/img/common/icon_insta.png) no-repeat;
                background-size: contain;
              }

              &.twitter{
                background: url(/asset/img/common/icon_x.png) no-repeat;
                background-size: contain;
              }

              &.fb{
                background: url(/asset/img/common/icon_fb.png) no-repeat;
                background-size: contain;
              }

              &.note{
                background: url(/asset/img/common/icon_note.png) no-repeat;
                background-size: contain;
              }

              &.youtube{
                background: url(/asset/img/common/icon_youtube.png) no-repeat;
                background-size: contain;
              }

              &.web{
                background: url(/asset/img/common/icon_web_g.png) no-repeat;
                background-size: contain;
              }

              a{
                display: block;
                width: 100%;
                height: 100%;
                transition: all .3s ease;

                &:hover{
                  opacity: 0.8;
                }
              }
              
            }
          }
        }
      }

    }
  }
}

/****各種コースのみの設定***/

#courseDetailPage{

  /*プロダクトコース、Webデザイン、ITコース*/
  .it_engineer06,
  .it_engineer04,
  .it_engineer02{
    .careerBlock{
      padding-top: 40px;
      padding-bottom: 100px;
    }
  }

}
