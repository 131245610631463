#courseDetailPage .international {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .titleLabel01 {
    line-height: 1.6;
  }

  .titleLabel01_bottom {
    text-transform: uppercase;
  }

  .l-textLead {
    text-align: center;
    line-height: 1.75;
    @include pc() {
      @include fontsize(16);
    }
    > span {
      display: inline-block;
    }
  }

  .features .feature .featureSubTitle .subTitleLabel>span{
    color: $color-blue;
  }

  section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1150px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .featureBlock {
    @include sp {
      padding-top: 12.5%;
      padding-bottom: 20%;
    }
    .features {
      max-width: initial;
      flex-wrap: wrap;
    }
    .feature {
      max-width: 100%;
    }
    .featureWrap {
      @include flexChild;
      width: 100% !important;
      margin: 0 !important;
      height: 100%;
      background-image: url(/asset/img/common/bg_stripe_blue.png);
    }
    .featureTitle {
      color: $color-blue;
      > span {
        border-color: $color-blue;
        > span {
          display: inline-block;
        }
      }
    }
  }

  .featureBlock{
    &.common{
      .features {
        display: flex;
        justify-content: space-between;
        max-width: 1200px;
        margin: 0 auto;
        @include sp() {
          display: block;
        }
      }

      .featureWrap{
        max-width: 48%;
        background: none;
        margin-bottom: 30px !important;

        @include sp() {
          max-width: 94% !important;
          margin: 0 auto 6% !important;
        }
      }

      .features .feature{
        padding: 48px 28px;
        min-height: 370px;
      }

    }
  }

  .feature01 {
    max-width: 1157px;
    padding-left: 22px;
    .features {
      margin: 60px -10px 0;
    }
    .featureBox {
      @include flexChild(50%);
      padding: 0 10px 72px;
      @include sp() {
        @include flexChild();
      }
    }
    .feature {
      @include sp() {
        padding: 8% 5% 5%;
      }
    }
    .featureWrap {
      margin: 0 !important;
    }

    .featureTitle {
      @include fontsize(20);
      @include sp() {
        @include fontsize(17);
      }
    }

    .iconImage {
      img {
        width: 72px;
      }
    }
    dd {
      line-height: 1.75;
      @include sp() {
        @include fontsize(13);
      }

      > .y--border {
        background-image: linear-gradient(
          to bottom,
          transparent 0%,
          transparent 70%,
          $color-yellow 70%,
          $color-yellow 100%
        );
        background-repeat: no-repeat;
        background-size: 99% 100%;
      }
    }
  }

  .feature02 {
    max-width: 1157px;
    padding-left: 22px;
    padding-top: 20px;
    @include sp {
      overflow: hidden;
    }
    .titleLabel03 {
      margin-left: -4px;
    }
    .features {
      margin: 0 0 60px;
    }
    .feature {
      padding-right: 41.668%;
      position: relative;
      padding-bottom: 32px;

      &::after {
        content: '';
        position: absolute;
        width: 41.668%;
        padding-bottom: 32.079879565%;
        right: -1.5%;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url(/asset/img/international/feature_middle.png) top center no-repeat;
        background-size: contain;
        @include sp {
          right: -8.5%;
          top: auto;
          width: 71.668%;
          bottom: -15%;
          padding-bottom: 52.079879565%;
        }
      }
    }

    .result__list {
      @include flex;
      margin: 0 -10px;

      > li {
        @include flexChild(percentage(1/3));
        @include sp() {
          @include flexChild();
        }
        padding: 0 10px;
        > span {
          display: block;
          padding-left: 18px;
          text-indent: -18px;
          margin-bottom: 16px;
          &::before {
            content: '●';
            color: $color-blue;
            font-size: 10px;
            padding-right: 8px;
          }
        }
      }
    }
  }

  .feature03 {
    max-width: 1157px;
    padding-left: 22px;
    padding-top: 20px;
    .titleLabel03 {
      margin-left: -4px;
    }
    .features {
      margin: 0 -10px;
    }
    .featureBox {
      @include flexChild(percentage(1/3));
      padding: 0 10px 24px;
      @include sp() {
        @include flexChild();
      }
    }
    .feature {
      dd {
        text-align: center;
      }
    }
  }

  .voiceBlock {
    background: $color-white-smoke;

    > section {
      // @include sp {
      //   overflow: hidden;
      // }
    }
  }

  .voice01 {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 33.218%;
      padding-bottom: 26.000476437%;
      left: -3%;
      top: -140px;
      background: url(/asset/img/international/voice_top.png) top center no-repeat;
      background-size: contain;
      z-index: 1;
      @include sp {
        top: -6.25%;
        left: -7%;
        width: 45.218%;
        padding-bottom: 33.000476437%;
      }
    }
  }

  .voice__wrapper {
    position: relative;
    margin-bottom: 80px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
    }
    &.i_voice01 {
      &::after {
        right: -4px;
        width: 256px;
        height: 543px;
        background: url(/asset/img/international/voice_01.png) top center no-repeat;
        background-size: contain;
        @include sp {
          width: 50%;
          right: -2%;
          height: auto;
          padding-bottom: 80.109375%;
          bottom: -4%;
        }
      }

      dt {
        @include pc {
          margin-right: 280px;
          text-align: right;
          padding-bottom: 40px;
          .cource {
            margin-right: 5.75em;
          }
        }
        @include sp {
          margin-top: -18%;
          margin-right: 45%;
          padding-left: 15px;
        }
      }
      dd {
        @include pc {
          padding-right: 224px;
        }
        @include sp {
          padding-bottom: 68%;
        }
      }
    }
    &.i_voice02 {
      &::after {
        left: -50px;
        width: 264px;
        height: 600px;
        background: url(/asset/img/international/voice_02.png) top center no-repeat;
        background-size: contain;
        @include sp {
          width: 38%;
          left: 0;
          height: auto;
          padding-bottom: 100.109375%;
          bottom: -4%;
        }
      }
      dt {
        @include pc {
          margin-left: 188px;
          padding-bottom: 64px;
        }
        @include sp {
          margin-top: -18%;
          margin-left: 38%;
        }
      }
      dd {
        @include pc {
          padding-left: 216px;
        }
        @include sp {
          padding-bottom: 78%;
        }
      }
    }
  }

  .voice {
    @include flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    @include sp {
      padding: 0;
    }
    dt {
      padding-top: 20px;
      line-height: 1.2;
      position: relative;
      .cource {
        display: block;
        margin-bottom: 12px;
        @include sp {
          @include fontsize(12);
        }
      }
      .name {
        @include fontsize(24);
        @include sp {
          @include fontsize(20);
        }
      }
      .suffix {
        @include fontsize(14);
        @include sp {
          @include fontsize(12);
        }
      }
    }
    dd {
      @include flexChild;
      background: #fff;
      padding: 40px 48px;
      @include pc {
        @include fontsize(16);
      }
      line-height: 1.75;
      @include sp {
        padding: 20px 15px;
      }
    }
  }

  .voice02 {
    padding-top: 40px;
    padding-bottom: 88px;
    .titleLabel02 {
      &::after {
        background: #fff;
      }
    }
    .voice {
      &_inner {
        @include pc() {
          @include flex;
          margin: 0 -15px;
        }
      }
      &__text {
        @include pc() {
          @include flexChild(50%);
          padding: 0 15px;
          @include fontsize(16);
        }
        margin-bottom: 32px;
        h4 {
          margin-bottom: 1em;
          line-height: 1.4;
        }
        p {
          line-height: 1.75;
        }
      }
      &__photo {
        @include pc() {
          @include flexChild(50%);
          padding: 0 15px;
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .qaBlock {
    padding-top: 80px;
    padding-bottom: 8px;
  }

  .qa__list {
    @include pc() {
      @include fontsize(16);
    }
    margin-bottom: 80px;
    dt,
    dd {
      position: relative;
      padding-left: 2.675rem;
      &::before {
        position: absolute;
        @include fontsize(30);
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        width: 1em;
        height: 1em;
        text-align: center;
        line-height: 1;
      }
    }

    dt {
      padding-top: 0.25em;
      margin-bottom: 32px;
      line-height: 1.4;
      &::before {
        content: 'Q';
        color: $color-blue;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: $color-black;
        left: 0;
        bottom: -20px;
      }
    }
    dd {
      line-height: 1.75;
      &::before {
        content: 'A';
        color: $color-yellow;
      }
    }
  }
}
