/* ==================================
レイアウト
===================================== */
.l-full {
  width: 100%;
  margin: 0 auto;
}

.l-spinner {
  width: 95%;
  margin: 0 auto 10vw;

  @include pc {
    width: 100%;
    margin: 0;
  }
}

.l-wrap {
  max-width: 100%;
  margin: 0 auto 50px;

  @include pc {
    max-width: 1080px;
  }

  &Inner {
    @extend .l-wrap;
    margin: 0 auto;
  }
}

.l-wrapColor {
  padding: 50px 0 50px;

  @include pc {
    max-width: 1080px;
  }
}

.contentbox {
  border: 1px solid;
  margin-top: 40px;
}

.news-items {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  @include pc() {
    justify-content: space-between;
    &::after {
      content: '';
      display: block;
      width: 31.4%;
    }
  }
  @include sp() {
    flex-direction: column;
  }
  .news-item {
    width: 31.4%;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    @include pc() {
      margin-top: 35px;
      &:nth-of-type(-n + 3) {
        margin-top: 0;
      }
      &:hover {
        .news-item-thum {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    @include sp() {
      width: 100%;
      margin-bottom: 3%;
    }
    a {
      display: block;
      height: 100%;
    }

    .news-item-thum {
      overflow: hidden;
      height: 164px;
      img {
        transition: transform 0.3s;
        width: 100%;
      }
    }
    .news-item-body {
      padding: 20px;
      @include sp() {
        padding: 4% 3%;
      }
    }
    .news-item-category {
      @include fontsize(10);
      color: $color-blue;
      display: inline-block;
      border: solid 1px $color-blue;
      padding: 3px 11px;
      margin-bottom: 5px;
      margin-right: 3px;
      border-radius: 50px;
      &:last-child {
        margin-right: 0;
      }
      @include sp() {
        @include fontsizeVw(26);
        padding: 0.5% 2%;
      }
      &::before {
        content: '#';
      }
    }
    .news-item-date {
      @include fontsize(12);
      color: $color-demi-gray;
      margin-bottom: 16px;
      @include sp() {
        @include fontsizeVw(26);
        margin-bottom: 2.5%;
      }
    }
    .news-item-title {
      @include fontsize(14);
      font-weight: bold;
      margin-bottom: 16px;
      line-height: 1.5714;
      @include sp() {
        @include fontsizeVw(28);
        margin-bottom: 2.5%;
      }
    }
    .news-item-text {
      line-height: 1.5714;
      position: relative;
      height: calc(2em * 1.5714);
      margin-bottom: 16px;
      overflow: hidden;
      @include sp() {
        @include fontsizeVw(28);
        margin-bottom: 2.5%;
      }
    }
  }
}

.l-wrapError {
  padding: 50px 20px;
  text-align: center;
  line-height: 1.5;
  @include pc {
    max-width: 1080px;
    margin: 0 auto;
    font-size: 16px;
    padding: 100px 20px;
  }

  &_title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 20px;
    color: $color-blue;
    @include pc {
      font-size: 28px;
    }
  }
}

/* PCのみに効かせるやつ___________________________ */
@include pc {
}

/* ____________________________________________ */

/* SPのみに効かせるやつ___________________________ */
@include sp {
}

/* ____________________________________________ */
