#opencampus {
  .mainVisual {
    max-width: 100%;
    min-height: 64vw;
    background: url('/asset/img/opencampus/kv@2x.jpg') no-repeat center center/cover;
    background-size: cover;
    &::before {
      display: none;
    }
    @include pc {
      min-height: 420px;
      background: url('/asset/img/opencampus/kv.jpg') no-repeat center center/cover;
    }
    &_title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      text-align: center;
      color: $color-blue;
      letter-spacing: 5px;
      @include fontsize(21);
      line-height: 1.4;
      width: 100%;
      @include pc {
        @include fontsize(30);
      }
      img {
        width: 100%;
      }
      top: 60%;
      max-width: 180px;
      @include pc {
        max-width: 475px;
      }
    }
  }
  //

  .opencampusDays {
    background: #F2F7FA;
    padding: 8% 3%;
    @include pc {
      padding: 50px 0;
    }
    &_box {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      max-width: 1200px;
      @include pc {
        margin: 0 auto;
      }
      li {
        background: #fff;
        text-align: center;
        box-shadow: 0px 3px 3px 2px #ddd;
        margin: 0 1% 20px;
        width: 100%;
        @include pc {
          width: 31%;
          min-height: 300px;
        }
        a {
          display: block;
          padding: 20px;
          box-sizing: border-box;
          @include pc {
            display: flex;
            height: 100%;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: center;
            padding: 20px 30px 30px;
          }
        }

        .daysTxt {
          font-size: 45px;
          font-weight: 500;
          margin-bottom: 15px;
          line-height: 1em;
          text-align: center;
          font-family: $font-family-num;

          span {
            display: inline-block;
            margin-left: 10px;
            color: #004098;
            font-size: 24px;
          }
        }
        .daySubtxt {
          margin-bottom: 1em;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
        }
        h3 {
          text-align: left;
          margin-bottom: 1em;
          line-height: 1.5em;
          @include sp {
            text-align: center;
          }
        }
        p.op_text {
          margin-bottom: 1.5em;
          line-height: 1.5em;
          text-align: left;
          @include sp {
            display: none;
          }
        }
        .buttonLabel {
          display: inline-block;
          min-width: 160px;
          margin: 0 auto;
          padding: 0.5em 1em;
          @include pc {
            font-size: 14px;

            &::after {
              top: 10px;
            }
          }
        }
        &.personal {
          background: #FFFABE;

          .personal-ttl {
            margin-bottom: 10px;
            font-size: 28px;
            font-weight: bold;
            text-align: center;

            span {
              display: block;
              margin-bottom: 1em;
              font-size: 18px;
              color: #004098;

              &::before,
              &::after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 1.5em;
                margin: 0.2em 0.5em 0;
                background: #004098;
                transform: rotate(-25deg);
                transform-origin: center bottom;
              }
              &::after {
                transform: rotate(25deg);
              }
            }
          }
        }
      }
    }
    .l-buttonLayout {
      margin-top: 30px;
      @include pc {
        width: 25%;
        margin-top: 50px;
      }
    }
  }
  //

  .boxInner {
    position: relative;
    margin: 0 auto;
  }

  .programBox {
    margin: 0 auto;
    padding: 8% 0;
    @include pc {
      padding: 80px 0;
    }
    .boxInner {
      margin: 0 auto 5%;
      padding: 8% 3%;
      @include pc {
        margin: 0 auto 80px;
        padding: 80px 0;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        background: rgba($color-course-information, 0.15);
        @include sp {
          width: 80%;
        }
      }

      &.courseElectronics {
        &::before {
          left: auto;
          right: 0;
          background: rgba($color-course-electronics, 0.15);
        }
        .programBox__txt {
          .txtTl {
            color: $color-course-electronics;
            &_top {
              &::before,
              &::after {
                background: $color-course-electronics;
              }
            }
          }
        }
      }
      &.courseElectric {
        &::before {
          background: rgba($color-course-electric, 0.15);
        }
        .programBox__txt {
          .txtTl {
            color: $color-course-electric;
            &_top {
              &::before,
              &::after {
                background: $color-course-electric;
              }
            }
          }
        }
      }
    }

    .programBox {
      &__item {
        position: relative;
        max-width: 1200px;
        margin: 0 auto 62px auto;
        overflow: hidden;
        @include sp() {
          display: flex;
          justify-content: space-between;
          width: auto;
          flex-direction: column-reverse;
          padding: 0 3%;
          margin: 0 auto 10% auto;
        }
        &:last-child() {
          margin-bottom: 0;
        }

        @include pc() {
          &:nth-of-type(odd) {
            .programBox__txt {
              left: 0;
              right: auto;
            }
            .programBox__img {
              float: right;
            }
          }
        }
      }
      &__txt {
        position: relative;
        width: 600px;
        background: $color-white;
        box-sizing: border-box;
        z-index: 1;
        @include pc() {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          min-height: 80%;
          padding: 50px 40px;
        }
        @include sp() {
          width: 94%;
          margin: -8% auto 0;
          padding: 8% 5%;
        }

        .txtTl {
          color: $color-course-information;
          text-align: center;
          &_top {
            display: block;
            margin-bottom: 1em;
            font-size: 28px;
            font-weight: normal;
            @include sp() {
              font-size: 20px;
            }

            &::before,
            &::after {
              content: '';
              display: inline-block;
              width: 1em;
              height: 1px;
              background: $color-course-information;
            }
          }
          &_bottom {
            display: block;
            margin-bottom: 1em;
            font-size: 32px;
            @include sp() {
              font-size: 26px;
            }
          }
        }
        .textDesk {
          line-height: 1.5em;
        }
      }
      &__img {
        width: 680px;
        @include pc() {
          float: left;
          margin-top: 60px;
        }
        @include sp() {
          width: auto;
          margin: 0 -3%;
        }
      }

      &__cyuki {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
  //

  .privilegeBox {
    background: #f2f7fa;
    padding: 8% 0;
    line-height: 1.6;
    overflow: hidden;
    @include pc {
      padding: 80px 0;
    }
    .features {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;

      .feature {
        background-color: #fff;
        position: relative;
        box-sizing: border-box;
        padding: 7% 5%;
        height: 100%;

        @include pc {
          width: 100%;
          padding: 48px 28px;
          height: 100%;
        }
      }
      .featureWrap {
        width: 94%!important;
        margin: 0 0.5% 6%;
        @include pc {
          max-width: 32%;
        }
      }
      .featureSubTitle {
        margin: 0 auto 1em;
        text-align: center;
        .subTitleLabel {
          display: inline-block;
          font-weight: bold;
          @include pc() {
            @include fontsize(26);
          }
          @include sp() {
            @include fontsizeVw(40);
          }
          &::before,
          &::after {
            content: '―';
            display: inline-block;
            margin: 0 0.5em;
          }
          > span {
            color: $color-blue;
          }
        }
      }
      .featureTitle {
        text-align: center;
        color: $color-blue;
        font-weight: bold;
        margin-bottom: 14px;
        @include pc() {
          @include fontsize(32);
        }
        @include sp() {
          @include fontsizeVw(52);
        }
        > span {
          display: inline-block;
        }
      }
    }
    .featureTitle--support {
      font-size: 32px;
      @include sp {
        font-size: 26px;
      }
      span {
        border: none;
      }
    }
  }
  //

  .scheduleBox {
    padding: 8% 3%;
    @include pc() {
      padding: 80px 0;
    }
    &__inner {
      @include pc() {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 0 auto;
      }
    }
    &__item {
      position: relative;
      margin: 0 0 60px;
      @include pc() {
        width: 29%;
        margin: 0 2%;
      }
      &:not(:last-child) {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -7.5%;
          display: inline-block;
          width: 15px;
          height: 15px;
          border: 0px;
          border-top: solid 2px $color-text;
          border-right: solid 2px $color-text;
          transform: rotate(45deg);
          @include sp() {
            top: auto;
            bottom: -30px;
            right: 50%;
            transform: rotate(135deg);
          }
        }
      }
    }
    &__pic {
      margin-bottom: 20px;
      img {
        width: 100%;
        height: auto;;
      }
    }
    .scheduleBox__txt {
      margin-bottom: 0.5em;
      color: $color-blue;
      font-size: 20px;
      font-weight: bold;

      span {
        font-family: $font-family-num;
      }
    }
    p {
      margin-bottom: 1em;
      font-size: 16px;
      line-height: 1.5em;
    }
    .scheduleBox__detail {
      h2 {
        margin-bottom: 0.5em;
        color: $color-blue;
        font-size: 20px;
        font-weight: bold;
        font-family: $font-family-num;
      }
    }
  }
  //

  .opencampusBnr {
    background: url(/asset/img/opencampus/bg.jpg) no-repeat;
    padding: 5% 3%;
    background-size: cover;
    background-position: center;
    @include pc {
      padding: 80px 0;
    }

    .l-buttonLayout {
      margin-top: 30px;
      @include pc {
        width: 25%;
        margin-top: 50px;
      }
    }
    img {
      width: 100%;
    }

    &__tl {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: $color-blue;
      @include pc {
        font-size: 28px;
      }
    }

    &__pic01 {
      position: relative;
    }
  }
  //

  .time_tt {
    background: $color-blue;
    position: absolute;
    top: 0px;
    left: 0;
    padding: 2px 20px;
    color: #fff;
    border-radius: 10px 0 0 0;
    @include pc {
      display: none;
    }
  }


  .daysTxt {
    font-size: 46px;
    font-weight: bold;
    font-family: $font-family-num;
    line-height: 1em;

    span {
      display: inline-block;
      margin-left: 0.5em;
      font-size: 0.6em;
      line-height: 1em;
    }
  }

  .oc_info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 730px;
    margin: 0 auto 50px;
    @include sp {
      flex-direction: row;
      justify-content: center;
      max-width: 100%;
      margin: 5% auto;
    }
    .oc_date {
      position: relative;
      margin-bottom: 5%;
      padding-bottom: 5%;
      @include pc {
        width: 35%;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 2em);
        display: inline-block;
        width: 4em;
        height: 1px;
        background: #000;
        @include pc {
          display: none;
        }
      }

      .daysTxt {
        margin-bottom: 15px;
        font-size: 58px;

        span {
          color: $color-blue;
        }
      }
      .daySubtxt {
        font-weight: bold;
      }
    }
    .oc_title{
      @include pc {
        width: 60%;
      }
      h3 {
        @include sp {
          text-align: center;
        }
      }
    }
    .oc_body {
      width: 100%;
      margin-top: 8%;
      @include pc {
        margin-top: 40px;
        text-align: center;

        &.column2 {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > div {
            margin: 0;
            width: 47%;
          }
        }
      }
      > div {
        width: 100%;
        margin: 0 auto 20px;
        text-align: left;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .oc_text {
      color: #555555;
    }
  }
}
//
