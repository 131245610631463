#future-creation {
  .mainVisual {
    max-width: 100%;
    background: url('/asset/img/future_creation/kv.jpg') no-repeat center center/cover;
    background-size: cover;
    padding-bottom: 17.4%;
    background-position: center;
    min-height: 175px;
    &::before {
      display: none;
    }

    &_title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      text-align: center;
      color: $color-blue;
      letter-spacing: 5px;
      @include fontsize(21);
      line-height: 1.4;
      width: 100%;
      @include pc {
        @include fontsize(30);
      }

      span {
        display: block;
        max-width: 40%;
        margin: 0 auto 10px auto;
        color: $color-white;
        background: $color-blue;
        @include fontsize(12);
        border-radius: 30px;
        padding: 5px 20px 7px 20px;
        letter-spacing: 3px;
        @include pc {
          @include fontsize(14);
          max-width: 14%;
        }
      }
    }

    &_title {
      color: #333;
      letter-spacing: 5px;
      span {
        background: none;
        display: inline-block;
        font-size: initial;
        padding: 0;
        letter-spacing: 5px;
        max-width: auto;
        font-size: 1.2rem;
        color: #f3e100;
        @include pc {
          font-size: 2.14286rem;
        }
      }
      .futureImg {
        width: 60%;
        max-width: 820px;
        margin: 0 auto 20px auto;
        display: block;
        @include pc {
          width: 30%;
        }
      }
    }
  }
  //

  .futureTxt {
    text-align: center;
    h3 {
      font-size: 22px;
      color: $color-blue;
      margin-bottom: 20px;
      @include pc {
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
  }

  .futureBg {
    @include pc {
      background: url('/asset/img/future_creation/bg01.jpg') no-repeat;
      background-size: cover;
      background-position: 83%;
      padding: 10% 0;
    }
    &--img02 {
      @include pc {
        background: url('/asset/img/future_creation/bg02.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }
    &--img03 {
      @include pc {
        background: url('/asset/img/future_creation/bg03.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }
    &--img04 {
      @include pc {
        background: url('/asset/img/future_creation/bg04.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }
    &--img05 {
      @include pc {
        background: url('/asset/img/future_creation/bg05.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }
    &--img06 {
      @include pc {
        background: url('/asset/img/future_creation/bg06.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }
    &--img07 {
      @include pc {
        background: url('/asset/img/future_creation/bg07.jpg') no-repeat;
        background-size: cover;
        background-position: 83%;
      }
    }

    @include pc {
      background-position: top center;
      padding: 100px 0;
    }
    img {
      width: 100%;
    }
  }
  .futureName {
    background: #f3e100;
    color: $color-blue;
    display: flex;
    padding: 3%;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    @include pc {
      max-width: 650px;
      width: 80%;
      padding: 30px 0 0 30px;
      margin: -20px 10% 0 0;
    }
    &--right {
      @include pc {
        display: flex;
        justify-content: flex-start;
      }
      .futureName {
        @include pc {
          margin: -20px 0 0 10%;
        }
      }
    }
    &--left {
      @include pc {
        display: flex;
        justify-content: flex-end;
      }

      &.no-movie{
        margin-top: 200px;
        @include sp{
          margin-top: 0;
        }
      }
    }


    &__img {
      width: 4%;
      max-width: 23px;
      padding-right: 3%;
      @include pc {
        padding-right: 20px;
        padding-bottom: 30px;
      }
    }
    &__txt {
      border-left: 1px solid #fff;
      padding-left: 3%;
      @include pc {
        padding: 0 0 30px 20px;
      }
    }
    &__company {
      font-size: 12px;
      margin-bottom: 10px;
      @include pc {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    &__name {
      font-size: 24px;
      @include pc {
        font-size: 30px;
      }
      .middle {
        font-size: 18px;
        @include pc {
          font-size: 24px;
        }
      }
      .small {
        font-size: 14px;
        display: block;
        margin-top: 10px;
        @include pc {
          margin-top: 0;
          display: inline-block;
        }
      }
    }
  }
  .futureRap {
    padding: 3% 3% 10% 3%;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    line-height: 1.6;
    @include pc {
      background: rgba(255, 255, 255, 0.8);
      margin: -20px 10% 0 0;
      padding: 50px 40px 40px 40px;
      max-width: 650px;
      width: 80%;
      box-shadow: 2px 3px 16px -8px #000000;
    }
    &--right {
      @include pc {
        margin: -20px 0 0 10%;
      }
    }

    .small {
      font-size: 12px;
    }
    h4 {
      font-size: 20px;
      margin-bottom: 10px;
      @include pc {
        margin-bottom: 20px;
        font-size: 24px;
      }
      span {
        color: #f3e100;
        text-shadow: 2px 2px 3px #bbb;
      }
    }

    &__flow {
      background: $color-blue;
      margin: 10px auto 0 auto;
      color: #fff;
      font-size: 12px;
      width: 300px;

      @include pc {
        display: flex;
        padding: 20px;
        width: auto;
      }
      li {
        position: relative;
        padding: 3%;
        margin-bottom: 60px;
        @include pc {
          padding: 0;
          margin-right: 50px;
          margin-bottom: 0;
          min-height: 135px;
        }
        &::after {
          content: '';
          position: absolute;
          top: 25px;
          right: 10px;
          width: 140px;
          height: 140px;
          margin-left: 45%;
          transform-origin: left bottom;
          box-sizing: border-box;
          border-left: solid 4px #fff;
          border-bottom: solid 4px #fff;
          transform: rotate(-46deg) skew(28deg, 28deg);

          @include pc {
            content: '';
            position: absolute;
            top: -19px;
            right: -125px;
            width: 90px;
            height: 90px;
            transform-origin: left bottom;
            box-sizing: border-box;
            border-left: solid 4px #fff;
            border-bottom: solid 4px #fff;
            transform: rotate(-130deg) skew(23deg, 23deg);
          }
        }
        &:last-child {
          margin-bottom: 0;
          @include pc {
            margin-right: 0;
          }
          &::after {
            display: none;
          }
        }

        h5 {
          font-size: 14px;
          text-align: center;
          margin-bottom: 20px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            background: #f3e100;
            width: 12%;
            height: 3px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto auto -8px auto;
          }
        }
      }
    }
    //
  }
  //.futureRap
  .futureMovie {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  .futureMovie iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
//
