html {
  overflow-x: hidden;
  @include pc() {
    font-size: $baseFont + px;
  }
  @include sp() {
    font-size: 100%;
  }
}
body {
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', '游ゴシック', 'Yu Gothic', YuGothic,
    'メイリオ', Meiryo, Verdana, sans-serif;
  position: relative;

  @include pc {
    //min-width: 1120px;
    font-size: 14px;
  }

  &.is-fixed {
    width: 100%;
    height: 100%;
    overflow: hidden;
    //z-index: -1;
  }
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo, Verdana, sans-serif;
  }
}

@include sp() {
  img {
    width: 100%;
    height: auto;
  }
}

a {
  //display: block;
  color: $color-text;
  text-decoration: none;

  @include pc {
    transition: all 0.3s ease;

    &:hover {
      color: $color-blue;
      opacity: 1;
    }
  }
}

button {
  @include pc() {
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

input[type='text'],
textarea {
  @include fontsize(16);
  padding: 8px 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-weight: normal;
  width: auto;
  border-radius: 4px;
  @include sp() {
    width: 100%;
  }
  &.wide {
    width: 600px;
    @include sp() {
      width: 100%;
    }
  }
  &.narrow {
    width: 3em;
  }
}

select {
  &.selectBox {
    @include fontsize(16);
    height: 40px;
    padding: 8px 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-right: 0.5em;
    width: 200px;
    position: relative;
    @include sp() {
      width: auto;
    }
  }
}

.appeal {
  color: $color-red;
}
