.formTable {
  @include fontsize(16);

  .formTitle {
    background-color: $color-light-gray;
    padding: 10px;
    @include fontsize(16);
    font-weight: bold;
    @include pc() {
      padding-left: 2em;
    }
  }

  .formContent {
    padding: 10px;
    margin-bottom: 30px;
    @include pc() {
      padding-left: 2em;
    }
  }

  .formError {
    padding: 10px;
    color: $color-red;
    @include pc() {
      padding-left: 2em;
    }
  }

  .formContentInner {
    display: flex;
    align-items: center;

    @include sp() {
      flex-direction: column;
    }

    &.row {
      @include sp() {
        flex-direction: row;

        .formItem {
          display: flex;

          &:last-child {
            .inputLabel {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.column {
      flex-direction: column;
      align-items: flex-start;

      .formItem {
        margin-right: 0;
        margin-bottom: 10px;

        .inputLabel {
          width: 120px;
        }
      }
    }

    .formItem {
      display: flex;
      align-items: center;
      margin-right: 20px;

      @include sp() {
        display: block;
        width: 100%;
        margin-right: 0;
      }

      > label {
        display: flex;
        align-items: center;

        input[type='checkbox'],
        input[type='radio'] {
          margin-right: 6px;
        }
      }
      .inputLabel {
        margin-right: 20px;
      }
    }
  }
}

.l-receiptBox {
  display: flex;
  justify-content: space-between;
  //margin-top: 20px;

  @include sp() {
    flex-direction: column;
  }
}

.receiptBox {
  width: 30%;

  @include sp() {
    width: 100%;
    margin-bottom: 5%;
  }

  dt {
    border-bottom: solid 1px $color-black;
    font-weight: bold;
    @include fontsize(16);
    padding-bottom: 4px;
    margin-bottom: 6px;
  }
}

.thanks_box {
  text-align: center;

  &__tl {
    font-size: 35px;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}

/* checkbox */
.formTable .mwform-checkbox-field {
  width: 30%;
  padding: 0;
  margin: 0 0 8px;
  display: inline-block;

  @include sp() {
    width: 100%;
  }
}

.checkboxlist {
  b {
    margin-bottom: 0.6em;
  }

  & + .checkboxlist {
    margin-top: 1em;
  }
}
