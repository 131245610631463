#teacher {
  .mainVisual {
    background: url('/asset/img/teacher/mv.jpg') no-repeat center center/cover;
    background-size: cover;
  }

  .teacherTitle {
    text-align: center;
    h2 {
      font-size: 30px;
      color: $color-blue;
      margin-bottom: 35px;
    }
  }

  .featureBlock{
    background: #F2F7FA;
  }
  .features .feature .featureTitle,
  .features .feature .featureSubTitle .subTitleLabel>span{
    color: $color-blue;
  }

  .teacherPoint {
    justify-content: center;
  }
  .teacherPointBox {
    position: relative;
    max-width: 1120px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .subTitleLabel span{
      color: $color-blue;
    }
    .featureTitle{
      color: $color-blue;
    }

    &__pic01 {
      display: none;
      @include pc {
        display: block;
        position: absolute;
        bottom: 0;
        left: -10px;
        width: 12.5%;
      }
    }
    &__pic02 {
      display: none;
      @include pc {
        display: block;
        position: absolute;
        bottom: 0;
        right: -10px;
        width: 14%;
      }
    }
  }

  .featureWrap:first-child{
    margin-right: 1%;
  }
  //

  .teacherVoice {
    padding: 5% 0;
    background: #f2f7fa;
    @include pc {
      padding: 80px 0;
    }
    &__rap {
      max-width: 1120px;
      margin: 0 auto;
    }

    &__box {
      padding: 50px 20px 20px 20px;
      box-sizing: border-box;
      line-height: 1.6;
      position: relative;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column-reverse;
      @include pc {
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      &__course {
        color: $color-course-information;
        font-weight: bold;

        &--02 {
          color: $color-course-electronics;
        }
        &--03 {
          color: $color-course-electric;
        }
      }

      &__l {
        background: #fff;
        padding: 30px;
        border-radius: 10px;
        @include pc {
          width: 75%;
        }
      }
      &__l:after{
        content: none;
        @include pc {
          border: solid transparent;
          content:'';
          height:0;
          width:0;
          pointer-events:none;
          position:absolute;
          border-color: rgba(255, 255, 255, 0);
          border-top-width:12px;
          border-bottom-width:12px;
          border-left-width:12px;
          border-right-width:18px;
          margin-top: -12px;
          border-right-color:#fff;
          left:18%;
          top:50%;
        }
      }
      &__r {
        width: 80%;
        margin: 0 auto 20px;
        @include pc {
          //width: 16%;
          //position: relative;
          position: absolute;
          width: 16%;
          top: 20%;
          left: 0;
        }
        img {
          width: 100%;
        }
      }
      &__tl {
        color: $color-course-information;
        font-weight: bold;
        margin-bottom: 10px;
        @include pc {
          font-size: 18px;
        }
        &--02 {
          color: $color-course-electronics;
        }
        &--03 {
          color: $color-course-electric;
        }
      }
      &__name {
        font-weight: bold;
        margin-bottom: 20px;
        border-bottom: 2px solid #F4F4F4;
        padding-bottom: 15px;
        span {
          font-size: 18px;
        }
      }
    }
    //
  }
}
//teacher
