// 入学までの流れ
#entrance {
  .l-contentRap {
    max-width: 1120px;
  }
  .textBlue {
    color: $color-blue;
  }
  .textWhite {
    color: $color-white;
  }
  .textRed {
    color: $color-red;
  }
  .text18Red {
    color: $color-red;
    @include fontsize(18);
  }
  .bgYellow {
    background-color: $color-yellow;
  }
  .bgBlue {
    background-color: $color-blue;
  }
  .p-entranceRap {
    display: flex;
    justify-content: space-between;
    @include sp() {
      flex-direction: column;
    }
    .l-contentRap_space {
      width: 46%;
      @include sp() {
        width: 100%;
        margin-bottom: 5em;
      }
    }
    &_box {
      min-height: 300px;
      @include sp() {
        min-height: 320px;
      }
    }
    &_ttl {
      margin-bottom: 50px;
      padding: 0.3em 0.3em 0;
      text-align: center;
      position: relative;
      @include fontsize(22);
    }
    &_ttl:after{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: -20px;
      left: 0;
      border-style: solid;
      border-color: $color-blue transparent transparent transparent;
      border-width: 20px 235px 0 235px;
      z-index: 1;
      @include sp() {
        border-width: 20px 47vw 0 47vw;
      }
    }
    &_subttl {
      margin-bottom: 10px;
      @include fontsize(20);
    }
    & li {
      margin-bottom: 4px;
      margin-left: 5%;
      list-style: disc;
      @include fontsize(16);
      @include sp() {
        margin-left: 6%;
        @include fontsize(14);
      }
    }
    &_container {
      display: flex;
      justify-content: space-between;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 32%;
        left: 8%;
        transform: translateX(-50%);
        width: 2px;
        height: 70%;
        background-color: $color-yellow;
        @include sp() {
          top: 24%;
          height: 82%;
        }
      }
      &.lineBlue {
        &:before {
          background-color: $color-blue;
        }
      }
      &.last {
        &:before {
          background: none;
        }
        & dl {
          height: auto;
        }
      }
    }
    dl {
      width: 80%;
      height: 230px;
      @include sp() {
        width: 82%;
      }
    }
    dt {
      margin-top: 0.6em;
      margin-bottom: 0.6em;
      @include fontsize(24);
      position: relative;
      @include sp() {
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        @include fontsize(18);
      }
    }
    dd {
      @include fontsize(16);
      @include sp() {
        @include fontsize(14);
      }
      &.p-entranceRap_danger {
        color: $color-red;
        @include fontsize(18);
      }
    }

    &_circle {
      width: 8%;
      position: absolute;
      top: 59%;
      left: 4%;

      img {
        width: 100%;
      }
    }
    &_icon {
      width: 16%;
      img {
        width: 100%;
      }
    }
    &_note {
      @include sp() {
        font-size: 0.8rem !important;
      }
      &_rev {
        margin-top: 1.5em;
        @include fontsize(24);
        position: relative;
        @include sp() {
          margin-top: 1.2em;
          @include fontsize(18);
        }
        & + dt {
          margin-top: 0;
        }
      }
    }
  }
  .p-entranceRap_ttl.bgYellow:after{
    border-color: $color-yellow transparent transparent transparent;
  }
}

// 留学生の方へ
#entrance-i{
  .pointList{
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include sp() {
      align-items: normal;
    }

    dt{
      width: 76px;
      height: 76px;
      margin: 0 40px 0 0;
      @include sp() {
        margin: 0 20px 0 0;
      }
    }

    dt img{
      width: 76px;
      height: 76px;
      @include sp() {
        width: 60px;
        height: 60px;
      }
    }

    dd{
      width: 100%;
    }
  }
}
